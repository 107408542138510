import './Products.css'
import PageHeader from '../../PageHeader';
import { Row, Col } from 'antd';
import SocialMedia from './SocialMedia';
import fastenersmain from '../../img/Product/fastenersmain.jpg';
import Bolt from '../../img/Product/Bolt.jpg';
import nuts from '../../img/Product/nuts.jpg';
import washer from '../../img/Product/washer.jpg';
import '../Pages.css';

const Fastner = () => {
    return (<div style={{ width: '100vw' }}>
        <PageHeader title='Fastener Manufacturer and Supplier in India' />
        <div className='product-info'>
            <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                    <img src={fastenersmain} width="100%" height="100%" className='product-img' />
                </Col>
                <Col xs={24} md={12}>
                    <div className='product-info-title'>Fastener Manufacturer, Supplier, and Stockist in India – Bhavesh Steel</div>
                    <div className='product-info-description'>Bhavesh Steel is one of the most trusted manufacturers, exporters, and suppliers of high-quality Stainless Steel Fasteners in India. We are focused on creating Stainless Steel Fasteners products that meet or exceed national and international quality standards. To fulfill the quality-conscious needs of our prestigious clients, we offer these <strong>Stainless Steel Fasteners</strong> in a variety of diameters, grades, and thicknesses as per the client’s specifications.
                        Bhavesh Steel is one of the largest stainless steel manufacturing companies in India, producing <strong>Stainless Steel Fasteners</strong>. These stainless steel tubes have various applications in various industries such as Automobile Sugar Mills, Dairy &amp; Foods, Water Supply, Heat Exchangers, Pharmaceuticals, Textiles, and Power Plants
                    </div>
                </Col>
                <Col xs={0} md={24}><br /></Col>
            </Row>
            <br />
            <div className='product-info-title'>Fasteners Manufacturer, Stainless Steel Fastener Manufacturer, Bolts Manufacturer, Nuts Manufacturer, Stainless Steel 304 Fasteners, Stainless Steel 316 Fastener Stockists in India.</div>
            <div className='product-info-description'><strong>Bhavesh Steel</strong> keeps a huge stock of high-quality standard and specialty stainless steel pipes&nbsp;and accessories ready to ship. Our <em>304 and 316 stainless steel seamless and welded pipe</em> meets the ASTM A312 standard specification to fulfill the strict certifications demanded on today’s pipeline systems, providing superior strength and corrosion resistance for a variety of applications. Fastener finishing and customization services include cutting, bending, polishing, threading, and grooving.</div>
            <div className='product-info-title'>Types of Stainless Steel Fasteners Supplier in India</div>
            <Row align='middle' gutter={[32, 16]}>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={Bolt} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Bolts</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={nuts} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Nuts</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={washer} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Washer</div>
                </Col>
                <Col xs={3} sm={0}></Col>
            </Row>
            <br />
            <SocialMedia />
            <br />
            <div className='product-info-title'>Fasteners Standard Specifications, Sizes, and Grades</div>
            <br />
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <td><strong>Specification</strong></td>
                            <td>ASTM, ASME, EN, DIN, JIS, GOST, IS&nbsp;and API</td>
                        </tr>
                        <tr>
                            <td><strong>Size</strong></td>
                            <td>M2 to M160 &amp; 1/8″ to Custom.</td>
                        </tr>
                        <tr>
                            <td><strong>Thread Type</strong></td>
                            <td>MM, BSW, BSF, UN, UNC, UNF &amp; FINE PITCH</td>
                        </tr>
                        <tr>
                            <td><strong>Shape</strong></td>
                            <td>Round /Square / Hexagonal / U-Bolt / L Bolt / J bolt / Anchor Bolt</td>
                        </tr>
                        <tr>
                            <td><strong>Type</strong></td>
                            <td>Bolt / Nut / Washer / Anchor</td>
                        </tr>
                        <tr>
                            <td><strong>End</strong></td>
                            <td>Plain End, Treaded or Custom</td>
                        </tr>
                        <tr>
                            <td><strong>Specialized in</strong></td>
                            <td>Custom Manufacturer</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div className='product-info-title'>Our extensive inventory of welded and seamless pipes conforms to the ASTM A312 standard specification. View the standard specifications chart.</div>
            <br />
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th colSpan="9"><strong>Specifications</strong></th>
                        </tr>
                        <tr>
                            <td><strong>Grade</strong></td>
                            <td><strong>Pipe</strong></td>
                            <td><strong>Tube</strong></td>
                            <td><strong>Fittings</strong></td>
                            <td><strong>Flanges</strong></td>
                            <td><strong>Plate</strong></td>
                            <td><strong>Bar</strong></td>
                            <td><strong>Forgings</strong></td>
                            <td><strong>UNS No.</strong></td>
                        </tr>
                        <tr>
                            <td>Stainless 304</td>
                            <td>A312</td>
                            <td>A213/249/269</td>
                            <td>A403</td>
                            <td>A182 F304</td>
                            <td>A240</td>
                            <td>A276/479</td>
                            <td>A182</td>
                            <td>S30400</td>
                        </tr>
                        <tr>
                            <td>Stainless 316</td>
                            <td>A312</td>
                            <td>A213/249/269</td>
                            <td>A403</td>
                            <td>A182 F316</td>
                            <td>A240</td>
                            <td>A276/479</td>
                            <td>A182</td>
                            <td>S31600</td>
                        </tr>
                        <tr>
                            <td>Stainless 410</td>
                            <td>A268</td>
                            <td>A213/249/269</td>
                            <td>A815</td>
                            <td>A182 F410</td>
                            <td>A240</td>
                            <td>A276/479</td>
                            <td>A182</td>
                            <td>S41000</td>
                        </tr>
                        <tr>
                            <td>Stainless 317</td>
                            <td>A312</td>
                            <td>A213/249/269</td>
                            <td>A403</td>
                            <td>A182 F317</td>
                            <td>A240</td>
                            <td>A276/479</td>
                            <td>A182</td>
                            <td>S31700</td>
                        </tr>
                        <tr>
                            <td>Stainless 310</td>
                            <td>A312</td>
                            <td>A213/249/269</td>
                            <td>A403</td>
                            <td>A182 F310</td>
                            <td>A240</td>
                            <td>A276/479</td>
                            <td>A182</td>
                            <td>S31000</td>
                        </tr>
                        <tr>
                            <td>Stainless 321</td>
                            <td>A312</td>
                            <td>A213/249/269</td>
                            <td>A403</td>
                            <td>A182 F321</td>
                            <td>A240</td>
                            <td>A276/479</td>
                            <td>A182</td>
                            <td>S32100</td>
                        </tr>
                        <tr>
                            <td>Stainless 347</td>
                            <td>A312</td>
                            <td>A213/249/269</td>
                            <td>A403</td>
                            <td>A182 F347</td>
                            <td>A240</td>
                            <td>A276/479</td>
                            <td>A182</td>
                            <td>S34700</td>
                        </tr>
                        <tr>
                            <td>Stainless 254 SMO</td>
                            <td>A312</td>
                            <td>A213/249/269</td>
                            <td>A403</td>
                            <td>A182 F254</td>
                            <td>A240</td>
                            <td>A/SA182 A/SA479 A/SA276 A/SA193</td>
                            <td>A/SA182</td>
                            <td>S31254</td>
                        </tr>
                        <tr>
                            <td>Alloy 20</td>
                            <td>B/SB729</td>
                            <td>B/SB729</td>
                            <td>B/SB366</td>
                            <td>B/SB462 B16.5</td>
                            <td>B/SB463</td>
                            <td>B/SB462 B/SB473</td>
                            <td>B/SB462</td>
                            <td>N08020</td>
                        </tr>
                        <tr>
                            <td>Duplex 2205</td>
                            <td>A/SA790</td>
                            <td>A/SA789</td>
                            <td>A/SA182 A/SA815</td>
                            <td>A/SA479 A/SA182</td>
                            <td>A/SA240</td>
                            <td>A/SA182 A/SA479 A/SA276 A/SA193</td>
                            <td>A/SA182</td>
                            <td>S31803/S 32205</td>
                        </tr>
                        <tr>
                            <td>Hastelloy C276</td>
                            <td>B/SB619 B/SB622</td>
                            <td>SB-622/SB- 516/SB-626</td>
                            <td>SB-366</td>
                            <td>B/SB574 B/SB564 B16.5</td>
                            <td>B/SB575</td>
                            <td>B/SB574 B/SB564</td>
                            <td>B/SB-564 B/SB462</td>
                            <td>N10276</td>
                        </tr>
                        <tr>
                            <td>Alloy 200/201</td>
                            <td>B/SB161</td>
                            <td>B/SB161 B/SB163</td>
                            <td>B/SB366</td>
                            <td>B/SB160 B/SB564 B16.5</td>
                            <td>B/SB162</td>
                            <td>B/SB160 B/SB564</td>
                            <td>B/SB564</td>
                            <td>N02200/ N02201</td>
                        </tr>
                        <tr>
                            <td>Alloy 400</td>
                            <td>B/SB165</td>
                            <td>B/SB165</td>
                            <td>B/SB366</td>
                            <td>B/SB164 B/SB564 B16.5</td>
                            <td>B/SB127</td>
                            <td>B/SB164 B/SB564 QQ-N-281D</td>
                            <td>SB-564</td>
                            <td>N04400</td>
                        </tr>
                        <tr>
                            <td>Alloy 600</td>
                            <td>B/SB167</td>
                            <td>B/SB167</td>
                            <td>B/SB366</td>
                            <td>B/SB166 B/SB564 B16.5</td>
                            <td>B/SB168</td>
                            <td>B/SB166 B/SB564</td>
                            <td>B/SB564</td>
                            <td>N06600</td>
                        </tr>
                        <tr>
                            <td>Alloy 625</td>
                            <td>B/SB444 B705</td>
                            <td>B/SB444</td>
                            <td>B/SB366</td>
                            <td>B/SB444 B/SB564 B16.5</td>
                            <td>B/SB443</td>
                            <td>B/SB446 B/SB564</td>
                            <td>B/SB564</td>
                            <td>N06625</td>
                        </tr>
                        <tr>
                            <td>Alloy 800H/HP</td>
                            <td>B/SB407</td>
                            <td>SB-407/SB- 829/SB-515/SB-751</td>
                            <td>B/SB366</td>
                            <td>B/SB446 B/SB564 B16.5</td>
                            <td>B/SB409</td>
                            <td>B/SB408 B/SB564</td>
                            <td>B/SB564</td>
                            <td>NO8810/ N08811</td>
                        </tr>
                        <tr>
                            <td>Alloy 825</td>
                            <td>B/SB423</td>
                            <td>B/SB423</td>
                            <td>B/SB366</td>
                            <td>B/SB425 B/SB564 B16.5</td>
                            <td>B/SB424</td>
                            <td>B/SB425 B/SB564</td>
                            <td>B/SB564</td>
                            <td>N08825</td>
                        </tr>
                        <tr>
                            <td>Carbon Steel</td>
                            <td>A53</td>
                            <td></td>
                            <td>A234 WPB</td>
                            <td>A105</td>
                            <td>A36</td>
                            <td></td>
                            <td>A105</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Carbon Steel</td>
                            <td>A106B</td>
                            <td></td>
                            <td>A234 WPB</td>
                            <td>A105</td>
                            <td>A36</td>
                            <td></td>
                            <td>A105</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Carbon Steel</td>
                            <td>A106C</td>
                            <td></td>
                            <td>A234 WPB</td>
                            <td>A105</td>
                            <td>A36</td>
                            <td></td>
                            <td>A105</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Low Temp C.S. Gr. 1</td>
                            <td>A333 GR. 1</td>
                            <td>A334 GR. 1</td>
                            <td>A420 WPL1/6</td>
                            <td>A350 LF2</td>
                            <td></td>
                            <td>A516</td>
                            <td>A350 LF2</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Low Temp C.S. Gr. 3</td>
                            <td>A333 GR. 3</td>
                            <td>A334 GR. 3</td>
                            <td>A420 WPL3</td>
                            <td>A350 LF3</td>
                            <td></td>
                            <td>A516</td>
                            <td>A350 LF3</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Low Temp C.S. Gr. 6</td>
                            <td>A333 GR. 6</td>
                            <td>A334 GR. 6</td>
                            <td>A420 WPL6</td>
                            <td>A350 LF2</td>
                            <td></td>
                            <td>A516</td>
                            <td>A350 LF2</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cr-Mo Grade 5</td>
                            <td>A335 P5</td>
                            <td>A213 T5</td>
                            <td>A234 WP5</td>
                            <td>A182 F5</td>
                            <td></td>
                            <td>A387 GRADE 5</td>
                            <td>A182 F5</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cr-Mo Grade 9</td>
                            <td>A335 P9</td>
                            <td>A213 T9</td>
                            <td>A234 WP9</td>
                            <td>A182 F9</td>
                            <td></td>
                            <td>A387 GRADE 9</td>
                            <td>A182 F9</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cr-Mo Grade 11</td>
                            <td>A335 P11</td>
                            <td>A213 T11</td>
                            <td>A234 WP11</td>
                            <td>A182 F11</td>
                            <td></td>
                            <td>A387 GRADE 11</td>
                            <td>A182 F11</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cr-Mo Grade 22</td>
                            <td>A335 P22</td>
                            <td>A213 T22</td>
                            <td>A234 WP22</td>
                            <td>A182 F22</td>
                            <td></td>
                            <td>A387 GRADE 22</td>
                            <td>A182 F22</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cr-Mo Grade 91</td>
                            <td>A335 P91</td>
                            <td>A213 T91</td>
                            <td>A234 WP91</td>
                            <td>A182 F91</td>
                            <td></td>
                            <td>A387 GRADE 91</td>
                            <td>A182 F91</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div className='product-info-title'>Application & Uses of Stainless Steel Fasteners</div>
            <div className='product-info-description'>
                <ul>
                    <li>Stainless Steel Fasteners are used in Sugar Industry.</li>
                    <li>Stainless Steel Fasteners are used in Power Industry.</li>
                    <li>Seamless Fasteners are used in ShipBuilding Industry.</li>
                    <li>Welded Fasteners are used in Plumbing</li>
                    <li>Stainless Steel Seamless Fasteners are used in Oil and Gas Plant</li>
                    <li>Stainless Steel Fasteners are used in Process Instrumentation</li>
                </ul>
            </div>
            <div className='product-info-title'>Stainless Steel Fastener Grades & Chemical Composition</div>
            <br />
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th colSpan="18">Stainless Steel Fasteners Chemical Composition</th>
                        </tr>
                        <tr>
                            <td>GRADE</td>
                            <td>UNS</td>
                            <td>DIN</td>
                            <td>Steel Name</td>
                            <td>Carbon (C)</td>
                            <td>Chromium (Cr)</td>
                            <td>Manganese (Mn)</td>
                            <td>Silicon (Si)</td>
                            <td>Phosphorous (P)</td>
                            <td>Sulphur (S)</td>
                            <td>Nickel (Ni)</td>
                            <td>Molybdenum (Mo)</td>
                            <td>Nitrogen (N)</td>
                            <td>Iron (Fe)</td>
                            <td>Copper (Cu)</td>
                            <td>Titanium (Ti)</td>
                            <td>Aluminum (Al)</td>
                            <td>Others</td>
                        </tr>
                        <tr>
                            <td>304</td>
                            <td>S30400</td>
                            <td>1.4301</td>
                            <td>X2CrNi18-20</td>
                            <td>0.07</td>
                            <td>17.50 – 19.50</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.045</td>
                            <td>0.015</td>
                            <td>8 – 10.5</td>
                            <td></td>
                            <td>0.1</td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>304L</td>
                            <td>S30403</td>
                            <td>1.4307</td>
                            <td>X2CrNi18-9</td>
                            <td>0.03</td>
                            <td>17.50 – 19.50</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.045</td>
                            <td>0.015</td>
                            <td>8 – 10.5</td>
                            <td></td>
                            <td>0.1</td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>309</td>
                            <td></td>
                            <td>1.4828</td>
                            <td></td>
                            <td>0.2</td>
                            <td>19 – 21</td>
                            <td>2</td>
                            <td>1.50 – 2.00</td>
                            <td>0.045</td>
                            <td>0.015</td>
                            <td>11.00 – 13.00</td>
                            <td></td>
                            <td>0.11</td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>310</td>
                            <td>S31008</td>
                            <td>1.4845</td>
                            <td>X8CrNi25-21</td>
                            <td>0.25</td>
                            <td>24 – 26</td>
                            <td>2</td>
                            <td>1.5</td>
                            <td>0.045</td>
                            <td>0.03</td>
                            <td>19 – 22</td>
                            <td></td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>310S</td>
                            <td></td>
                            <td>1.4845</td>
                            <td></td>
                            <td>0.08</td>
                            <td>24 – 26</td>
                            <td>2</td>
                            <td>1.5</td>
                            <td>0.045</td>
                            <td>0.03</td>
                            <td>19 – 22</td>
                            <td></td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>316</td>
                            <td>S31500</td>
                            <td>1.4401</td>
                            <td>X5CrNiMo17-12-2</td>
                            <td>0.07</td>
                            <td>16.50 – 18.50</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.05</td>
                            <td>0.02</td>
                            <td>10.0 – 13.0</td>
                            <td>2.00 – 2.50</td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>316L</td>
                            <td>S31603</td>
                            <td>1.4404</td>
                            <td>X5CrNiMo17-12-2</td>
                            <td>0.03</td>
                            <td>16.50 – 18.50</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.05</td>
                            <td>0.02</td>
                            <td>10.0 – 13.0</td>
                            <td>2.00 – 2.50</td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>316Ti</td>
                            <td>S31635</td>
                            <td>1.4571</td>
                            <td>X6CrNiTi18-10</td>
                            <td>0.08</td>
                            <td>16.50 – 18.50</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.05</td>
                            <td>0.03</td>
                            <td>10.50 – 14.0</td>
                            <td>2.00 – 2.50</td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td>0.4 – 0.7</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>317</td>
                            <td>S31700</td>
                            <td></td>
                            <td></td>
                            <td>0.08</td>
                            <td>17 – 19</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.045</td>
                            <td>0.03</td>
                            <td>13</td>
                            <td>3.5</td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>321</td>
                            <td>S32100</td>
                            <td>1.4541</td>
                            <td>X6CrNiTi18-10</td>
                            <td>0.08</td>
                            <td>17 – 19</td>
                            <td>2</td>
                            <td>0.75</td>
                            <td>0.045</td>
                            <td>0.03</td>
                            <td>9.0 – 12.0</td>
                            <td></td>
                            <td>0.1</td>
                            <td>Bal</td>
                            <td></td>
                            <td>0.5 – 0.7</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>321H</td>
                            <td>S32109</td>
                            <td>1.4878</td>
                            <td>X10CrNiTi18-10</td>
                            <td>0.4 – 0.10</td>
                            <td>17 – 19</td>
                            <td>2</td>
                            <td>0.75</td>
                            <td>0.045</td>
                            <td>0.03</td>
                            <td>9.0 – 12.0</td>
                            <td></td>
                            <td>0.1</td>
                            <td>Bal</td>
                            <td></td>
                            <td>0.4 – 0.7</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>347</td>
                            <td>S34700</td>
                            <td>1.4550</td>
                            <td>X6CrNiNb18-10</td>
                            <td>0.08</td>
                            <td>17 – 19</td>
                            <td>2</td>
                            <td>0.75</td>
                            <td>0.045</td>
                            <td>0.03</td>
                            <td>9.0 – 13.0</td>
                            <td></td>
                            <td>0.1</td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>NB (1.0)</td>
                        </tr>
                        <tr>
                            <td>409</td>
                            <td>S40900</td>
                            <td>1.4512</td>
                            <td>X6CrTi12</td>
                            <td>0.08</td>
                            <td>10.50 – 11.75</td>
                            <td>1</td>
                            <td>1</td>
                            <td>0.045</td>
                            <td>0.045</td>
                            <td>0.5</td>
                            <td></td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td>0.75</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>410</td>
                            <td>S41000</td>
                            <td>1.4006</td>
                            <td>X12Cr13</td>
                            <td>0.15</td>
                            <td>11.5 – 13.5</td>
                            <td>1</td>
                            <td>1</td>
                            <td>0.04</td>
                            <td>0.03</td>
                            <td>0.75</td>
                            <td></td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>410S</td>
                            <td>S41008</td>
                            <td>1.4034</td>
                            <td></td>
                            <td>0.08</td>
                            <td>12.5</td>
                            <td>1</td>
                            <td>1</td>
                            <td>0.04</td>
                            <td>0.03</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>430</td>
                            <td>S43000</td>
                            <td>1.4016</td>
                            <td>X6Cr17</td>
                            <td>0.08</td>
                            <td>16.0 – 18.0</td>
                            <td>1</td>
                            <td>1</td>
                            <td>0.04</td>
                            <td>0.02</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>441</td>
                            <td>S44100</td>
                            <td>1.4509</td>
                            <td></td>
                            <td>0.03</td>
                            <td>17.5 – 18.5</td>
                            <td>1</td>
                            <td>1</td>
                            <td>0.04</td>
                            <td>0.015</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>Bal</td>
                            <td></td>
                            <td>0.1 – 0.6</td>
                            <td></td>
                            <td>Nb (1.0)</td>
                        </tr>
                        <tr>
                            <td>904L</td>
                            <td>S08904</td>
                            <td>1.4539</td>
                            <td>X1NiCrMoCu25-20-5</td>
                            <td>0.02</td>
                            <td>19.0 – 23.0</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.045</td>
                            <td>0.035</td>
                            <td>23.0 – 28.0</td>
                            <td>4.0 – 5.0</td>
                            <td></td>
                            <td>Bal</td>
                            <td>1.0 – 2.0</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>TP 2205</td>
                            <td>S31803</td>
                            <td>1.4462</td>
                            <td>X2CrNiMoN22-5-3</td>
                            <td>0.03</td>
                            <td>21.0 – 23.0</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.03</td>
                            <td>0.02</td>
                            <td>4.5 – 6.5</td>
                            <td>2.5 – 3.5</td>
                            <td>0.08 – 0.20</td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>S32205</td>
                            <td>1.4410</td>
                            <td>X2CrNiMoN25-7-4</td>
                            <td>0.03</td>
                            <td>22.0 – 23.0</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0.03</td>
                            <td>0.02</td>
                            <td>4.5 – 6.5</td>
                            <td>3.0 – 3.5</td>
                            <td>0.14 – 0.20</td>
                            <td>Bal</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>TP 2507</td>
                            <td>S32750</td>
                            <td>1.4501</td>
                            <td>X2CrNiMoCuWN25-7-4</td>
                            <td>0.03</td>
                            <td>24.0 – 26.0</td>
                            <td>1.2</td>
                            <td>0.8</td>
                            <td>0.035</td>
                            <td>0.02</td>
                            <td>6.0 – 8.0</td>
                            <td>3.0 – 5.0</td>
                            <td>0.24 – 0.32</td>
                            <td>Bal</td>
                            <td>0.5</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>S32760</td>
                            <td></td>
                            <td></td>
                            <td>0.03</td>
                            <td>24.0 – 26.0</td>
                            <td>1</td>
                            <td>1</td>
                            <td>0.035</td>
                            <td>0.015</td>
                            <td>6.0 – 8.0</td>
                            <td>3.0 – 4.0</td>
                            <td>0.3</td>
                            <td>Bal</td>
                            <td>1</td>
                            <td></td>
                            <td></td>
                            <td>W (1.0)</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div className='product-info-title'>Nickel Alloy Fastener Grades & Chemical Composition</div>
            <br />
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th colSpan="13">Nickel Alloy Fastener Chemical Composition</th>
                        </tr>
                        <tr>
                            <td>GRADE</td>
                            <td>UNS</td>
                            <td>DIN</td>
                            <td>Carbon (C)</td>
                            <td>manganese (M</td>
                            <td>Phosphorous (P)</td>
                            <td>Sulphur (S)</td>
                            <td>Silicon (Si)</td>
                            <td>Chromium (Cr)</td>
                            <td>Nickel (Ni)</td>
                            <td>molybdenum (M</td>
                            <td>Copper (Cu)</td>
                            <td>Iron (Fe)</td>
                        </tr>
                        <tr>
                            <td>Alloy 20</td>
                            <td>N08020</td>
                            <td>2.446</td>
                            <td>0.06</td>
                            <td>2</td>
                            <td>–</td>
                            <td>0.03</td>
                            <td></td>
                            <td>19.0-21.0</td>
                            <td>32.0-38.0</td>
                            <td>2.0-3.0</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Alloy 28</td>
                            <td>N08028</td>
                            <td>1.4563</td>
                            <td>0.02</td>
                            <td>2</td>
                            <td>–</td>
                            <td>0.024</td>
                            <td>0.7</td>
                            <td>26.0-28.0</td>
                            <td>30.0-34.0</td>
                            <td>3.0-4.0</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Monel 400</td>
                            <td>N04400</td>
                            <td>2.4360</td>
                            <td>0.3</td>
                            <td>2</td>
                            <td>–</td>
                            <td>0.024</td>
                            <td>0.5</td>
                            <td>–</td>
                            <td>63.0 Min</td>
                            <td>—</td>
                            <td>28.0 – 34.0</td>
                            <td>2.5</td>
                        </tr>
                        <tr>
                            <td>Inconel 600</td>
                            <td>N06600</td>
                            <td>2.4816</td>
                            <td>0.15</td>
                            <td>1</td>
                            <td>–</td>
                            <td>0.015</td>
                            <td>0.5</td>
                            <td>14.0-17.0</td>
                            <td>72.0 Min</td>
                            <td></td>
                            <td>0.5</td>
                            <td>6.0 – 10.0</td>
                        </tr>
                        <tr>
                            <td>Inconel 625</td>
                            <td>N06625</td>
                            <td>2.4865</td>
                            <td>0.1</td>
                            <td>0.5</td>
                            <td>–</td>
                            <td>0.03</td>
                            <td>0.5</td>
                            <td>20.0-23.0</td>
                            <td>58.0 Min</td>
                            <td>8.0-10.0</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Inconel 800</td>
                            <td>N08800</td>
                            <td>–</td>
                            <td>0.1</td>
                            <td>1.5</td>
                            <td>–</td>
                            <td>0.01</td>
                            <td>0.5</td>
                            <td>19.0-23.0</td>
                            <td>30.0-35.0</td>
                            <td>–</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Inconel 825</td>
                            <td>N08825</td>
                            <td>2.4858</td>
                            <td>0.05</td>
                            <td>1</td>
                            <td>–</td>
                            <td>–</td>
                            <td>–</td>
                            <td>19.5-23.5</td>
                            <td>38.0-46.0</td>
                            <td>2.5-3.5</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Hastalloy C276</td>
                            <td>N10276</td>
                            <td>–</td>
                            <td>0.01</td>
                            <td>1</td>
                            <td>0.025</td>
                            <td>–</td>
                            <td>0.08</td>
                            <td>14.5-16.5</td>
                            <td>57.0 Min</td>
                            <td>15.0-17.0</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div className='product-info-title'>We are leading Manufacturers, Exporters, and Stockists of Stainless steel Fasteners in more than 23 countries worldwide.</div>
            <div className='product-info-description'>We can supply <strong>Stainless Steel Fasteners</strong> at the best price as we are one of the largest suppliers &amp; Stockists of 304, 316, 321, etc in India. We have our stocking distributors in Surat, Pune, Bangalore, Chennai, Hyderabad, &amp; Delhi. Check our Stainless Steel Fastener prices, Fasteners made of Stainless Steel have exceptional performance properties. Also, mail for our livestock of <em>Bolt, Nuts &amp; Washer</em> to check types &amp; sizes with our ready stock we can give you the best price of SS Fasteners.</div>
            <div className='product-info-title'>Bolt Size Chart</div>
            <div className='product-info-description'>Imperial and Metric charts for bolt dimensions as well as the thread pitc</div>
            <div className='product-info-title'>Imperial Bolt Size Chart</div>
            <br />
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th><strong>Nominal Size</strong></th>
                            <th colSpan="2"><strong>Bolt Diameter ‘D’</strong></th>
                            <th colSpan="3"><strong>Across Flats ‘A’</strong></th>
                            <th colSpan="2"><strong>Across Corners ‘B’</strong></th>
                            <th colSpan="3"><strong>Head Height ‘C’</strong></th>
                            <th colSpan="2"><strong>Thread Length ‘L’</strong></th>
                            <th><strong>Transition Length ‘Y’</strong></th>
                        </tr>
                        <tr>
                            <td></td>
                            <td>Max (in)</td>
                            <td>Min (in)</td>
                            <td>Nominal (in)</td>
                            <td>Max (in)</td>
                            <td>Min (in)</td>
                            <td>Max (in)</td>
                            <td>Min (in)</td>
                            <td>Nominal (in)</td>
                            <td>Max (in)</td>
                            <td>Min (in)</td>
                            <td>≤6 in</td>
                            <td>&gt;6 in</td>
                            <td>Max (in)</td>
                        </tr>
                        <tr>
                            <td>1/4</td>
                            <td>0.25</td>
                            <td>0.245</td>
                            <td>7/16</td>
                            <td>0.438</td>
                            <td>0.428</td>
                            <td>0.505</td>
                            <td>0.488</td>
                            <td>5/32</td>
                            <td>0.163</td>
                            <td>0.15</td>
                            <td>0.75</td>
                            <td>1</td>
                            <td>0.25</td>
                        </tr>
                        <tr>
                            <td>5/16</td>
                            <td>0.3125</td>
                            <td>0.3065</td>
                            <td>1/2</td>
                            <td>0.5</td>
                            <td>0.489</td>
                            <td>0.577</td>
                            <td>0.557</td>
                            <td>13/64</td>
                            <td>0.211</td>
                            <td>0.195</td>
                            <td>0.875</td>
                            <td>1.125</td>
                            <td>0.278</td>
                        </tr>
                        <tr>
                            <td>3/8</td>
                            <td>0.375</td>
                            <td>0.369</td>
                            <td>9/16</td>
                            <td>0.562</td>
                            <td>0.551</td>
                            <td>0.65</td>
                            <td>0.628</td>
                            <td>15/64</td>
                            <td>0.243</td>
                            <td>0.226</td>
                            <td>1</td>
                            <td>1.25</td>
                            <td>0.312</td>
                        </tr>
                        <tr>
                            <td>7/16</td>
                            <td>0.4375</td>
                            <td>0.4305</td>
                            <td>5/8</td>
                            <td>0.625</td>
                            <td>0.612</td>
                            <td>0.722</td>
                            <td>0.698</td>
                            <td>9/32</td>
                            <td>0.291</td>
                            <td>0.272</td>
                            <td>1.125</td>
                            <td>1.375</td>
                            <td>0.357</td>
                        </tr>
                        <tr>
                            <td>1/2</td>
                            <td>0.5</td>
                            <td>0.493</td>
                            <td>3/4</td>
                            <td>0.75</td>
                            <td>0.736</td>
                            <td>0.866</td>
                            <td>0.84</td>
                            <td>5/16</td>
                            <td>0.323</td>
                            <td>0.302</td>
                            <td>1.25</td>
                            <td>1.5</td>
                            <td>0.385</td>
                        </tr>
                        <tr>
                            <td>9/16</td>
                            <td>0.5625</td>
                            <td>0.5545</td>
                            <td>13/16</td>
                            <td>0.812</td>
                            <td>0.798</td>
                            <td>0.938</td>
                            <td>0.91</td>
                            <td>23/64</td>
                            <td>0.371</td>
                            <td>0.348</td>
                            <td>1.375</td>
                            <td>1.625</td>
                            <td>0.417</td>
                        </tr>
                        <tr>
                            <td>5/8</td>
                            <td>0.625</td>
                            <td>0.617</td>
                            <td>15/16</td>
                            <td>0.938</td>
                            <td>0.922</td>
                            <td>1.083</td>
                            <td>1.051</td>
                            <td>25/64</td>
                            <td>0.403</td>
                            <td>0.378</td>
                            <td>1.5</td>
                            <td>1.75</td>
                            <td>0.455</td>
                        </tr>
                        <tr>
                            <td>3/4</td>
                            <td>0.75</td>
                            <td>0.741</td>
                            <td>1-1/8</td>
                            <td>1.125</td>
                            <td>1.1</td>
                            <td>1.299</td>
                            <td>1.254</td>
                            <td>15/32</td>
                            <td>0.483</td>
                            <td>0.455</td>
                            <td>1.75</td>
                            <td>2</td>
                            <td>0.5</td>
                        </tr>
                        <tr>
                            <td>7/8</td>
                            <td>0.875</td>
                            <td>0.866</td>
                            <td>1-5/16</td>
                            <td>1.312</td>
                            <td>1.285</td>
                            <td>1.516</td>
                            <td>1.465</td>
                            <td>35/64</td>
                            <td>0.563</td>
                            <td>0.531</td>
                            <td>2</td>
                            <td>2.25</td>
                            <td>0.556</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>1</td>
                            <td>0.99</td>
                            <td>1-1/2</td>
                            <td>1.5</td>
                            <td>1.469</td>
                            <td>1.732</td>
                            <td>1.675</td>
                            <td>39/64</td>
                            <td>0.627</td>
                            <td>0.591</td>
                            <td>2.25</td>
                            <td>2.5</td>
                            <td>0.625</td>
                        </tr>
                        <tr>
                            <td>1-1/8</td>
                            <td>1.125</td>
                            <td>1.114</td>
                            <td>1-11/16</td>
                            <td>1.688</td>
                            <td>1.631</td>
                            <td>1.949</td>
                            <td>1.859</td>
                            <td>11/16</td>
                            <td>0.718</td>
                            <td>0.658</td>
                            <td>2.5</td>
                            <td>2.75</td>
                            <td>0.714</td>
                        </tr>
                        <tr>
                            <td>1-1/4</td>
                            <td>1.25</td>
                            <td>1.239</td>
                            <td>1-7/8</td>
                            <td>1.875</td>
                            <td>1.812</td>
                            <td>2.165</td>
                            <td>2.066</td>
                            <td>25/32</td>
                            <td>0.813</td>
                            <td>0.749</td>
                            <td>2.75</td>
                            <td>3</td>
                            <td>0.714</td>
                        </tr>
                        <tr>
                            <td>1-3/8</td>
                            <td>1.375</td>
                            <td>1.363</td>
                            <td>2-1/16</td>
                            <td>2.062</td>
                            <td>1.994</td>
                            <td>2.382</td>
                            <td>2.273</td>
                            <td>27/32</td>
                            <td>0.878</td>
                            <td>0.81</td>
                            <td>3</td>
                            <td>3.25</td>
                            <td>0.833</td>
                        </tr>
                        <tr>
                            <td>1-1/2</td>
                            <td>1.5</td>
                            <td>1.488</td>
                            <td>2-1/4</td>
                            <td>2.25</td>
                            <td>2.175</td>
                            <td>2.598</td>
                            <td>2.48</td>
                            <td>1-5/16</td>
                            <td>0.974</td>
                            <td>0.902</td>
                            <td>3.25</td>
                            <td>3.5</td>
                            <td>0.833</td>
                        </tr>
                        <tr>
                            <td>1-3/4</td>
                            <td>1.75</td>
                            <td>1.738</td>
                            <td>2-5/8</td>
                            <td>2.625</td>
                            <td>2.538</td>
                            <td>3.031</td>
                            <td>2.89</td>
                            <td>1-3/32</td>
                            <td>1.134</td>
                            <td>1.054</td>
                            <td>3.75</td>
                            <td>4</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>2</td>
                            <td>1.988</td>
                            <td>3</td>
                            <td>3</td>
                            <td>2.9</td>
                            <td>3.464</td>
                            <td>3.306</td>
                            <td>1-7/32</td>
                            <td>1.263</td>
                            <td>1.175</td>
                            <td>4.25</td>
                            <td>4.5</td>
                            <td>1.111</td>
                        </tr>
                        <tr>
                            <td>2-1/4</td>
                            <td>2.25</td>
                            <td>2.238</td>
                            <td>3-3/8</td>
                            <td>3.375</td>
                            <td>3.262</td>
                            <td>3.897</td>
                            <td>3.719</td>
                            <td>1-3/8</td>
                            <td>1.423</td>
                            <td>1.327</td>
                            <td>4.75</td>
                            <td>5</td>
                            <td>1.111</td>
                        </tr>
                        <tr>
                            <td>2-1/2</td>
                            <td>2.5</td>
                            <td>2.488</td>
                            <td>3-3/4</td>
                            <td>3.75</td>
                            <td>3.625</td>
                            <td>4.33</td>
                            <td>4.133</td>
                            <td>1-17/32</td>
                            <td>1.583</td>
                            <td>1.479</td>
                            <td>5.25</td>
                            <td>5.5</td>
                            <td>1.25</td>
                        </tr>
                        <tr>
                            <td>2-3/4</td>
                            <td>2.75</td>
                            <td>2.738</td>
                            <td>4-1/8</td>
                            <td>4.125</td>
                            <td>3.988</td>
                            <td>4.763</td>
                            <td>4.546</td>
                            <td>1-11/16</td>
                            <td>1.744</td>
                            <td>1.632</td>
                            <td>5.75</td>
                            <td>6</td>
                            <td>1.25</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>3</td>
                            <td>2.988</td>
                            <td>4-1/2</td>
                            <td>4.5</td>
                            <td>4.35</td>
                            <td>5.196</td>
                            <td>4.959</td>
                            <td>1-7/8</td>
                            <td>1.935</td>
                            <td>1.815</td>
                            <td>6.25</td>
                            <td>6.5</td>
                            <td>1.25</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div className='product-info-title'>Thread Pitch</div>
            <br />
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th><strong>Bolt Size</strong></th>
                            <th colSpan="2"><strong>Thread Pitch</strong></th>
                        </tr>
                        <tr>
                            <td>(in)</td>
                            <td>Coarse</td>
                            <td>Fine</td>
                        </tr>
                        <tr>
                            <td>1/4</td>
                            <td>20</td>
                            <td>28</td>
                        </tr>
                        <tr>
                            <td>5/16</td>
                            <td>18</td>
                            <td>24</td>
                        </tr>
                        <tr>
                            <td>3/8</td>
                            <td>16</td>
                            <td>24</td>
                        </tr>
                        <tr>
                            <td>7/16</td>
                            <td>14</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>1/2</td>
                            <td>13</td>
                            <td>20</td>
                        </tr>
                        <tr>
                            <td>9/16</td>
                            <td>12</td>
                            <td>18</td>
                        </tr>
                        <tr>
                            <td>5/8</td>
                            <td>11</td>
                            <td>18</td>
                        </tr>
                        <tr>
                            <td>3/4</td>
                            <td>10</td>
                            <td>16</td>
                        </tr>
                        <tr>
                            <td>7/8</td>
                            <td>9</td>
                            <td>14</td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>8</td>
                            <td>14</td>
                        </tr>
                        <tr>
                            <td>1-1/8</td>
                            <td>7</td>
                            <td>12</td>
                        </tr>
                        <tr>
                            <td>1-1/4</td>
                            <td>7</td>
                            <td>12</td>
                        </tr>
                        <tr>
                            <td>1-3/8</td>
                            <td>6</td>
                            <td>12</td>
                        </tr>
                        <tr>
                            <td>1-1/2</td>
                            <td>6</td>
                            <td>12</td>
                        </tr>
                        <tr>
                            <td>1-3/4</td>
                            <td>5</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>4-1/2</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>2-1/2</td>
                            <td>4</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>4</td>
                            <td>–</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div className='product-info-title'>Metric Bolt Size Chart</div>
            <br />
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th><strong>Size</strong></th>
                            <th><strong>Pitch</strong></th>
                            <th colSpan="2"><strong>Across Flats ‘A’</strong></th>
                            <th><strong>Across Corners ‘B’</strong></th>
                            <th><strong>Head Height ‘C’</strong></th>
                            <th colSpan="2" width="120"><strong>Bolt Diameter ‘D’</strong></th>
                        </tr>
                        <tr>
                            <td></td>
                            <td>(mm)</td>
                            <td>Max (mm)</td>
                            <td>Min (mm)</td>
                            <td>Min (mm)</td>
                            <td>Max (mm)</td>
                            <td>Max (mm)</td>
                            <td>Min (mm)</td>
                        </tr>
                        <tr>
                            <td>M5</td>
                            <td>0.8</td>
                            <td>8</td>
                            <td>7.78</td>
                            <td>9.2</td>
                            <td>3.5</td>
                            <td>5</td>
                            <td>4.82</td>
                        </tr>
                        <tr>
                            <td>M6</td>
                            <td>1</td>
                            <td>10</td>
                            <td>9.78</td>
                            <td>11.5</td>
                            <td>4</td>
                            <td>6</td>
                            <td>5.82</td>
                        </tr>
                        <tr>
                            <td>M8</td>
                            <td>1.25</td>
                            <td>13</td>
                            <td>12.73</td>
                            <td>15</td>
                            <td>5.3</td>
                            <td>8</td>
                            <td>7.78</td>
                        </tr>
                        <tr>
                            <td>M10</td>
                            <td>1.5</td>
                            <td>16</td>
                            <td>15.73</td>
                            <td>18.4</td>
                            <td>6.4</td>
                            <td>10</td>
                            <td>9.78</td>
                        </tr>
                        <tr>
                            <td>M12</td>
                            <td>1.75</td>
                            <td>18</td>
                            <td>17.73</td>
                            <td>20.7</td>
                            <td>7.5</td>
                            <td>12</td>
                            <td>11.73</td>
                        </tr>
                        <tr>
                            <td>M14</td>
                            <td>2</td>
                            <td>21</td>
                            <td>20.67</td>
                            <td>24.2</td>
                            <td>8.8</td>
                            <td>14</td>
                            <td>13.73</td>
                        </tr>
                        <tr>
                            <td>M16</td>
                            <td>2</td>
                            <td>24</td>
                            <td>23.67</td>
                            <td>27.7</td>
                            <td>10</td>
                            <td>16</td>
                            <td>15.73</td>
                        </tr>
                        <tr>
                            <td>M18</td>
                            <td>2.5</td>
                            <td>27</td>
                            <td>26.67</td>
                            <td>31.2</td>
                            <td>11.5</td>
                            <td>18</td>
                            <td>17.73</td>
                        </tr>
                        <tr>
                            <td>M20</td>
                            <td>2.5</td>
                            <td>30</td>
                            <td>29.67</td>
                            <td>34.6</td>
                            <td>12.5</td>
                            <td>20</td>
                            <td>19.67</td>
                        </tr>
                        <tr>
                            <td>M22</td>
                            <td>2.5</td>
                            <td>34</td>
                            <td>33.38</td>
                            <td>39.3</td>
                            <td>14</td>
                            <td>22</td>
                            <td>21.67</td>
                        </tr>
                        <tr>
                            <td>M24</td>
                            <td>3</td>
                            <td>36</td>
                            <td>35.38</td>
                            <td>41.6</td>
                            <td>15</td>
                            <td>24</td>
                            <td>23.67</td>
                        </tr>
                        <tr>
                            <td>M27</td>
                            <td>3</td>
                            <td>41</td>
                            <td>40.38</td>
                            <td>47.3</td>
                            <td>16.7</td>
                            <td>27</td>
                            <td>26.67</td>
                        </tr>
                        <tr>
                            <td>M30</td>
                            <td>3.5</td>
                            <td>46</td>
                            <td>45</td>
                            <td>53.1</td>
                            <td>18.7</td>
                            <td>30</td>
                            <td>29.67</td>
                        </tr>
                        <tr>
                            <td>M33</td>
                            <td>3.5</td>
                            <td>50</td>
                            <td>49</td>
                            <td>57.7</td>
                            <td>20.5</td>
                            <td>33</td>
                            <td>32.61</td>
                        </tr>
                        <tr>
                            <td>M36</td>
                            <td>4</td>
                            <td>55</td>
                            <td>53.8</td>
                            <td>63.5</td>
                            <td>22.5</td>
                            <td>36</td>
                            <td>35.61</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div className='product-info-title'>Thread Pitch</div>
            <br />
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th><strong>Size</strong></th>
                            <th><strong>Coarse (mm)</strong></th>
                            <th><strong>Fine (mm)</strong></th>
                            <th><strong>Super Fine (mm)</strong></th>
                        </tr>
                        <tr>
                            <td>M2</td>
                            <td>0.4</td>
                            <td>–</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M2.5</td>
                            <td>0.45</td>
                            <td>–</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M3</td>
                            <td>0.5</td>
                            <td>–</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M3.5</td>
                            <td>0.6</td>
                            <td>–</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M4</td>
                            <td>0.7</td>
                            <td>–</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M5</td>
                            <td>0.8</td>
                            <td>–</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M6</td>
                            <td>1</td>
                            <td>0.75</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M7</td>
                            <td>1</td>
                            <td>–</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M8</td>
                            <td>1.25</td>
                            <td>1</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M10</td>
                            <td>1.5</td>
                            <td>1.25</td>
                            <td>1</td>
                        </tr>
                        <tr>
                            <td>M12</td>
                            <td>1.75</td>
                            <td>1.5</td>
                            <td>1.25</td>
                        </tr>
                        <tr>
                            <td>M14</td>
                            <td>2</td>
                            <td>1.5</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M16</td>
                            <td>2</td>
                            <td>1.5</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M18</td>
                            <td>2.5</td>
                            <td>1.5</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M20</td>
                            <td>2.5</td>
                            <td>1.5</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M22</td>
                            <td>2.5</td>
                            <td>1.5</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M24</td>
                            <td>3</td>
                            <td>2</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M27</td>
                            <td>3</td>
                            <td>2</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M30</td>
                            <td>3.5</td>
                            <td>2</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M33</td>
                            <td>3.5</td>
                            <td>2</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M36</td>
                            <td>4</td>
                            <td>3</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M42</td>
                            <td>4.5</td>
                            <td>3</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M48</td>
                            <td>5</td>
                            <td>3</td>
                            <td>–</td>
                        </tr>
                        <tr>
                            <td>M56</td>
                            <td>5.5</td>
                            <td>3</td>
                            <td>–</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>)
}
export default Fastner;