import { Col, Row } from 'antd';
import "./Home.css";
import { CheckCircleOutlined, RightOutlined } from '@ant-design/icons';
import pipetube from '../../img/pipetube.jpg';

const AboutFlometals = ({ readMore = true }) => {
    return (<div className='info_about_company'>
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <div className='about_title'>FLO METALS & TUBES - ABOUT US</div>
            </Col>
            <Col xs={24} md={11}>
                <img src={pipetube} width='100%' style={{ borderRadius: '15px' }} />
            </Col>
            <Col xs={0} md={1}></Col>
            <Col xs={24} md={12}>
                {readMore && <p className='about_description'>We take pride in introducing ourselves as one of the leading manufacturer, stockist, and supplier of a comprehensive range of premium quality Carbon Steel Pipes & Tubes, Buttwelded Fitting, Stainless Steel and Ferrous and Non-Ferrous metal items that your company requires in all aspects of project implementation, maintenance, modernization, and so on. Carbon Steel Pipes and Tubes, Plate, Coils, Sheet, Round Bar/Rods, Buttweld Fittings, & Socketweld Fittings are all available from us. Products are well-known for their excellent service, durability, and effectiveness.
                    <br />
                    We have developed adequate knowledge in this sector over the years and have earned considerable business from reputable chemical companies such as petrochemical corporations, oil refineries, pharmaceutical companies, pulp and paper mills, cement plants, and fertiliser plants.
                </p>}
                {!readMore && <p className='about_description'>We take pride in introducing ourselves as one of the leading Manufacturer, Stockist, Supplier, and Exporter of a comprehensive range of premium quality Stainless Steel, Ferrous and Non-Ferrous metal items that your company requires in all aspects of project implementation, maintenance, modernization, and so on. Stainless Steel Pipes and Tubes, Plate, Coils, Sheet, Round Bar/Rods, Buttweld Fittings, &amp; Socketweld Fittings, are all available from us. Products are well-known for their excellent service, durability, and effectiveness.
                    <br />
                    We have developed adequate knowledge in this sector over the years and have earned considerable business from reputable chemical companies such as petrochemical corporations, oil refineries, pharmaceutical companies, pulp and paper mills, cement plants, and fertiliser plants. We have also supplied dairies, breweries, sugar mills, nuclear and thermal power plants, engineering firms, and national-level projects. Many of our clients place repeat purchases with us, which says volumes about the quality of our products and service.
                </p>}
                {readMore && <div className='about_read_more'><a style={{ color: 'black' }} href='/aboutus'>READ MORE <RightOutlined /></a></div>}
                <br />
            </Col>
            <Col xs={24} md={12}>
                <div className='about_col2_title'><CheckCircleOutlined className='about_col2_icon' /> Certified company</div>
                <p className='about_description' style={{ margin: '0' }}>Flo Metal & Tubes is an Certified Company.</p>
                <br />
            </Col>
            <Col xs={24} md={12}>
                <div className='about_col2_title'><CheckCircleOutlined className='about_col2_icon' /> Our experience</div>
                <p className='about_description' style={{ margin: '0' }}>We are proud of the experience and skills we have earned by working on several challenging and rewarding projects around the world.</p>
            </Col>
        </Row>
    </div>)
}
export default AboutFlometals;