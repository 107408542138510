import './Enquiry.css';
import EnquiryForm from './EnquiryForm';
import { Row, Col } from 'antd';

const Enquiry = () => {
    return (<div>
        <div className="enquiry-page-title">
            Send An Enquiry
        </div>
        <div className="enquiry">
            <div className="enquiry-title">MAKE AN ENQUIRY</div>
            <div className="enquiry_description">Please fill out the form below to enquire about a product. Each field is required.</div>
            <br />
            <Row>
                <Col xs={0} lg={6}></Col>
                <Col xs={24} lg={12}> <EnquiryForm /></Col>
                <Col xs={0} lg={6}></Col>
            </Row>
        </div>
    </div>)
}
export default Enquiry;