import './Products.css';
import PageHeader from '../../PageHeader';
import { Row, Col } from 'antd';
import SocialMedia from './SocialMedia';
import flatsanglechannelmanufacturer from '../../img/Product/flatsanglechannelmanufacturer.jpg';
import flatbarmanufacturer from '../../img/Product/flatbarmanufacturer.jpg';
import anglemanufcaturer from '../../img/Product/anglemanufcaturer.jpg';
import channelmanufacturer from '../../img/Product/channelmanufacturer.jpg';
import beammanufacturer from '../../img/Product/beammanufacturer.jpg';
import '../Pages.css';

const FlatsAngleChannelAndBeamManfacturer = () => {
    return (<div style={{ width: '100vw' }}>
        <PageHeader title='Flats, Angle, Channel & Beam Manfacturer in India' />
        <div className='product-info'>
            <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                    <img src={flatsanglechannelmanufacturer} width="100%" height="100%" className='product-img' />
                </Col>
                <Col xs={24} md={12}>
                    <div className='product-info-title'>Flats, Angle, Channel & Beam Manufacturer, Supplier, and Stockists in India</div>
                    <div className='product-info-description'>Flo Metal & Tubes is a well-known <b>Flats, Angle, Channel & Beam Manufacturer, Supplier, and Exporter in India</b>. We have a significant manufacturer and supplier of Stainless Steel <u>Angle, Channel, Flat Bar & Beam.</u> Flats, Angle, Channel & Beam are utilised in many sectors, including oil and gas, petroleum, petrochemical, chemical, machine construction, cars, and so on. To suit the demands of our clients, these Flats, Angle, Channel & Beam are available in a range of sizes, specifications, grade materials, and forms.</div>
                </Col>
                <Col xs={0} md={24}><br /></Col>
            </Row>
            <br />
            <div className='product-info-title'>Flats, Angle, Channel & Beam Manufacturer, Carbon Steel Flats, Angle, Channel & Beam Supplier, Duplex Steel Flats, Angle, Channel & Beam Stockists, Nickel Alloy Flats, Angle, Channel & Beam Manufacturer in India.</div>
            <div className='product-info-description'>Flo Metal & Tubes is one of India's leading manufacturer of high-quality <u>Flats, Angle, Channel & Beam</u> that are used in a variety of industries. <b>Flats, Angle, Channel & Beam</b> is created by pressing a hard circular steel 'material' over a form and heating it until the steel is wrought into a Flats, Angle, Channel & Beam. Our <em>Adaptors</em> provide consistent strength and structure throughout the Flats, Angle, Channel & Beam body. As a result, these Flats, Angle, Channel & Beam can withstand greater temperatures, higher pressures, corrosive environments, and increased mechanical stress.</div>
            <div className='product-info-description'> We have a competent team to ensure the quality of <b>Flats, Angle, Channel & Beam</b> and other goods throughout the manufacturing and supplying process, which includes selecting high-quality raw materials, turning them into completed products, identifying, packing, storage, and transportation. We are also the largest Supplier of <a href="/products/socketweld-fittings">Socketweld Fittings</a>.</div>
            <br />
            <div className='product-info-title'>Flats, Angle, Channel & Beam Specifications</div>
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th colspan="2"><strong>Flats, Angle, Channel & Beam</strong></th>
                        </tr>
                        <tr>
                            <td><strong>Stainless Steel</strong></td>
                            <td> SA 240 TP 304/ 304L/ 304H/ 316/ 316L/ 316Ti/ 317/ 317L/ 321/ 309/ 310/ 347/ 409/ 410/
                                430/ 904L Etc.</td>
                        </tr>
                        <tr>
                            <td><strong>Other Alloys </strong></td>
                            <td> : Nickel, Monel, Inconel, Hastelloy, Alloy20, Incoloy, Duplex & Super Duplex, 5M06-254,
                                Carbon Steel, Alloy Steel, Copper, Brass, Cupro Nickel, Aluminium, Titanium Etc. </td>
                        </tr>

                        <tr>
                            <td><strong>Types </strong></td>
                            <td> Angle, Channel, Flat Bar, Beams Etc</td>
                        </tr>


                    </tbody>
                </table>
            </div>
            <br />
            <div className='product-info-title'>Types of Flats, Angle, Channel & Beam</div>
            <Row align='middle' gutter={[32, 16]}>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={flatbarmanufacturer} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Flat</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={anglemanufcaturer} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Angle</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={channelmanufacturer} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Channel</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={beammanufacturer} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Beam</div>
                </Col>
                <Col xs={3} sm={0}></Col>
            </Row>
            <br />
            <SocialMedia />
            <br />
            <div className='product-info-title'>Sheet, Plate and Coils Supplier - Additional Information</div>
            <div className='product-info-description'>
                <ol>
                    <li>Sheet, Plate and Coils Payment Modes: LC (Letter of Credit), TT (Telegraphic Transfer or Wire Transfer), Cheque, and others.</li>
                    <li>Sheet, Plate and Coils Packaging: Sheet, Plate and Coils are packaged with caution and safety so they reach our client’s as good as he would expect. Packaging Charges Extra.</li>
                    <li>Sheet, Plate and Coils Port of Dispatch: Mumbai ports in the Maharashtra state of India.</li>
                    <li>Sheet, Plate and Coils Tax: 18% GST.</li>
                </ol>
            </div>
            <div style={{ padding: '2vw 0', textAlign: 'center' }}><span className='markup-text'>Flo Metal & Tubes is a leading Carbon Steel Pipes and Tubes Manufacturer in India.</span></div>
            <br />
            <div className='product-info-title'>Flats, Angle, Channel & Beam Supplier - Additional Information</div>
            <div className='product-info-description'>
                <ol>
                    <li>Flats, Angle, Channel & Beam Payment Modes: LC (Letter of Credit), TT (Telegraphic Transfer or Wire Transfer), Cheque, and others.</li>
                    <li>Flats, Angle, Channel & Beam Packaging: Flats, Angle, Channel & Beam are packaged with caution and safety so they reach our client’s as good as he would expect. Packaging Charges Extra.</li>
                    <li>Flats, Angle, Channel & Beam Port of Dispatch: Mumbai ports in the Maharashtra state of India.</li>
                    <li>Flats, Angle, Channel & Beam Tax: 18% GST.</li>
                </ol>
            </div>
            <div className='product-info-title'>Applications & Uses of Flats, Angle, Channel & Beam</div>
            <div className='product-info-description'>
                <ul>
                    <li>Flats, Angle used in Sugar Industry.</li>
                    <li>Channel & Beam used in Power Industry.</li>
                    <li>Flats & Beam used in ShipBuilding Industry.</li>
                    <li>Angle & Channel used in Mechanical and Plant engineering.</li>
                    <li>Flats & Channel used in Food, Beverage, and Dairy.</li>
                    <li>Flats, Angle, Channel & Beam used in Oil and Gas Plant.</li>
                    <li>Angle & Beam used in Process Instrumentation.</li>
                </ul>
            </div>
            {/* <div className='product-info-title'>Cities we supply Flats, Angle, Channel & Beam in India :-</div>
            <div className='product-info-description'>Flo Metal & Tubes is one of India's leading suppliers, dealers, manufacturers, and stockists of <b>Flats, Angle, Channel & Beam</b> due to its dependability and performance. Every month, we supply/export containers of <u>Flats, Angle, Channel & Beam</u> to a range of clients from Indian ports. We supply our products in a number of cities around India.
                <br />
                <div className="table-responsive">
                    <table border="1" className='product-info-description'>
                        <tbody>
                            <tr>
                                <th colspan="6">Flats, Angle, Channel & Beam Supply:</th>
                            </tr>
                            <tr>
                                <td>Bengaluru</td>
                                <td>Chennai</td>
                                <td>Hyderabad</td>
                                <td>Kolkata</td>
                                <td>Mumbai</td>
                                <td>Pune</td>
                            </tr>
                            <tr>
                                <td>Bhopal</td>
                                <td>Sri City</td>
                                <td>Jamshedpur</td>
                                <td>Hosdurg</td>
                                <td>Lucknow</td>
                                <td>Noida</td>
                            </tr>
                            <tr>
                                <td>Ahmedabad</td>
                                <td>Delhi</td>
                                <td>Raipur</td>
                                <td>Jaipur</td>
                                <td>Surat</td>
                                <td>Salem</td>
                            </tr>
                            <tr>
                                <td>Jamnagar</td>
                                <td>Bhubaneswar</td>
                                <td>Sivakasi</td>
                                <td>Rajahmundry</td>
                                <td>Thiruvananthapuram</td>
                                <td>Tiruppur</td>
                            </tr>
                            <tr>
                                <td>Bhiwandi</td>
                                <td>Bokaro Steel City</td>
                                <td>Bharuch</td>
                                <td>Vijaywada</td>
                                <td>Firozabad</td>
                                <td>Rajkot</td>
                            </tr>
                            <tr>
                                <td>Cochin</td>
                                <td>Ludhiana</td>
                                <td>Durgapur</td>
                                <td>Panna</td>
                                <td>Panipat</td>
                                <td>Raipur</td>
                            </tr>
                            <tr>
                                <td>Bareilly</td>
                                <td>Channapatna</td>
                                <td>Kharagpur</td>
                                <td>Peenya</td>
                                <td>Pimpri-Chinchwad</td>
                                <td>Nashik</td>
                            </tr>
                            <tr>
                                <td>Bangalore</td>
                                <td>Indore</td>
                                <td>Haldia</td>
                                <td>Moradabad</td>
                                <td>Varanasi</td>
                                <td>Rourkela</td>
                            </tr>
                            <tr>
                                <td>Bhopal</td>
                                <td>Sri City</td>
                                <td>Jamshedpur</td>
                                <td>Hosdurg</td>
                                <td>Lucknow</td>
                                <td>Noida</td>
                            </tr>
                            <tr>
                                <td>Bareilly</td>
                                <td>Coimbatore</td>
                                <td>Kannur</td>
                                <td>Nagpur</td>
                                <td>Vadodara</td>
                                <td>Rudrapur</td>
                            </tr>
                            <tr>
                                <td>Agra</td>
                                <td>Bhagalpur</td>
                                <td>Bhilai</td>
                                <td>Thane</td>
                                <td>Kanpur</td>
                                <td>Haldia</td>
                            </tr>
                            <tr>
                                <td>Angul</td>
                                <td>Dibrugarh</td>
                                <td>Gwalior</td>
                                <td>Patna</td>
                                <td>Trivandrum</td>
                                <td>Pithampur</td>
                            </tr>   										</tbody>
                    </table>
                </div>
            </div>
            <div className='product-info-title'>Countries we export Flats, Angle, Channel & Beam worldwide :-</div>
            <div className='product-info-description'>More than 80 tons of <b>Flats, Angle, Channel & Beam</b> are exported from India each year, with Flo Metal & Tubes accounting for 25% of the total. We export to a range of countries on a regular basis for our new clients. Because of our constant supply of <u>Flats, Angle, Channel & Beam</u>, we can export and satisfy our clients' demands and requests.
                <br />
                <div className="table-responsive">
                    <table border="1" className='product-info-description'>
                        <tbody>
                            <tr>
                                <th colspan="6">Flats, Angle, Channel & Beam Exporter:</th>
                            </tr>
                            <tr>
                                <td>United States</td>
                                <td>South Africa</td>
                                <td>Malaysia</td>
                                <td>Bangladesh</td>
                                <td>Turkey</td>
                            </tr>
                            <tr>
                                <td>Bahrain</td>
                                <td>Qatar</td>
                                <td>UAE</td>
                                <td>Africa</td>
                                <td>China</td>
                            </tr>
                            <tr>
                                <td>Nigeria</td>
                                <td>Mexico</td>
                                <td>Canada</td>
                                <td>Venezuela</td>
                                <td>United Kingdom</td>
                            </tr>
                            <tr>
                                <td>Egypt</td>
                                <td>Jordan</td>
                                <td>Portugal</td>
                                <td>Russia</td>
                                <td>Vietnam</td>
                            </tr>
                            <tr>
                                <td>Kazakhstan</td>
                                <td>Thailand</td>
                                <td>Spain</td>
                                <td>Romania</td>
                                <td>Philippines</td>
                            </tr>
                            <tr>
                                <td>Hongkong</td>
                                <td>Taiwan</td>
                                <td>Japan</td>
                                <td>Ukraine</td>
                                <td>Chile</td>
                            </tr>
                            <tr>
                                <td>United States</td>
                                <td>South Africa</td>
                                <td>Malaysia</td>
                                <td>Bangladesh</td>
                                <td>Turkey</td>
                            </tr>
                            <tr>
                                <td>Australia</td>
                                <td>Brazil</td>
                                <td>Germany</td>
                                <td>Singapore</td>
                                <td>Sri Lanka</td>
                            </tr>
                            <tr>
                                <td>Iran</td>
                                <td>Saudi Arabia</td>
                                <td>Oman</td>
                                <td>Kuwait</td>
                                <td>Netherlands</td>
                            </tr>
                            <tr>
                                <td>Belgium</td>
                                <td>France</td>
                                <td>Italy</td>
                                <td>South Korea</td>
                                <td>Poland</td>
                            </tr>

                            <tr>
                                <td>Costa Rica</td>
                                <td>Israel</td>
                                <td>Iraq</td>
                                <td>Madagascar</td>
                                <td>Morocco</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> */}

        </div>
    </div>)
}
export default FlatsAngleChannelAndBeamManfacturer;