import './Products.css';
import PageHeader from '../../PageHeader';
import { Row, Col } from 'antd';
import SocialMedia from './SocialMedia';
import stainlesssteelbuttweldfittings from '../../img/Product/stainlesssteelbuttweldfittings.jpg';
import elbowsupplier from '../../img/Product/elbowsupplier.jpg';
import returnbendsupplier from '../../img/Product/returnbendsupplier.jpg';
import equalteesupplier from '../../img/Product/equalteesupplier.jpg';
import reducingteeexporter from '../../img/Product/reducingteeexporter.jpg';
import crossteemanufacturer from '../../img/Product/crossteemanufacturer.jpg';
import longsrtubendsupplier from '../../img/Product/longsrtubendsupplier.jpg';
import shortstubendstockist from '../../img/Product/shortstubendstockist.jpg';
import reducerexporter from '../../img/Product/reducerexporter.jpg';
import endcupmanufacturer from '../../img/Product/endcupmanufacturer.jpg';
import '../Pages.css';

const ButtweldFittings = () => {
    return (<div style={{ width: '100vw' }}>
        <PageHeader title='Buttweld Fittings Manfacturer in India' />
        <div className='product-info'>
            <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                    <img src={stainlesssteelbuttweldfittings} width="100%" height="100%" className='product-img' />
                </Col>
                <Col xs={24} md={12}>
                    <div className='product-info-title'>Buttweld Fittings Manufacturer, Supplier, and Stockists in India</div>
                    <div className='product-info-description'>Flo Metal & Tubes is a well-known <b>Buttweld Fittings Manufacturer, Supplier, and Exporter in India</b>. We have a significant manufacturer and supplier of Stainless Steel <u>Elbow Fittings</u>, as well as Equal Tee. Buttweld Fittings are utilised in many sectors, including oil and gas, petroleum, petrochemical, chemical, machine construction, cars, and so on. To suit the demands of our clients, these Buttweld Fittings are available in a range of sizes, specifications, grade materials, and forms.</div>
                </Col>
                <Col xs={0} md={24}><br /></Col>
            </Row>
            <br />
            <div className='product-info-title'>Buttweld Fittings Manufacturer, Carbon Steel Buttweld Fittings Supplier, Duplex Steel Buttweld Fittings Stockists, Nickel Alloy Buttweld Fittings Manufacturer in India.</div>
            <div className='product-info-description'>Flo Metal & Tubes is one of India's leading manufacturer of high-quality <u>Buttweld Fittings</u> that are used in a variety of industries. <b>Elbow Fittings</b> is created by pressing a hard circular steel 'material' over a form and heating it until the steel is wrought into a hollow Fittings. Our <em>Cross Tee</em> provide consistent strength and structure throughout the Buttweld Fittings body. As a result, these Buttweld Fittings can withstand greater temperatures, higher pressures, corrosive environments, and increased mechanical stress.</div>
            <div className='product-info-description'> We have a competent team to ensure the quality of <b>Buttweld Fittings</b> and other goods throughout the manufacturing and supplying process, which includes selecting high-quality raw materials, turning them into completed products, identifying, packing, storage, and transportation. We are also the largest Supplier of <a href="/products/socketweld-fittings">Socketweld Fittings</a>.</div>
            <br />
            <div className='product-info-title'>Buttweld Fittings Specifications</div>
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th colspan="2"><strong>Buttweld Fittings</strong></th>
                        </tr>
                        <tr>
                            <td><strong>Size</strong></td>
                            <td>1/4"NB to 24"NB(seamless)<br />4"NB to 72"NB(welded)</td>
                        </tr>
                        <tr>
                            <td><strong>Type</strong></td>
                            <td>Elbow (1d/1.5d/3d/5d), 180° Return Bend / Miter Bend, Equal Tee, Reducing Tee / Cross Tee, Long Stubend, Short Stubend,Reducer (con./ecc.), End Cap / Dish End Ca</td>
                        </tr>
                        <tr>
                            <td colspan="2"><strong>Grade</strong></td>
                        </tr>
                        <tr>
                            <td><strong>Stainless Steel Buttweld Fittings </strong></td>
                            <td>ASTM A403, WP316/316L, ASTM A403 SA / A 774 WP-S, WP-W, WP-WX 304/304L, ASTM A182 F316L, 304L, DIN 1.4301, DIN1.4306, DIN 1.4401, DIN 1.4404</td>
                        </tr>
                        <tr>
                            <td><strong>Duplex & Super Duplex Steel Buttweld Fittings </strong></td>
                            <td>ASTM A 815, ASME SA 815 UNS NO S31803, S32205. UNS S32750, S32950. Werkstoff No. 1.4462</td>
                        </tr>
                        <tr>
                            <td><strong>Carbon Steel Buttweld Fittings </strong></td>
                            <td>ASTM A234, ASME SA234 WPB , WPBW, WPHY 42, WPHY 46, WPHY 52, WPH 60, WPHY 65 & WPHY 70.</td>
                        </tr>
                        <tr>
                            <td><strong>Alloy Steel Buttweld Fittings </strong></td>
                            <td>ASTM / ASME A/SA 234 Gr. WP 1, WP 5, WP 9, WP 11, WP 12, WP 22, WP 91</td>
                        </tr>
                        <tr>
                            <td><strong>Nickel Alloy Buttweld Fittings </strong></td>
                            <td>ASTM / ASME SB 564 UNS 2200 ( NICKEL 200 ), UNS 4400 (MONEL 400 ), UNS 8825 INCONEL (825), UNS 6600 (INCONEL 600 ), UNS 6601 ( INCONEL 601 ), UNS 6625 (INCONEL 625), UNS 10276 ( HASTELLOY C 276 )<br />ASTM / ASME SB 160 UNS 2201 (NICKEL 201 ),<br />ASTM / ASME SB 472 UNS 8020 ( ALLOY 20 / 20 CB 3 )</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div className='product-info-title'>Types of Pipes and Tubes</div>
            <Row align='middle' gutter={[32, 16]}>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={elbowsupplier} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Elbow</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={returnbendsupplier} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>180° Return Bend/Miterbend</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={equalteesupplier} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Equal Tee</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={reducingteeexporter} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Reducing Tee</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={crossteemanufacturer} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Cross Tee</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={longsrtubendsupplier} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Long Stubend</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={shortstubendstockist} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Short Stubend</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={reducerexporter} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Reducer (con./ecc.)</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={endcupmanufacturer} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>End Cap / Dish End Cap</div>
                </Col>
                <Col xs={3} sm={0}></Col>
            </Row>
            <br />
            <SocialMedia />
            <br />
            <div className='product-info-title'>Types of Pipes and Tubes</div>
            <div className='product-info-description'>
                <ol>
                    <li>Buttweld Fittings Payment Modes: LC (Letter of Credit), TT (Telegraphic Transfer or Wire Transfer), Cheque, and others.</li>
                    <li>Buttweld Fittings Packaging: Buttweld Fittings are packaged with caution and safety so they reach our client’s as good as he would expect. Packaging Charges Extra.</li>
                    <li>Buttweld Fittings Port of Dispatch: Mumbai ports in the Maharashtra state of India.</li>
                    <li>Buttweld Fittings Tax: 18% GST.</li>
                </ol>
            </div>
            <div style={{ padding: '2vw 0', textAlign: 'center' }}><span className='markup-text'>Flo Metal & Tubes is a leading Carbon Steel Pipes and Tubes Manufacturer in India.</span></div>
            <br />
            <div className='product-info-title'>Applications & Uses of Buttweld Fittings</div>
            <div className='product-info-description'>
                <ul>
                    <li>Buttweld Fittings used in Sugar Industry.</li>
                    <li>Buttweld Fittings used in Power Industry.</li>
                    <li>Buttweld Fittings used in ShipBuilding Industry.</li>
                    <li>Buttweld Fittings used in Mechanical and Plant engineering.</li>
                    <li>Buttweld Fittings used in Food, Beverage, and Dairy.</li>
                    <li>Buttweld Fittings used in Oil and Gas Plant.</li>
                    <li>Buttweld Fittings used in Process Instrumentation.</li>
                </ul>
            </div>
            {/* <div className='product-info-title'>Cities we supply Buttweld Fittings in India :-</div>
            <div className='product-info-description'>Flo Metal & Tubes is one of India's leading suppliers, dealers, manufacturers, and stockists of <b>Buttweld Fittings</b> due to its dependability and performance. Every month, we supply/export containers of <u>Buttweld Fittings</u> to a range of clients from Indian ports. We supply our products in a number of cities around India.
                <br />
                <div className="table-responsive">
                    <table border="1" className='product-info-description'>
                        <tbody>
                            <tr>
                                <th colspan="6">Buttweld Fittings Supply:</th>
                            </tr>
                            <tr>
                                <td>Bengaluru</td>
                                <td>Chennai</td>
                                <td>Hyderabad</td>
                                <td>Kolkata</td>
                                <td>Mumbai</td>
                                <td>Pune</td>
                            </tr>
                            <tr>
                                <td>Bhopal</td>
                                <td>Sri City</td>
                                <td>Jamshedpur</td>
                                <td>Hosdurg</td>
                                <td>Lucknow</td>
                                <td>Noida</td>
                            </tr>
                            <tr>
                                <td>Ahmedabad</td>
                                <td>Delhi</td>
                                <td>Raipur</td>
                                <td>Jaipur</td>
                                <td>Surat</td>
                                <td>Salem</td>
                            </tr>
                            <tr>
                                <td>Jamnagar</td>
                                <td>Bhubaneswar</td>
                                <td>Sivakasi</td>
                                <td>Rajahmundry</td>
                                <td>Thiruvananthapuram</td>
                                <td>Tiruppur</td>
                            </tr>
                            <tr>
                                <td>Bhiwandi</td>
                                <td>Bokaro Steel City</td>
                                <td>Bharuch</td>
                                <td>Vijaywada</td>
                                <td>Firozabad</td>
                                <td>Rajkot</td>
                            </tr>
                            <tr>
                                <td>Cochin</td>
                                <td>Ludhiana</td>
                                <td>Durgapur</td>
                                <td>Panna</td>
                                <td>Panipat</td>
                                <td>Raipur</td>
                            </tr>
                            <tr>
                                <td>Bareilly</td>
                                <td>Channapatna</td>
                                <td>Kharagpur</td>
                                <td>Peenya</td>
                                <td>Pimpri-Chinchwad</td>
                                <td>Nashik</td>
                            </tr>
                            <tr>
                                <td>Bangalore</td>
                                <td>Indore</td>
                                <td>Haldia</td>
                                <td>Moradabad</td>
                                <td>Varanasi</td>
                                <td>Rourkela</td>
                            </tr>
                            <tr>
                                <td>Bhopal</td>
                                <td>Sri City</td>
                                <td>Jamshedpur</td>
                                <td>Hosdurg</td>
                                <td>Lucknow</td>
                                <td>Noida</td>
                            </tr>
                            <tr>
                                <td>Bareilly</td>
                                <td>Coimbatore</td>
                                <td>Kannur</td>
                                <td>Nagpur</td>
                                <td>Vadodara</td>
                                <td>Rudrapur</td>
                            </tr>
                            <tr>
                                <td>Agra</td>
                                <td>Bhagalpur</td>
                                <td>Bhilai</td>
                                <td>Thane</td>
                                <td>Kanpur</td>
                                <td>Haldia</td>
                            </tr>
                            <tr>
                                <td>Angul</td>
                                <td>Dibrugarh</td>
                                <td>Gwalior</td>
                                <td>Patna</td>
                                <td>Trivandrum</td>
                                <td>Pithampur</td>
                            </tr>                                           </tbody>
                    </table>
                </div>
            </div>
            <div className='product-info-title'>Countries we export Buttweld Fittings worldwide :-</div>
            <div className='product-info-description'>More than 80 tons of <b>Buttweld Fittings</b> are exported from India each year, with Flo Metal & Tubes accounting for 25% of the total. We export to a range of countries on a regular basis for our new clients. Because of our constant supply of <u>Buttweld Fittings</u>, we can export and satisfy our clients' demands and requests.
                <br />
                <div className="table-responsive">
                    <table border="1" className='product-info-description'>
                        <tbody>
                            <tr>
                                <th colspan="6">Buttweld Fittings Exporter:</th>
                            </tr>
                            <tr>
                                <td>United States</td>
                                <td>South Africa</td>
                                <td>Malaysia</td>
                                <td>Bangladesh</td>
                                <td>Turkey</td>
                            </tr>
                            <tr>
                                <td>Bahrain</td>
                                <td>Qatar</td>
                                <td>UAE</td>
                                <td>Africa</td>
                                <td>China</td>
                            </tr>
                            <tr>
                                <td>Nigeria</td>
                                <td>Mexico</td>
                                <td>Canada</td>
                                <td>Venezuela</td>
                                <td>United Kingdom</td>
                            </tr>
                            <tr>
                                <td>Egypt</td>
                                <td>Jordan</td>
                                <td>Portugal</td>
                                <td>Russia</td>
                                <td>Vietnam</td>
                            </tr>
                            <tr>
                                <td>Kazakhstan</td>
                                <td>Thailand</td>
                                <td>Spain</td>
                                <td>Romania</td>
                                <td>Philippines</td>
                            </tr>
                            <tr>
                                <td>Hongkong</td>
                                <td>Taiwan</td>
                                <td>Japan</td>
                                <td>Ukraine</td>
                                <td>Chile</td>
                            </tr>
                            <tr>
                                <td>United States</td>
                                <td>South Africa</td>
                                <td>Malaysia</td>
                                <td>Bangladesh</td>
                                <td>Turkey</td>
                            </tr>
                            <tr>
                                <td>Australia</td>
                                <td>Brazil</td>
                                <td>Germany</td>
                                <td>Singapore</td>
                                <td>Sri Lanka</td>
                            </tr>
                            <tr>
                                <td>Iran</td>
                                <td>Saudi Arabia</td>
                                <td>Oman</td>
                                <td>Kuwait</td>
                                <td>Netherlands</td>
                            </tr>
                            <tr>
                                <td>Belgium</td>
                                <td>France</td>
                                <td>Italy</td>
                                <td>South Korea</td>
                                <td>Poland</td>
                            </tr>

                            <tr>
                                <td>Costa Rica</td>
                                <td>Israel</td>
                                <td>Iraq</td>
                                <td>Madagascar</td>
                                <td>Morocco</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> */}

        </div>
    </div>)
}
export default ButtweldFittings;