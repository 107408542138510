import { Row, Col } from "antd";
import './Home.css'
import bg from '../../img/alloybg.png';
import ProductInfo from "./ProductInfo";

const Alloy = () => {
    return (<div className="alloy" style={{ backgroundImage: `url(${bg})`, color: '#1848A0' }}>
        <ProductInfo title='ALLOY FOR ALL APPLICATION' />
        <Row gutter={[48, 32]} justify='center'>
            <Col span={24}></Col>
            <Col xs={16} md={12} lg={6}>
                <div className='alloy-info-small' style={{ float: 'start' }}>
                    24<br />Cr<br />Chorme
                </div>
                <Row justify={'end'}>
                    <div className='alloy-info-small'>
                        28<br />Ni<br />Nickel
                    </div>
                </Row>
                <div className="alloy-title">Stainless Steel</div>
            </Col>
            <Col xs={16} md={12} lg={6}>
                <div className='alloy-info-small' style={{ float: 'start' }}>
                    24<br />Cr<br />Chorme
                </div>
                <Row justify={'end'}>
                    <div className='alloy-info-small'>
                        42<br />Ni<br />Nickel
                    </div>
                </Row>
                <div className="alloy-title">Alloy Steel</div>
            </Col>
            <Col xs={24} md={12} lg={6}>
                <div className='alloy-info-big'>
                    6<br />C<br />Carbon
                </div>
                <div className="alloy-title">Carbon Steel</div>
            </Col>
            <Col xs={24} md={12} lg={6}>
                <div className='alloy-info-big'>
                    28<br />Ni<br />Nickel
                </div>
                <div className="alloy-title">Nickel Alloys</div>
            </Col>
        </Row>
    </div>)
}
export default Alloy;