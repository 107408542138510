
import PageHeader from '../../PageHeader';
import EnquiryForm from '../Enquiry/EnquiryForm';
import { Row, Col } from 'antd';
import './Contact.css';
import { ContactsFilled, PhoneFilled, MailFilled } from '@ant-design/icons';

const Contact = () => {
    return (<div>
        <PageHeader title='Contact Us' />
        <div className='contact-us-info'>
            <Row gutter={[48, 32]}>
                <Col xs={24} lg={12} >
                    <div className='contactus'>
                        <div className='contact-us-info-title'>- Flo Metal & Tubes</div>
                        <br />
                        <div className='contact-us-info-description'><ContactsFilled className='contact-us-icon' /> flo pipes and fittings co 5 / 502 a wing,suryadarshan building, s.s.m.marg, kumbharwada 2nd lane, mumbai - 400004.</div>
                        <div className='contact-us-info-description'> <PhoneFilled className='contact-us-icon' /><a href="tel:919820810810">+91 98208 10810</a></div>
                        <div className='contact-us-info-description'><PhoneFilled className='contact-us-icon' /> <a href="tel:919820810810">+91 98208 10810</a></div>
                        <div className='contact-us-info-description'> <MailFilled className='contact-us-icon' /><a href="mailto:flometaltubes@gmail.com">flometaltubes@gmail.com</a></div>
                    </div>
                </Col>
                <Col xs={24} lg={12}>
                    <EnquiryForm />
                </Col>
            </Row>
        </div>
    </div>)
}
export default Contact;