import './Products.css';
import PageHeader from '../../PageHeader';
import { Row, Col } from 'antd';
import SocialMedia from './SocialMedia';
import coppertubesmanufacturer from '../../img/Product/coppertubesmanufacturer.jpg';
import '../Pages.css';

const CopperTubesManufacturer = () => {
    return (<div style={{ width: '100vw' }}>
        <PageHeader title='Copper Tubes Manfacturer in India' />
        <div className='product-info'>
            <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                    <img src={coppertubesmanufacturer} width="100%" height="100%" className='product-img' />
                </Col>
                <Col xs={24} md={12}>
                    <div className='product-info-title'>Copper Tubes Manufacturer, Supplier, and Stockists in India</div>
                    <div className='product-info-description'>Flo Metal &amp; Tubes is a well-known <b>Copper Tubes Manufacturer, Supplier, and Exporter in India</b>. We have a significant manufacturer and supplier of <u>Copper Tubes</u>. Copper Tubes are utilised in many sectors, including oil and gas, petroleum, petrochemical, chemical, machine construction, cars, and so on. To suit the demands of our clients, these Copper Tubes are available in a range of sizes, specifications, grade materials, and forms.</div>
                </Col>
                <Col xs={0} md={24}><br /></Col>
            </Row>
            <br />
            <div className='product-info-title'>Copper Tubes Supplier, Copper Tubes Stockists, Copper Tubes Dealer, Copper Tubes Manufacturer in India.</div>
            <div className='product-info-description'>Flo Metal &amp; Tubes is one of India's leading manufacturer of high-quality <u>Copper Tubes</u> that are used in a variety of industries. <b>Copper Tubes</b> is created by pressing a hard circular Copper 'material' over a form and heating it until the Copper is wrought into a Copper Tubes. Our <em>Copper</em> provide consistent strength and structure throughout the Copper Tubes body. As a result, these Copper Tubes can withstand greater temperatures, higher pressures, corrosive environments, and increased mechanical stress.</div>
            <div className='product-info-description'>We have a competent team to ensure the quality of <b>Copper Tubes</b> and other goods throughout the manufacturing and supplying process, which includes selecting high-quality raw materials, turning them into completed products, identifying, packing, storage, and transportation. We are also the largest Supplier of <a href="/products/socketweld-fittings">Socketweld Fittings</a>.</div>
            <br />
            <div className='product-info-title'>Copper Tubes Specifications</div>
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th colspan="2"><strong> Copper Tubes</strong></th>
                        </tr>
                        <tr>
                            <td><strong>Tubes</strong></td>
                            <td> 2mm OD to 200mm OD with Wall thickness of
                                0.10 mm to 15 mm in length upto 10 mtrs straight
                                (in coils upto 25 mtrs) in copper, Brass &amp; Curpronickel.</td>
                        </tr>
                        <tr>
                            <td><strong>Rods</strong></td>
                            <td>In all size upto 160mm diameter in Copper, Brass and Bronzes.

                            </td>
                        </tr>

                        <tr>
                            <td><strong>Strips/Profiles </strong></td>
                            <td> Copper Strips and sections as per clients specific requirements.</td>
                        </tr>
                        <tr>
                            <td><strong>Wires </strong></td>
                            <td>Copper wires upto 42 swg in bright annealed condition.
                            </td>
                        </tr>
                        <tr>
                            <td><strong>S. E. Wires </strong></td>
                            <td>Super enamelled copper wires upto 42 swg.
                            </td>
                        </tr>



                    </tbody>
                </table>
            </div>
            <br />
            <SocialMedia />
            <br />
            <div className='product-info-title'>Copper Tubes Supplier - Additional Information</div>
            <div className='product-info-description'>
                <ol>
                    <li>Copper Tubes Payment Modes: LC (Letter of Credit), TT (Telegraphic Transfer or Wire Transfer), Cheque, and others.</li>
                    <li>Copper Tubes Packaging: Copper Tubes are packaged with caution and safety so they reach our client’s as good as he would expect. Packaging Charges Extra.</li>
                    <li>Copper Tubes Port of Dispatch: Mumbai ports in the Maharashtra state of India.</li>
                    <li>Copper Tubes Tax: 18% GST.</li>
                </ol>
            </div>
            <div style={{ padding: '2vw 0', textAlign: 'center' }}><span className='markup-text'>Flo Metal & Tubes is a leading Carbon Steel Pipes and Tubes Manufacturer in India.</span></div>
            <br />
            <div className='product-info-title'>Applications & Uses of Copper Tubes</div>
            <div className='product-info-description'>
                <ul>
                    <li>Copper Tubes used in Sugar Industry.</li>
                    <li>Copper Tubes used in Power Industry.</li>
                    <li>Copper Tubes used in ShipBuilding Industry.</li>
                    <li>Copper Tubes used in Mechanical and Plant engineering.</li>
                    <li>Copper Tubes used in Food, Beverage, and Dairy.</li>
                    <li>Copper Tubes used in Oil and Gas Plant.</li>
                    <li>Copper Tubes used in Process Instrumentation.</li>
                </ul>
            </div>
            {/* <br />
            <div className='product-info-title'>Cities we supply Copper Tubes in India :-</div>
            <div className='product-info-description'>Flo Metal &amp; Tubes is one of India's leading suppliers, dealers, manufacturers, and stockists of <b>Copper Tubes</b> due to its dependability and performance. Every month, we supply/export containers of <u>Copper Tubes</u> to a range of clients from Indian ports. We supply our products in a number of cities around India.
                <br />
                <div className="table-responsive">
                    <table border="1" className='product-info-description'>
                        <tbody>
                            <tr>
                                <th colspan="6">Copper Tubes Supply:</th>
                            </tr>
                            <tr>
                                <td>Bengaluru</td>
                                <td>Chennai</td>
                                <td>Hyderabad</td>
                                <td>Kolkata</td>
                                <td>Mumbai</td>
                                <td>Pune</td>
                            </tr>
                            <tr>
                                <td>Bhopal</td>
                                <td>Sri City</td>
                                <td>Jamshedpur</td>
                                <td>Hosdurg</td>
                                <td>Lucknow</td>
                                <td>Noida</td>
                            </tr>
                            <tr>
                                <td>Ahmedabad</td>
                                <td>Delhi</td>
                                <td>Raipur</td>
                                <td>Jaipur</td>
                                <td>Surat</td>
                                <td>Salem</td>
                            </tr>
                            <tr>
                                <td>Jamnagar</td>
                                <td>Bhubaneswar</td>
                                <td>Sivakasi</td>
                                <td>Rajahmundry</td>
                                <td>Thiruvananthapuram</td>
                                <td>Tiruppur</td>
                            </tr>
                            <tr>
                                <td>Bhiwandi</td>
                                <td>Bokaro Steel City</td>
                                <td>Bharuch</td>
                                <td>Vijaywada</td>
                                <td>Firozabad</td>
                                <td>Rajkot</td>
                            </tr>
                            <tr>
                                <td>Cochin</td>
                                <td>Ludhiana</td>
                                <td>Durgapur</td>
                                <td>Panna</td>
                                <td>Panipat</td>
                                <td>Raipur</td>
                            </tr>
                            <tr>
                                <td>Bareilly</td>
                                <td>Channapatna</td>
                                <td>Kharagpur</td>
                                <td>Peenya</td>
                                <td>Pimpri-Chinchwad</td>
                                <td>Nashik</td>
                            </tr>
                            <tr>
                                <td>Bangalore</td>
                                <td>Indore</td>
                                <td>Haldia</td>
                                <td>Moradabad</td>
                                <td>Varanasi</td>
                                <td>Rourkela</td>
                            </tr>
                            <tr>
                                <td>Bhopal</td>
                                <td>Sri City</td>
                                <td>Jamshedpur</td>
                                <td>Hosdurg</td>
                                <td>Lucknow</td>
                                <td>Noida</td>
                            </tr>
                            <tr>
                                <td>Bareilly</td>
                                <td>Coimbatore</td>
                                <td>Kannur</td>
                                <td>Nagpur</td>
                                <td>Vadodara</td>
                                <td>Rudrapur</td>
                            </tr>
                            <tr>
                                <td>Agra</td>
                                <td>Bhagalpur</td>
                                <td>Bhilai</td>
                                <td>Thane</td>
                                <td>Kanpur</td>
                                <td>Haldia</td>
                            </tr>
                            <tr>
                                <td>Angul</td>
                                <td>Dibrugarh</td>
                                <td>Gwalior</td>
                                <td>Patna</td>
                                <td>Trivandrum</td>
                                <td>Pithampur</td>
                            </tr>   										</tbody>
                    </table>
                </div>
            </div>
            <div className='product-info-title'>Countries we export Copper Tubes worldwide :-</div>
            <div className='product-info-description'>More than 80 tons of <b>Copper Tubes</b> are exported from India each year, with Flo Metal &amp; Tubes accounting for 25% of the total. We export to a range of countries on a regular basis for our new clients. Because of our constant supply of <u>Copper Tubes</u>, we can export and satisfy our clients' demands and requests.
                <br />
                <div className="table-responsive">
                    <table border="1" className='product-info-description'>
                        <tbody>
                            <tr>
                                <th colspan="6">Copper Tubes Exporter:</th>
                            </tr>
                            <tr>
                                <td>United States</td>
                                <td>South Africa</td>
                                <td>Malaysia</td>
                                <td>Bangladesh</td>
                                <td>Turkey</td>
                            </tr>
                            <tr>
                                <td>Bahrain</td>
                                <td>Qatar</td>
                                <td>UAE</td>
                                <td>Africa</td>
                                <td>China</td>
                            </tr>
                            <tr>
                                <td>Nigeria</td>
                                <td>Mexico</td>
                                <td>Canada</td>
                                <td>Venezuela</td>
                                <td>United Kingdom</td>
                            </tr>
                            <tr>
                                <td>Egypt</td>
                                <td>Jordan</td>
                                <td>Portugal</td>
                                <td>Russia</td>
                                <td>Vietnam</td>
                            </tr>
                            <tr>
                                <td>Kazakhstan</td>
                                <td>Thailand</td>
                                <td>Spain</td>
                                <td>Romania</td>
                                <td>Philippines</td>
                            </tr>
                            <tr>
                                <td>Hongkong</td>
                                <td>Taiwan</td>
                                <td>Japan</td>
                                <td>Ukraine</td>
                                <td>Chile</td>
                            </tr>
                            <tr>
                                <td>United States</td>
                                <td>South Africa</td>
                                <td>Malaysia</td>
                                <td>Bangladesh</td>
                                <td>Turkey</td>
                            </tr>
                            <tr>
                                <td>Australia</td>
                                <td>Brazil</td>
                                <td>Germany</td>
                                <td>Singapore</td>
                                <td>Sri Lanka</td>
                            </tr>
                            <tr>
                                <td>Iran</td>
                                <td>Saudi Arabia</td>
                                <td>Oman</td>
                                <td>Kuwait</td>
                                <td>Netherlands</td>
                            </tr>
                            <tr>
                                <td>Belgium</td>
                                <td>France</td>
                                <td>Italy</td>
                                <td>South Korea</td>
                                <td>Poland</td>
                            </tr>

                            <tr>
                                <td>Costa Rica</td>
                                <td>Israel</td>
                                <td>Iraq</td>
                                <td>Madagascar</td>
                                <td>Morocco</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> */}
        </div>
    </div>)
}
export default CopperTubesManufacturer;