
import Brand from './Brand';
import ProductSlider from './ProductSlider';
import Slider from './Slider';
import AboutFlometals from './AboutFlometals';
import ProductInfo from './ProductInfo';
import Materials from './Materials';
import StandardFollowesSlider from './StandardFollowesSlider';
import FloMetalAndTubes from './FloMetalAndTubes';
import Countires from './Countires';
import Alloy from './Alloy';

const Home = () => {
    return (<div className='home'>
        <Slider />
        <AboutFlometals />
        <br />
        <ProductInfo title='OUR PRODUCTS' description='We are leading Manufacturer, Supplier, Dealer, Exporter of Pipes & Tubes, Buttweld Fittings, Socketweld Fittings, Flanges, Sheets, Plates & Coils in India.' />
        <br />
        <ProductSlider />
        <Brand />
        <br />
        <ProductInfo title='MATERIALS' description='We are leading Manufacturer & Supplier of Stainless Steel, Nickel Alloys, Titanium Alloys, Brass, Bronze, Copper, Monel, Nickel, Hastalloy, Inconel, Copper, Brass, Aluminium, products and High Temperature steel in India.' />
        <Materials />
        <StandardFollowesSlider />
        <br />
        <br />
        <ProductInfo title='FLO METALS & TUBES' description='Industries We Serve.' />
        <FloMetalAndTubes />
        <br />
        <Countires />
        <Alloy />
    </div>)
}
export default Home;