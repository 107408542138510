import PageHeader from "../../PageHeader";
import './TechnicalInformation.css';
import '../Pages.css';

const TechnicalInformation = () => {
    return (<div>
        <PageHeader title='Technical Information' />
        <div className="technical-info">
        <div className="technical-info-title">Stainless Steel Chemical Composition</div>
        <div className="technical_conditions_description">
            <div className="table-responsive">
            <table className='technical-info-description'>
                <tbody>
                    <tr>
                        <th>Grade</th>
                        <th>C (Max)</th>
                        <th>Mn (Max)</th>
                        <th>P (Max)</th>
                        <th>S (Max)</th>
                        <th>Si (Max)</th>
                        <th>Cr</th>
                        <th>Ni</th>
                        <th>Mo</th>
                        <th>Nitrogen (Max)</th>
                        <th>Cu/ Others</th>
                    </tr>
                    <tr>
                        <td>201</td>
                        <td>0.15</td>
                        <td>5.50/7.50</td>
                        <td>0.06</td>
                        <td>0.030</td>
                        <td>1.0</td>
                        <td>16.0/18.0</td>
                        <td>3.5/5.5</td>
                        <td>-</td>
                        <td>-</td>
                        <td>n-25 max</td>
                    </tr>
                    <tr>
                        <td>202</td>
                        <td>0.15</td>
                        <td>7.50/10.0</td>
                        <td>0.06</td>
                        <td>0.030.</td>
                        <td>1.0</td>
                        <td>17.0/19.0</td>
                        <td>4.0/6.0</td>
                        <td>-</td>
                        <td>-</td>
                        <td>n-25 max</td>
                    </tr>
                    <tr>
                        <td>JS- 203</td>
                        <td>0.08</td>
                        <td>9.2510.25</td>
                        <td>0.070</td>
                        <td>0.030</td>
                        <td>0.75</td>
                        <td>14.25 - 15.25</td>
                        <td>2.25 - 2.75</td>
                        <td>-</td>
                        <td>-</td>
                        <td>1.60- 2.0</td>
                    </tr>
                    <tr>
                        <td>301</td>
                        <td>0.15</td>
                        <td>2.00</td>
                        <td>0.045</td>
                        <td>0.030</td>
                        <td>1.00</td>
                        <td>16.00 - 18.00</td>
                        <td>6.00 - 8.00</td>
                        <td>-</td>
                        <td>0.10</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>301M</td>
                        <td>0.10</td>
                        <td>4.55.5</td>
                        <td>0.060</td>
                        <td>0.030</td>
                        <td>0.75</td>
                        <td>14.50 - 15.50</td>
                        <td>6.0 - 7.0</td>
                        <td>-</td>
                        <td>-</td>
                        <td>1.70- 1.90</td>
                    </tr>
                    <tr>
                        <td>304</td>
                        <td>0.08</td>
                        <td>2.00</td>
                        <td>0.045</td>
                        <td>0.030</td>
                        <td>0.75</td>
                        <td>18.00 - 20.00</td>
                        <td>8.00- 10.50</td>
                        <td>-</td>
                        <td>0.10</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>304L</td>
                        <td>0.030</td>
                        <td>2.00</td>
                        <td>0.045</td>
                        <td>0.030</td>
                        <td>0.75</td>
                        <td>18.00 - 20.00</td>
                        <td>8.00- 12.00</td>
                        <td>-</td>
                        <td>0.10</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>309s</td>
                        <td>0.08</td>
                        <td>2.0</td>
                        <td>0.045</td>
                        <td>0.030</td>
                        <td>1.0</td>
                        <td>22.0/24.0</td>
                        <td>12.0/15.0</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>310</td>
                        <td>0.25</td>
                        <td>2.0</td>
                        <td>0.045</td>
                        <td>0.030</td>
                        <td>1.50</td>
                        <td>24.0/26.0</td>
                        <td>19.0/22.0</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>310S</td>
                        <td>0.08</td>
                        <td>2.00</td>
                        <td>0.045</td>
                        <td>0.030</td>
                        <td>1.50</td>
                        <td>24.00- 26.00</td>
                        <td>19.00 - 22.00</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>316</td>
                        <td>0.08</td>
                        <td>2.00</td>
                        <td>0.045</td>
                        <td>0.030</td>
                        <td>0.75</td>
                        <td>16.00 - 18.00</td>
                        <td>10.00 - 14.00</td>
                        <td>2.00 - 3.00</td>
                        <td>0.10</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>316L</td>
                        <td>0.030</td>
                        <td>2.00</td>
                        <td>0.045</td>
                        <td>0.030</td>
                        <td>0.75</td>
                        <td>16.00 - 18.00</td>
                        <td>10.00 - 14.00</td>
                        <td>2.00 - 3.00</td>
                        <td>0.10</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>317</td>
                        <td>0.08</td>
                        <td>2.00</td>
                        <td>0.045</td>
                        <td>0.030</td>
                        <td>0.75</td>
                        <td>18.00 - 20.00</td>
                        <td>11.00 - 14.00</td>
                        <td>3.00 - 4.00</td>
                        <td>0.10</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>317L</td>
                        <td>0.030</td>
                        <td>2.00</td>
                        <td>0.045</td>
                        <td>0.030</td>
                        <td>0.75</td>
                        <td>18.00 - 20.00</td>
                        <td>11.00 - 15.00</td>
                        <td>3.00 - 4.00</td>
                        <td>0.10</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>321</td>
                        <td>0.08</td>
                        <td>2.00</td>
                        <td>0.045</td>
                        <td>0.030</td>
                        <td>0.75</td>
                        <td>17.00 - 19.00</td>
                        <td>9.00 - 12.00</td>
                        <td>-</td>
                        <td>0.10</td>
                        <td>Ti5 ( C + N ) Min or 0.70 max</td>
                    </tr>
                    <tr>
                        <td>347</td>
                        <td>0.08</td>
                        <td>2.00</td>
                        <td>0.045</td>
                        <td>0.030</td>
                        <td>0.75</td>
                        <td>17.00 - 19.00</td>
                        <td>9.00 - 13.00</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Cb= 10x ( C Min ) or 1.00 Max</td>
                    </tr>
                    <tr>
                        <td>409</td>
                        <td>0.08</td>
                        <td>1.00</td>
                        <td>0.040</td>
                        <td>0.010</td>
                        <td>1.00</td>
                        <td>10.50 - 11.75</td>
                        <td>0.50</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Ti= 6x (C+ N ) Min or 0.70 Max</td>
                    </tr>
                    <tr>
                        <td>409M</td>
                        <td>0.03</td>
                        <td>0.81.2</td>
                        <td>0.030</td>
                        <td>0.030</td>
                        <td>0.40.75</td>
                        <td>11.00- 12.00</td>
                        <td>1.5 max.</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Ti= 6x (C) Min or 0.70 Max</td>
                    </tr>
                    <tr>
                        <td>410S</td>
                        <td>0.08</td>
                        <td>1.00</td>
                        <td>0.040</td>
                        <td>0.030</td>
                        <td>1.00</td>
                        <td>11.50- 13.50</td>
                        <td>0.60</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>410</td>
                        <td>0.15</td>
                        <td>1.00</td>
                        <td>0.040</td>
                        <td>0.030</td>
                        <td>1.00</td>
                        <td>11.50- 13.50</td>
                        <td>0.75</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>420</td>
                        <td>0.35</td>
                        <td>0.50</td>
                        <td>0.035</td>
                        <td>0.015</td>
                        <td>0.50</td>
                        <td>12.00 - 13.00</td>
                        <td>0.20.3</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>440A</td>
                        <td>0.60/0.70</td>
                        <td>1.0</td>
                        <td>0.040</td>
                        <td>0.030</td>
                        <td>1.0</td>
                        <td>16.0/18.0</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>440B</td>
                        <td>0.75,0.95</td>
                        <td>1.0</td>
                        <td>0.040</td>
                        <td>0.030</td>
                        <td>1.0</td>
                        <td>16.0/18.0</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>440C</td>
                        <td>0.95, 1.2</td>
                        <td>1.0</td>
                        <td>0.040</td>
                        <td>0.030</td>
                        <td>1.0</td>
                        <td>16.0/18.0</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>430</td>
                        <td>0.12</td>
                        <td>1.00</td>
                        <td>0.040</td>
                        <td>0.030</td>
                        <td>1.00</td>
                        <td>16.00 - 18.00</td>
                        <td>0.75</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>JSL AUS</td>
                        <td>0.08</td>
                        <td>7.08.0</td>
                        <td>0.070</td>
                        <td>0.030</td>
                        <td>0.75</td>
                        <td>15.50 - 16.50</td>
                        <td>4.25 - 4.75</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.9 - 1.10</td>
                    </tr>
                    <tr>
                        <td>904L</td>
                        <td>0.02</td>
                        <td>2.00</td>
                        <td>0.030</td>
                        <td>0.045</td>
                        <td>1.00</td>
                        <td>19.0-23.0</td>
                        <td>23.0-28.0</td>
                        <td>-</td>
                        <td>-</td>
                        <td>23.0-28.0</td>
                    </tr>
                    <tr>
                        <td>2205</td>
                        <td>0.03</td>
                        <td>2.00</td>
                        <td>0.030</td>
                        <td>0.020</td>
                        <td>1.00</td>
                        <td>21.0-23.0</td>
                        <td>4.5-6.5</td>
                        <td>4.5-6.5</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td>2207</td>
                        <td>0.030</td>
                        <td>1.20</td>
                        <td>0.035</td>
                        <td>0.020</td>
                        <td>0.30</td>
                        <td>24.0-26.0</td>
                        <td>6.0-8.0</td>
                        <td>-</td>
                        <td>-</td>
                        <td>6.0-8.0</td>
                    </tr>
                    <tr>
                        <td colspan="11">* Thickness of 1.27mm &amp; below will have elongation of 20% min.</td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
        <br/>
        <div className="technical-info-title">Stainless Steel Mechanical Properties</div>
        <div className="technical_conditions_description">
        <div className="table-responsive">
            <table className='technical-info-description'>
                <tbody>
                    <tr>
                        <th colspan="6">MECHANICAL PROPERTIES</th>
                    </tr>
                    <tr>
                        <td>Grade</td>
                        <td>Tensile</td>
                        <td>Yield</td>
                        <td>%Age</td>
                        <td>Hardness (Max)</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>Strength Mpa, (Min)</td>
                        <td>Strength</td>
                        <td>Elongation</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>Mpa, (Min)</td>
                        <td>in 50mm</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>gauge length min</td>
                        <td>BHN</td>
                        <td>Rb</td>
                    </tr>
                    <tr>
                        <td>301</td>
                        <td>515</td>
                        <td>205</td>
                        <td>40</td>
                        <td>217</td>
                        <td>95</td>
                    </tr>
                    <tr>
                        <td>304</td>
                        <td>515</td>
                        <td>205</td>
                        <td>40</td>
                        <td>201</td>
                        <td>92</td>
                    </tr>
                    <tr>
                        <td>304L</td>
                        <td>485</td>
                        <td>170</td>
                        <td>40</td>
                        <td>201</td>
                        <td>92</td>
                    </tr>
                    <tr>
                        <td>310S</td>
                        <td>515</td>
                        <td>205</td>
                        <td>40</td>
                        <td>217</td>
                        <td>95</td>
                    </tr>
                    <tr>
                        <td>316</td>
                        <td>515</td>
                        <td>205</td>
                        <td>40</td>
                        <td>217</td>
                        <td>95</td>
                    </tr>
                    <tr>
                        <td>316L</td>
                        <td>485</td>
                        <td>170</td>
                        <td>40</td>
                        <td>217</td>
                        <td>95</td>
                    </tr>
                    <tr>
                        <td>317</td>
                        <td>515</td>
                        <td>205</td>
                        <td>35</td>
                        <td>217</td>
                        <td>95</td>
                    </tr>
                    <tr>
                        <td>317L</td>
                        <td>515</td>
                        <td>205</td>
                        <td>40</td>
                        <td>217</td>
                        <td>95</td>
                    </tr>
                    <tr>
                        <td>321</td>
                        <td>515</td>
                        <td>205</td>
                        <td>40</td>
                        <td>217</td>
                        <td>95</td>
                    </tr>
                    <tr>
                        <td>347</td>
                        <td>515</td>
                        <td>205</td>
                        <td>40</td>
                        <td>201</td>
                        <td>92</td>
                    </tr>
                    <tr>
                        <td>409</td>
                        <td>380</td>
                        <td>170</td>
                        <td>20</td>
                        <td>179</td>
                        <td>88</td>
                    </tr>
                    <tr>
                        <td>409M</td>
                        <td>430</td>
                        <td>275</td>
                        <td>20</td>
                        <td>187</td>
                        <td>90</td>
                    </tr>
                    <tr>
                        <td>410S</td>
                        <td>415</td>
                        <td>205</td>
                        <td>22</td>
                        <td>183</td>
                        <td>89</td>
                    </tr>
                    <tr>
                        <td>410</td>
                        <td>450</td>
                        <td>205</td>
                        <td>20</td>
                        <td>217</td>
                        <td>89</td>
                    </tr>
                    <tr>
                        <td>420</td>
                        <td>700( max )</td>
                        <td>-</td>
                        <td>15</td>
                        <td>217</td>
                        <td>95</td>
                    </tr>
                    <tr>
                        <td>430</td>
                        <td>450</td>
                        <td>205</td>
                        <td>22</td>
                        <td>183</td>
                        <td>89</td>
                    </tr>
                    <tr>
                        <td>JSL AUS</td>
                        <td>515</td>
                        <td>205</td>
                        <td>40</td>
                        <td>217</td>
                        <td>95</td>
                    </tr>
                    <tr>
                        <td>JS- 203</td>
                        <td>515</td>
                        <td>205</td>
                        <td>40</td>
                        <td>217</td>
                        <td>95</td>
                    </tr>
                    <tr>
                        <td>301M</td>
                        <td>515</td>
                        <td>205</td>
                        <td>40</td>
                        <td>217</td>
                        <td>95</td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
        <br/>
        <div className="technical-info-title">Nickel Alloy Chemical Composition</div>
        <div className="technical_conditions_description">
        <div className="table-responsive">
            <table className='technical-info-description'>
                <tbody>
                    <tr>
                        <th colspan="14">U.S.A. / GROSSBRITAINNIE- U.S.A. / GRANDE-BRETAGNE-- U.S.A. / GREAT BRITAIN</th>
                    </tr>
                    <tr>
                        <td>Handelsbezeichung</td>
                        <td>C%</td>
                        <td>Co%</td>
                        <td>Cr%</td>
                        <td>Mo%</td>
                        <td>Ni%</td>
                        <td>V%</td>
                        <td>W%</td>
                        <td>Ai%</td>
                        <td>Cu%</td>
                        <td>Nb/Cb Ta%</td>
                        <td>Ti%</td>
                        <td>Fe%</td>
                        <td>Sonstige Autres-Other%</td>
                    </tr>
                    <tr>
                        <td>Designation Comercial</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Comercial designation</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Monel 400</td>
                        <td>0.12</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>65</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>32</td>
                        <td>-</td>
                        <td>-</td>
                        <td>1.5</td>
                        <td>Mn 1.0</td>
                    </tr>
                    <tr>
                        <td>Monel 401</td>
                        <td>0.1</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>43</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>53</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.75</td>
                        <td>Si 0.25; Mn 2.25</td>
                    </tr>
                    <tr>
                        <td>Monel 404</td>
                        <td>0.15</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>52.0-57.0</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.05</td>
                        <td>rest/bal</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.5</td>
                        <td>Mn 0.10; Si 0.10;S o.024</td>
                    </tr>
                    <tr>
                        <td>Monel 502</td>
                        <td>0.1</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>63.0-17.0</td>
                        <td>-</td>
                        <td>-</td>
                        <td>2.5-3.5</td>
                        <td>rest/bal</td>
                        <td>-</td>
                        <td>0.5</td>
                        <td>2</td>
                        <td>Mn 1.5;Si 0.5; S 0.010</td>
                    </tr>
                    <tr>
                        <td>Monel K 500</td>
                        <td>0.13</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>64</td>
                        <td>-</td>
                        <td>-</td>
                        <td>2.8</td>
                        <td>30</td>
                        <td>-</td>
                        <td>0.6</td>
                        <td>1</td>
                        <td>Mn 0.8</td>
                    </tr>
                    <tr>
                        <td>Monel R 405</td>
                        <td>0.15</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>66</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>31</td>
                        <td>-</td>
                        <td>-</td>
                        <td>1.2</td>
                        <td>Mn 1.0; S 0.04</td>
                    </tr>
                    <tr>
                        <td>Hastelloy B</td>
                        <td>0.1</td>
                        <td>1.25</td>
                        <td>0.6</td>
                        <td>28</td>
                        <td>rest/bal</td>
                        <td>0.3</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>5.5</td>
                        <td>Mn 0.80; Si 0.70</td>
                    </tr>
                    <tr>
                        <td>Hastelloy B2</td>
                        <td>0.02</td>
                        <td>1</td>
                        <td>1</td>
                        <td>26.0-30.0</td>
                        <td>rest/bal</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>2</td>
                        <td>Mn 1.0: Si 0.10</td>
                    </tr>
                    <tr>
                        <td>Hastelloy C</td>
                        <td>0.07</td>
                        <td>1.25</td>
                        <td>16</td>
                        <td>17</td>
                        <td>rest/bal</td>
                        <td>0.3</td>
                        <td>40</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>5.75</td>
                        <td>Mn 1.0 :Si 0 0.70</td>
                    </tr>
                    <tr>
                        <td>Hastelloy C4</td>
                        <td>0.015</td>
                        <td>2</td>
                        <td>14.0-18.0</td>
                        <td>14.0-17.0</td>
                        <td>rest/bal</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0..70</td>
                        <td>3</td>
                        <td>Mn 1.0 ; Si 0.08</td>
                    </tr>
                    <tr>
                        <td>Hastelloy- C 276</td>
                        <td>0.02</td>
                        <td>2.5</td>
                        <td>14.0-16.5</td>
                        <td>15.0-17.0</td>
                        <td>rest/bal</td>
                        <td>0.35</td>
                        <td>3.0-4.5</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>4.0-7.0</td>
                        <td>Mn 1.0;Si 0.05</td>
                    </tr>
                    <tr>
                        <td>Hastelloy F</td>
                        <td>0.02</td>
                        <td>1.25</td>
                        <td>22</td>
                        <td>6.5</td>
                        <td>rest/bal</td>
                        <td>-</td>
                        <td>0.5</td>
                        <td>-</td>
                        <td>-</td>
                        <td>Nb2.10</td>
                        <td>-</td>
                        <td>21</td>
                        <td>Mn 1.50;Si 0.50</td>
                    </tr>
                    <tr>
                        <td>Hastelloy G</td>
                        <td>0.05</td>
                        <td>2.5</td>
                        <td>21.0-23.5</td>
                        <td>5.5-7.5</td>
                        <td>rest/bal</td>
                        <td>-</td>
                        <td>1</td>
                        <td>-</td>
                        <td>1.5-2.5</td>
                        <td>Nb1.75-2.5</td>
                        <td>-</td>
                        <td>18.0-21.0</td>
                        <td>Mn 1.0-2.0;p0.04;Si 1.0;80.03</td>
                    </tr>
                    <tr>
                        <td>Hastelloy G-2</td>
                        <td>0.03</td>
                        <td>-</td>
                        <td>23.0-26.0</td>
                        <td>5.0-7.0</td>
                        <td>47.0-52.0</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.70-1.20</td>
                        <td>-</td>
                        <td>0.70-1.50</td>
                        <td>rest/bal</td>
                        <td>Mn 1.0;Si 1.0</td>
                    </tr>
                    <tr>
                        <td>Hastelloy N</td>
                        <td>0.06</td>
                        <td>0.25</td>
                        <td>7</td>
                        <td>16.5</td>
                        <td>rest/bal</td>
                        <td>-</td>
                        <td>0.2</td>
                        <td>-</td>
                        <td>0.1</td>
                        <td>-</td>
                        <td>-</td>
                        <td>3</td>
                        <td>Mn 0.40;Si 0.25;B0.01</td>
                    </tr>
                    <tr>
                        <td>Hastelloy S</td>
                        <td>0.02</td>
                        <td>2</td>
                        <td>15.5</td>
                        <td>14.5</td>
                        <td>rest/bal</td>
                        <td>0.6</td>
                        <td>1</td>
                        <td>0.2</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>3</td>
                        <td>Mn 0.50;Si 0.40;B0.0009;LA 0.02</td>
                    </tr>
                    <tr>
                        <td>Hastelloy W</td>
                        <td>0.06</td>
                        <td>1.25</td>
                        <td>5</td>
                        <td>24.5</td>
                        <td>rest/bal</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>5.5</td>
                        <td>Mn 0.050 ; Si 0.50</td>
                    </tr>
                    <tr>
                        <td>Hastelloy X</td>
                        <td>0.1</td>
                        <td>1.5</td>
                        <td>22</td>
                        <td>9</td>
                        <td>rest/bal</td>
                        <td>-</td>
                        <td>0.6</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>18.5</td>
                        <td>-</td>
                        <td>Mn 0.6; Si 0.60</td>
                    </tr>
                    <tr>
                        <td>Incoloy 800</td>
                        <td>0.04</td>
                        <td>-</td>
                        <td>21</td>
                        <td>-</td>
                        <td>32</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.3</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.4</td>
                        <td>45</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Incoloy 800 H</td>
                        <td>0.08</td>
                        <td>-</td>
                        <td>21</td>
                        <td>-</td>
                        <td>32</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.3</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.4</td>
                        <td>45</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Incoloy 801</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>20.5</td>
                        <td>-</td>
                        <td>32</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>1.1</td>
                        <td>45</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Incoloy 802</td>
                        <td>0.35</td>
                        <td>-</td>
                        <td>21</td>
                        <td>-</td>
                        <td>32</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.6</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.7</td>
                        <td>45</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Incoloy 804</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>29.5</td>
                        <td>-</td>
                        <td>41</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.3</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.6</td>
                        <td>25.4</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Incoloy 805</td>
                        <td>0.12</td>
                        <td>-</td>
                        <td>7.5</td>
                        <td>0.5</td>
                        <td>36</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.1</td>
                        <td>-</td>
                        <td>-</td>
                        <td>rest/bal</td>
                        <td>Mn 0.60;Si 0.50</td>
                    </tr>
                    <tr>
                        <td>Incoloy 810</td>
                        <td>0.25</td>
                        <td>-</td>
                        <td>21</td>
                        <td>-</td>
                        <td>32</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.5</td>
                        <td>-</td>
                        <td>-</td>
                        <td>rest/bal</td>
                        <td>Mn 0.90; Si 0.80</td>
                    </tr>
                    <tr>
                        <td>Incoloy 825</td>
                        <td>0.04</td>
                        <td>-</td>
                        <td>21</td>
                        <td>3</td>
                        <td>42</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>2</td>
                        <td>-</td>
                        <td>1</td>
                        <td>30</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Incoloy 825 Cp</td>
                        <td>0.04</td>
                        <td>-</td>
                        <td>21.5</td>
                        <td>3</td>
                        <td>42</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>2.2</td>
                        <td>Nb0.9</td>
                        <td>-</td>
                        <td>30</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Incoloy 840</td>
                        <td>0.08</td>
                        <td>-</td>
                        <td>20</td>
                        <td>-</td>
                        <td>20</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>rest/bal</td>
                        <td>Mn 1.0;Si 1.0</td>
                    </tr>
                    <tr>
                        <td>Incoloy 901</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>12.5</td>
                        <td>6</td>
                        <td>rest/bal</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.15</td>
                        <td>-</td>
                        <td>-</td>
                        <td>2.7</td>
                        <td>34</td>
                        <td>Mn 0.24;Si 0.12;B0.015</td>
                    </tr>
                    <tr>
                        <td>Incoloy 901 Mod</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>12.5</td>
                        <td>5.8</td>
                        <td>rest/bal</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>2.9</td>
                        <td>34</td>
                        <td>Mn 0.09;Si 0.08;B0.015</td>
                    </tr>
                    <tr>
                        <td>Incoloy 903</td>
                        <td>0.02</td>
                        <td>15</td>
                        <td>-</td>
                        <td>-</td>
                        <td>38</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.7</td>
                        <td>-</td>
                        <td>Nb 3.0</td>
                        <td>1.4</td>
                        <td>41</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Incoloy 904</td>
                        <td>0.02</td>
                        <td>14</td>
                        <td>-</td>
                        <td>-</td>
                        <td>33</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>1.7</td>
                        <td>50</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Incoloy DS</td>
                        <td>0.06</td>
                        <td>-</td>
                        <td>18</td>
                        <td>-</td>
                        <td>37</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>42</td>
                        <td>Mn 1.0; Si 2.3</td>
                    </tr>
                    <tr>
                        <td>Incoloy Ma- 956</td>
                        <td>-</td>
                        <td>-</td>
                        <td>20</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>4.5</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.5</td>
                        <td>74.4</td>
                        <td>Y2.03 0.5</td>
                    </tr>
                    <tr>
                        <td>Incoloy 600</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>15.5</td>
                        <td>-</td>
                        <td>75</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>8</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Incoloy 601</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>23</td>
                        <td>-</td>
                        <td>60</td>
                        <td>-</td>
                        <td>-</td>
                        <td>1.4</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>14</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Incoloy 604</td>
                        <td>0.04</td>
                        <td>-</td>
                        <td>15.8</td>
                        <td>-</td>
                        <td>rest/bal</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.1</td>
                        <td>Nb 2.0</td>
                        <td>-</td>
                        <td>7.2</td>
                        <td>Mn 0.20; Si 0.20</td>
                    </tr>
                    <tr>
                        <td>Incoloy 610</td>
                        <td>0.2</td>
                        <td>-</td>
                        <td>15.5</td>
                        <td>-</td>
                        <td>rest/bal</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.5</td>
                        <td>Nb 1.0</td>
                        <td>-</td>
                        <td>9</td>
                        <td>Mn 0.90;Si 2.0</td>
                    </tr>
                    <tr>
                        <td>Incoloy 617</td>
                        <td>0.07</td>
                        <td>12.5</td>
                        <td>22.5</td>
                        <td>9</td>
                        <td>54</td>
                        <td>-</td>
                        <td>-</td>
                        <td>1</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Incoloy 625</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>21.5</td>
                        <td>9</td>
                        <td>61</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.4</td>
                        <td>-</td>
                        <td>Nb 3.65</td>
                        <td>0.4</td>
                        <td>2.5</td>
                        <td>Mn 0.50; Si 0.50</td>
                    </tr>
                    <tr>
                        <td>Incoloy 671</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>48</td>
                        <td>-</td>
                        <td>51</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.35</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>Incoloy 700</td>
                        <td>0.12</td>
                        <td>28.5</td>
                        <td>15</td>
                        <td>3.75</td>
                        <td>46</td>
                        <td>-</td>
                        <td>-</td>
                        <td>3</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>2.2</td>
                        <td>0.7</td>
                        <td>Mn 0.10; Si 0.30</td>
                    </tr>
                    <tr>
                        <td>Incoloy 702</td>
                        <td>0.04</td>
                        <td>-</td>
                        <td>15.6</td>
                        <td>-</td>
                        <td>rest/bal</td>
                        <td>-</td>
                        <td>-</td>
                        <td>3.4</td>
                        <td>0.1</td>
                        <td>-</td>
                        <td>0.7</td>
                        <td>0.35</td>
                        <td>Mn 0.05: Si 0.20</td>
                    </tr>
                    <tr>
                        <td>Incoloy 705</td>
                        <td>0.3</td>
                        <td>-</td>
                        <td>15.5</td>
                        <td>-</td>
                        <td>rest/bal</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.5</td>
                        <td>-</td>
                        <td>-</td>
                        <td>8</td>
                        <td>Mn 0.90 ; Si 5.5</td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
        <br/>
        <div className="technical-info-title">Carbon Steel Chemical Composition</div>
        <div className="technical_conditions_description">
        <div className="table-responsive">
            <table className='technical-info-description' >
                <tbody>
                    <tr>
                        <th rowspan="4"><strong>Speci -cation</strong></th>
                        <th rowspan="4"><strong>Grade</strong></th>
                        <th colspan="15"><strong>Chemical Composition [%]</strong></th>
                        <th colspan="6"><strong>Mechanical Properties</strong></th>
                    </tr>
                    <tr>
                        <th colspan="2" rowspan="2">C</th>
                        <th colspan="2" rowspan="2">Mn</th>
                        <th rowspan="2">P</th>
                        <th rowspan="2">S</th>
                        <th colspan="2" rowspan="2">Si</th>
                        <th colspan="2" rowspan="2">Cr</th>
                        <th colspan="2" rowspan="2">Mo</th>
                        <th rowspan="2">Cu</th>
                        <th rowspan="2">Ni</th>
                        <th rowspan="2">V</th>
                        <th colspan="2"><strong>Yield Strength</strong></th>
                        <th colspan="3"><strong>Tensile Strength</strong></th>
                        <th rowspan="3"><strong>% Elongation (G.L. 50 mm WT 8 mm) e (min.)</strong></th>
                    </tr>
                    <tr>
                        <th colspan="2"><strong>Min.</strong></th>
                        <th colspan="3"><strong>Min.</strong></th>
                    </tr>
                    <tr>
                        <th>Min</th>
                        <th>Max.</th>
                        <th>Min</th>
                        <th>Max.</th>
                        <th>Max.</th>
                        <th>Max.</th>
                        <th>Min.</th>
                        <th>Max</th>
                        <th>Min.</th>
                        <th>Max</th>
                        <th>Min.</th>
                        <th>Max</th>
                        <th>Max</th>
                        <th>Max</th>
                        <th>Max</th>
                        <th>PSI</th>
                        <th>MPa</th>
                        <th>PSI</th>
                        <th colspan="2">MPa</th>
                    </tr>
                    <tr>
                        <th colspan="23"><strong>HYDROCARBON PROCESS INDUSTRY/STRUCTURAL/PRESSURE VESSEL TUBES</strong></th>
                    </tr>
                    <tr>
                        <td>ASTM</td>
                        <td>A</td>
                        <td>-</td>
                        <td>0.25</td>
                        <td>-</td>
                        <td>0.95</td>
                        <td>0.05</td>
                        <td>0.045</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.4</td>
                        <td>-</td>
                        <td>0.15</td>
                        <td>0.40 for S</td>
                        <td>0.4</td>
                        <td>0.08</td>
                        <td>30,000</td>
                        <td>205</td>
                        <td>48,000</td>
                        <td>330</td>
                        <td colspan="2" rowspan="2">e=625000 A02/U09</td>
                    </tr>
                    <tr>
                        <td>A-53</td>
                        <td>B</td>
                        <td>-</td>
                        <td>0.3</td>
                        <td>-</td>
                        <td>1.2</td>
                        <td>0.05</td>
                        <td>0.045</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.4</td>
                        <td>-</td>
                        <td>0.15</td>
                        <td>0.40 for S</td>
                        <td>0.4</td>
                        <td>0.08</td>
                        <td>35,000</td>
                        <td>240</td>
                        <td>60,000</td>
                        <td>415</td>
                    </tr>
                    <tr>
                        <td>ASTM</td>
                        <td>A</td>
                        <td>-</td>
                        <td>0.25</td>
                        <td>0.27</td>
                        <td>0.93</td>
                        <td>0.035</td>
                        <td>0.035</td>
                        <td>0.1</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.4</td>
                        <td>-</td>
                        <td>0.15</td>
                        <td>0.4</td>
                        <td>0.4</td>
                        <td>0.08</td>
                        <td>30,000</td>
                        <td>205</td>
                        <td>48,000</td>
                        <td>330</td>
                        <td colspan="2">35L / 25T</td>
                    </tr>
                    <tr>
                        <td>A-106</td>
                        <td>B</td>
                        <td>-</td>
                        <td>0.3</td>
                        <td>0.29</td>
                        <td>1.06</td>
                        <td>0.035</td>
                        <td>0.035</td>
                        <td>0.1</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.4</td>
                        <td>-</td>
                        <td>0.15</td>
                        <td>0.4</td>
                        <td>-</td>
                        <td>0.08</td>
                        <td>35,000</td>
                        <td>240</td>
                        <td>60,000</td>
                        <td>415</td>
                        <td colspan="2">30L / 16.5T</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>c</td>
                        <td>-</td>
                        <td>0.35</td>
                        <td>0.29</td>
                        <td>1.06</td>
                        <td>0.035</td>
                        <td>0.035</td>
                        <td>0.1</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.4</td>
                        <td>-</td>
                        <td>0.15</td>
                        <td>0.4</td>
                        <td>-</td>
                        <td>0.08</td>
                        <td>40,000</td>
                        <td>275</td>
                        <td>70,000</td>
                        <td>485</td>
                        <td colspan="2">30L / 16.5T</td>
                    </tr>
                    <tr>
                        <td>ASTM</td>
                        <td>1</td>
                        <td>-</td>
                        <td>0.3</td>
                        <td>0.4</td>
                        <td>1.06</td>
                        <td>0.025</td>
                        <td>0.025</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>30,000</td>
                        <td>205</td>
                        <td>55,000</td>
                        <td>380</td>
                        <td colspan="2">35</td>
                    </tr>
                    <tr>
                        <td>A333 &amp; A 334</td>
                        <td>6</td>
                        <td>-</td>
                        <td>0.3</td>
                        <td>0.29</td>
                        <td>1.06</td>
                        <td>0.025</td>
                        <td>0.025</td>
                        <td>0.1</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>35,000</td>
                        <td>240</td>
                        <td>60,000</td>
                        <td>415</td>
                        <td colspan="2">30</td>
                    </tr>
                    <tr>
                        <td colspan="23"><strong>SEAMLESS COLD DRAWN PRECISION STEEL TUBES FOR MECHANICAL APPLICATIONS/HYDRAULIC/PNEUMATIC POWER SYSTEMS</strong></td>
                    </tr>
                    <tr>
                        <td>EN 10305-1/</td>
                        <td>E 235</td>
                        <td rowspan="2">-</td>
                        <td>0.17</td>
                        <td rowspan="2">-</td>
                        <td>1.2</td>
                        <td>0.025</td>
                        <td>0.025</td>
                        <td rowspan="2">-</td>
                        <td>0.35</td>
                        <td rowspan="2">-</td>
                        <td rowspan="2">-</td>
                        <td rowspan="2">-</td>
                        <td rowspan="2">-</td>
                        <td rowspan="2">-</td>
                        <td rowspan="2">-</td>
                        <td>0.02</td>
                        <td rowspan="2">-</td>
                        <td>235</td>
                        <td rowspan="2">-</td>
                        <td>340</td>
                        <td colspan="2">25</td>
                    </tr>
                    <tr>
                        <td>EN10305-4</td>
                        <td>E 355</td>
                        <td>0.22</td>
                        <td>1.6</td>
                        <td>0.025</td>
                        <td>0.025</td>
                        <td>0.55</td>
                        <td>0.02</td>
                        <td>355</td>
                        <td>490</td>
                        <td colspan="2">22</td>
                    </tr>
                    <tr>
                        <td rowspan="2">EN 10216-1</td>
                        <td>P195 TR1</td>
                        <td rowspan="2">-</td>
                        <td>0.13</td>
                        <td rowspan="2">-</td>
                        <td>0.7</td>
                        <td>0.025</td>
                        <td>0.02</td>
                        <td rowspan="2">-</td>
                        <td>0.35</td>
                        <td rowspan="2">-</td>
                        <td>0.3</td>
                        <td rowspan="2">-</td>
                        <td>0.08</td>
                        <td>0.3</td>
                        <td>0.3</td>
                        <td rowspan="2">-</td>
                        <td rowspan="2">-</td>
                        <td>195</td>
                        <td rowspan="2">-</td>
                        <td>320</td>
                        <td colspan="2">27</td>
                    </tr>
                    <tr>
                        <td>P235 TR1</td>
                        <td>0.16</td>
                        <td>1.2</td>
                        <td>0.025</td>
                        <td>0.02</td>
                        <td>0.35</td>
                        <td>0.3</td>
                        <td>0.08</td>
                        <td>0.3</td>
                        <td>0.3</td>
                        <td>235</td>
                        <td>360</td>
                        <td colspan="2">25</td>
                    </tr>
                    <tr>
                        <td>EN 10216-2</td>
                        <td>P235 GH</td>
                        <td>-</td>
                        <td>0.16</td>
                        <td>-</td>
                        <td>1.2</td>
                        <td>0.025</td>
                        <td>0.02</td>
                        <td>-</td>
                        <td>0.35</td>
                        <td>-</td>
                        <td>0.3</td>
                        <td>-</td>
                        <td>0.08</td>
                        <td>0.3</td>
                        <td>0.3</td>
                        <td>0.02</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td colspan="2">-</td>
                    </tr>
                    <tr>
                        <td rowspan="3">DIN 2391</td>
                        <td>St - 45</td>
                        <td>-</td>
                        <td>0.17</td>
                        <td>0.4</td>
                        <td>-</td>
                        <td>0.025</td>
                        <td>0.025</td>
                        <td>-</td>
                        <td>0.35</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td colspan="2">NBK : 215 / GBK : -</td>
                        <td colspan="2">NBK : 340 / GBK : 315</td>
                        <td colspan="2">NBK : 470</td>
                    </tr>
                    <tr>
                        <td>St - 45</td>
                        <td>-</td>
                        <td>0.21</td>
                        <td>0.4</td>
                        <td>-</td>
                        <td>0.025</td>
                        <td>0.025</td>
                        <td>-</td>
                        <td>0.35</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td colspan="2">NBK : 255 / GBK : -</td>
                        <td colspan="2">NBK : 440 / GBK : 390</td>
                        <td colspan="2">NBK : 570</td>
                    </tr>
                    <tr>
                        <td>St - 52</td>
                        <td>-</td>
                        <td>0.22</td>
                        <td>-</td>
                        <td>1.6</td>
                        <td>0.025</td>
                        <td>0.025</td>
                        <td>-</td>
                        <td>0.55</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td colspan="2">NBK : 355 / GBK : -</td>
                        <td colspan="2">NBK : 490 / GBK : 490</td>
                        <td colspan="2">NBK : 630</td>
                    </tr>
                    <tr>
                        <td>ASTM</td>
                        <td>SAE 1010</td>
                        <td>0.08</td>
                        <td>0.13 0.20</td>
                        <td>0.3</td>
                        <td>0.6</td>
                        <td>0.04</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td colspan="6" rowspan="8">As per Customer requirements</td>
                    </tr>
                    <tr>
                        <td>A-519</td>
                        <td>SAE 1018</td>
                        <td>0.15</td>
                        <td>0.28</td>
                        <td>0.6</td>
                        <td>0.9</td>
                        <td>0.04</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>SAE 1026</td>
                        <td>0.22</td>
                        <td>0.38</td>
                        <td>0.6</td>
                        <td>0.9</td>
                        <td>0.04</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>SAE 1035</td>
                        <td>0.32</td>
                        <td>0.44</td>
                        <td>0.6</td>
                        <td>0.9</td>
                        <td>0.04</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>SAE 1040</td>
                        <td>0.37</td>
                        <td>0.21</td>
                        <td>0.6</td>
                        <td>0.9</td>
                        <td>0.04</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>SAE 1518</td>
                        <td>0.15</td>
                        <td>0.44</td>
                        <td>1.1</td>
                        <td>1.4</td>
                        <td>0.04</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>SAE 1541</td>
                        <td>0.36</td>
                        <td>0.33</td>
                        <td>1.35</td>
                        <td>1.65</td>
                        <td>0.04</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>SAE 4130</td>
                        <td>0.28</td>
                        <td>-</td>
                        <td>0.4</td>
                        <td>0.6</td>
                        <td>0.04</td>
                        <td>0.04</td>
                        <td>0.15</td>
                        <td>0.35</td>
                        <td>0.8</td>
                        <td>1.1</td>
                        <td>0.15</td>
                        <td>0.25</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                    <tr>
                        <td colspan="23">BOILER/HEAT EXCHANGER/SUPERHEATER &amp; CONDENSER TUBES</td>
                    </tr>
                    <tr>
                        <td>ASTM A179</td>
                        <td>-</td>
                        <td>0.06</td>
                        <td>0.18</td>
                        <td>0.27</td>
                        <td>0.63</td>
                        <td>0.035</td>
                        <td>0.035</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>26,000</td>
                        <td>180</td>
                        <td>47,000</td>
                        <td>325</td>
                        <td colspan="2">35</td>
                    </tr>
                    <tr>
                        <td>ASTM A192</td>
                        <td>-</td>
                        <td>0.06</td>
                        <td>0.18</td>
                        <td>0.27</td>
                        <td>0.63</td>
                        <td>0.035</td>
                        <td>0.035</td>
                        <td>-</td>
                        <td>0.25</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>26,000</td>
                        <td>180</td>
                        <td>47,000</td>
                        <td>325</td>
                        <td colspan="2">35</td>
                    </tr>
                    <tr>
                        <td>ASTM</td>
                        <td>A-1</td>
                        <td>-</td>
                        <td>0.27</td>
                        <td>-</td>
                        <td>0.93</td>
                        <td>0.035</td>
                        <td>0.035</td>
                        <td>0.1</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>37,000</td>
                        <td>255</td>
                        <td>60,000</td>
                        <td>415</td>
                        <td colspan="2">30</td>
                    </tr>
                    <tr>
                        <td>A 210</td>
                        <td>C</td>
                        <td>-</td>
                        <td>0.35</td>
                        <td>0.29</td>
                        <td>1.06</td>
                        <td>0.035</td>
                        <td>0.035</td>
                        <td>0.1</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>40,000</td>
                        <td>275</td>
                        <td>70,000</td>
                        <td>485</td>
                        <td colspan="2">30</td>
                    </tr>
                    <tr>
                        <td>ASTM</td>
                        <td>T-11</td>
                        <td>0.05</td>
                        <td>0.15</td>
                        <td>0.3</td>
                        <td>0.6</td>
                        <td>0.025</td>
                        <td>0.025</td>
                        <td>0.5</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1.5</td>
                        <td>0.44</td>
                        <td>0.65</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>30,000</td>
                        <td>205</td>
                        <td>60,000</td>
                        <td>415</td>
                        <td colspan="2">30</td>
                    </tr>
                    <tr>
                        <td>A 213</td>
                        <td>T-12</td>
                        <td>0.05</td>
                        <td>0.15</td>
                        <td>0.3</td>
                        <td>0.61</td>
                        <td>0.025</td>
                        <td>0.025</td>
                        <td>-</td>
                        <td>0.5</td>
                        <td>0.8</td>
                        <td>1.25</td>
                        <td>0.44</td>
                        <td>0.65</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>32,000</td>
                        <td>220</td>
                        <td>60,000</td>
                        <td>415</td>
                        <td colspan="2">30</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>T-22</td>
                        <td>0.05</td>
                        <td>0.15</td>
                        <td>0.3</td>
                        <td>0.6</td>
                        <td>0.025</td>
                        <td>0.025</td>
                        <td>-</td>
                        <td>0.5</td>
                        <td>1.9</td>
                        <td>2.6</td>
                        <td>0.87</td>
                        <td>1.13</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>30,000</td>
                        <td>205</td>
                        <td>60,000</td>
                        <td>415</td>
                        <td colspan="2">30</td>
                    </tr>
                    <tr>
                        <td>ASTM</td>
                        <td>P-11</td>
                        <td>0.05</td>
                        <td>0.15</td>
                        <td>0.3</td>
                        <td>0.6</td>
                        <td>0.025</td>
                        <td>0.025</td>
                        <td>0.5</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1.5</td>
                        <td>0.44</td>
                        <td>0.65</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>30,000</td>
                        <td>205</td>
                        <td>60,000</td>
                        <td>415</td>
                        <td colspan="2">30</td>
                    </tr>
                    <tr>
                        <td>A335</td>
                        <td>P-12</td>
                        <td>0.05</td>
                        <td>0.15</td>
                        <td>0.3</td>
                        <td>0.61</td>
                        <td>0.025</td>
                        <td>0.025</td>
                        <td>-</td>
                        <td>0.5</td>
                        <td>0.8</td>
                        <td>1.25</td>
                        <td>0.44</td>
                        <td>0.65</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>32,000</td>
                        <td>220</td>
                        <td>60,000</td>
                        <td>415</td>
                        <td colspan="2">30</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>P-22</td>
                        <td>0.05</td>
                        <td>0.15</td>
                        <td>0.3</td>
                        <td>0.6</td>
                        <td>0.025</td>
                        <td>0.025</td>
                        <td>-</td>
                        <td>0.5</td>
                        <td>1.9</td>
                        <td>2.6</td>
                        <td>0.87</td>
                        <td>1.13</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>30,000</td>
                        <td>205</td>
                        <td>60,000</td>
                        <td>415</td>
                        <td colspan="2">30</td>
                    </tr>
                    <tr>
                        <td>BS 3059 Pt I</td>
                        <td>320</td>
                        <td>-</td>
                        <td>0.16</td>
                        <td>0.3</td>
                        <td>0.7</td>
                        <td>0.04</td>
                        <td>0.04</td>
                        <td>0.1</td>
                        <td>0.35</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>28,000</td>
                        <td>195</td>
                        <td>46,000</td>
                        <td>320</td>
                        <td colspan="2">69,500</td>
                    </tr>
                    <tr>
                        <td>BS 3059 Pt II</td>
                        <td>622-490</td>
                        <td>0.08</td>
                        <td>0.15</td>
                        <td>0.4</td>
                        <td>0.7</td>
                        <td>0.03</td>
                        <td>0.03</td>
                        <td>-</td>
                        <td>0.5</td>
                        <td>2</td>
                        <td>2.5</td>
                        <td>0.9</td>
                        <td>1.2</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>40,000</td>
                        <td>275</td>
                        <td>71,000</td>
                        <td>490</td>
                        <td colspan="2">93,000</td>
                    </tr>
                    <tr>
                        <td rowspan="2">DIN 1629</td>
                        <td>St- 37.0</td>
                        <td>-</td>
                        <td>0.17</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.04</td>
                        <td>0.04</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td colspan="2">WT 16mm = 235 / WT&gt;16mm = 225</td>
                        <td colspan="2">350</td>
                        <td colspan="2">-</td>
                    </tr>
                    <tr>
                        <td>St - 52</td>
                        <td>-</td>
                        <td>0.22</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.04</td>
                        <td>0.035</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td colspan="2">WT 16mm = 355 / WT&gt;16mm = 345</td>
                        <td colspan="2">-</td>
                        <td colspan="2">Long 21 / Trans 19</td>
                    </tr>
                    <tr>
                        <td rowspan="2">DIN 17175</td>
                        <td>St - 35.8</td>
                        <td>-</td>
                        <td>0.17</td>
                        <td>0.4</td>
                        <td>0.8</td>
                        <td>0.04</td>
                        <td>0.04</td>
                        <td>0.1</td>
                        <td>0.35</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td colspan="2">WT 16mm = 235 / WT&gt;16mm = 225</td>
                        <td colspan="2">360</td>
                        <td colspan="2">480</td>
                    </tr>
                    <tr>
                        <td>St - 45.8</td>
                        <td>-</td>
                        <td>0.21</td>
                        <td>0.4</td>
                        <td>1.2</td>
                        <td>0.04</td>
                        <td>0.04</td>
                        <td>0.1</td>
                        <td>0.35</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td colspan="2">WT 16mm = 255 / WT&gt;16mm = 245</td>
                        <td colspan="2">410</td>
                        <td colspan="2">530</td>
                    </tr>
                    <tr>
                        <td colspan="23"><strong>RAILWAYS</strong></td>
                    </tr>
                    <tr>
                        <td>IS:1239 Pt I</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>0.05</td>
                        <td>0.05</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>46,500</td>
                        <td>320</td>
                        <td colspan="2">-</td>
                    </tr>
                    <tr>
                        <td rowspan="3">IS:1161</td>
                        <td>YST-210</td>
                        <td>-</td>
                        <td>0.12</td>
                        <td>-</td>
                        <td>0.6</td>
                        <td>0.04</td>
                        <td>0.04</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>30,500</td>
                        <td>210</td>
                        <td>48,000</td>
                        <td>330</td>
                        <td colspan="2">-</td>
                    </tr>
                    <tr>
                        <td>YST-240</td>
                        <td>-</td>
                        <td>0.16</td>
                        <td>-</td>
                        <td>1.2</td>
                        <td>0.04</td>
                        <td>0.04</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>35,000</td>
                        <td>240</td>
                        <td>59,500</td>
                        <td>410</td>
                        <td colspan="2">-</td>
                    </tr>
                    <tr>
                        <td>YST-310</td>
                        <td>-</td>
                        <td>0.25</td>
                        <td>-</td>
                        <td>1.3</td>
                        <td>0.04</td>
                        <td>0.04</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>45,000</td>
                        <td>310</td>
                        <td>64,500</td>
                        <td>445</td>
                        <td colspan="2">-</td>
                    </tr>
                    <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
        <br/>
        <div className="technical-info-title">STAINLESS STEEL PIPE DIMENSION AS PER ASTM & WEIGHT-KG. PER MTR. (ANSI B36.19)</div>
        <div className="technical_conditions_description">
        <div className="table-responsive">
            <table className='technical-info-description'>
            <tbody>
                <tr>
                    <th colspan="2">Nominal Bore</th>
                    <th>Outside Diameter</th>
                    <th colspan="2">Sch-5S</th>
                    <th colspan="2">Sch-10S</th>
                    <th colspan="2">Sch-40S</th>
                    <th colspan="2">Sch-80S</th>
                    <th colspan="2">Sch-160S</th>
                    <th colspan="2">Sch-XXS</th>
                </tr>
                <tr>
                    <th rowspan="2">mm</th>
                    <th rowspan="2">INCH</th>
                    <th>mm</th>
                    <th>Wt</th>
                    <th>Weight</th>
                    <th>Wt</th>
                    <th>Weight</th>
                    <th>Wt</th>
                    <th>Weight</th>
                    <th>Wt</th>
                    <th>Weight</th>
                    <th>Wt</th>
                    <th>Weight</th>
                    <th>Wt</th>
                    <th>Weight</th>
                </tr>
                <tr>
                    <th>&nbsp;</th>
                    <th>mm</th>
                    <th>(Kg/mt)</th>
                    <th>mm</th>
                    <th>(Kg/mt)</th>
                    <th>mm</th>
                    <th>(Kg/mt)</th>
                    <th>mm</th>
                    <th>(Kg/mt)</th>
                    <th>mm</th>
                    <th>(Kg/mt)</th>
                    <th>mm</th>
                    <th>(Kg/mt)</th>
                </tr>
                <tr>
                    <td>3</td>
                    <td>43313</td>
                    <td>10.3</td>
                    <td>1.24</td>
                    <td>0.276</td>
                    <td>1.24</td>
                    <td>0.28</td>
                    <td>1.73</td>
                    <td>0.37</td>
                    <td>2.41</td>
                    <td>0.47</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>6</td>
                    <td>43191</td>
                    <td>13.7</td>
                    <td>1.24</td>
                    <td>0.39</td>
                    <td>1.65</td>
                    <td>0.49</td>
                    <td>2.24</td>
                    <td>0.631</td>
                    <td>3.02</td>
                    <td>0.8</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>10</td>
                    <td>43315</td>
                    <td>17.1</td>
                    <td>1.24</td>
                    <td>0.49</td>
                    <td>1.65</td>
                    <td>0.63</td>
                    <td>2.31</td>
                    <td>0.845</td>
                    <td>3.2</td>
                    <td>1.1</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>15</td>
                    <td>43132</td>
                    <td>21.3</td>
                    <td>1.65</td>
                    <td>0.8</td>
                    <td>2.11</td>
                    <td>1</td>
                    <td>2.77</td>
                    <td>1.27</td>
                    <td>3.75</td>
                    <td>1.62</td>
                    <td>4.75</td>
                    <td>1.94</td>
                    <td>7.47</td>
                    <td>2.55</td>
                </tr>
                <tr>
                    <td>20</td>
                    <td>43193</td>
                    <td>26.7</td>
                    <td>1.65</td>
                    <td>1.03</td>
                    <td>2.11</td>
                    <td>1.28</td>
                    <td>2.87</td>
                    <td>1.68</td>
                    <td>3.91</td>
                    <td>2.2</td>
                    <td>5.54</td>
                    <td>2.89</td>
                    <td>7.82</td>
                    <td>3.63</td>
                </tr>
                <tr>
                    <td>25</td>
                    <td>1</td>
                    <td>33.4</td>
                    <td>1.65</td>
                    <td>1.3</td>
                    <td>2.77</td>
                    <td>2.09</td>
                    <td>3.38</td>
                    <td>2.5</td>
                    <td>4.55</td>
                    <td>3.24</td>
                    <td>6.35</td>
                    <td>4.24</td>
                    <td>9.09</td>
                    <td>5.45</td>
                </tr>
                <tr>
                    <td>32</td>
                    <td>1.25</td>
                    <td>42.2</td>
                    <td>1.65</td>
                    <td>1.65</td>
                    <td>2.77</td>
                    <td>2.7</td>
                    <td>3.56</td>
                    <td>3.38</td>
                    <td>4.85</td>
                    <td>4.47</td>
                    <td>6.35</td>
                    <td>5.61</td>
                    <td>9.7</td>
                    <td>7.77</td>
                </tr>
                <tr>
                    <td>40</td>
                    <td>1.5</td>
                    <td>48.3</td>
                    <td>1.65</td>
                    <td>1.91</td>
                    <td>2.77</td>
                    <td>3.11</td>
                    <td>3.68</td>
                    <td>4.05</td>
                    <td>5.08</td>
                    <td>5.41</td>
                    <td>7.14</td>
                    <td>7.25</td>
                    <td>10.16</td>
                    <td>9.54</td>
                </tr>
                <tr>
                    <td>50</td>
                    <td>2</td>
                    <td>60.3</td>
                    <td>1.65</td>
                    <td>2.4</td>
                    <td>2.77</td>
                    <td>3.93</td>
                    <td>3.91</td>
                    <td>5.44</td>
                    <td>5.54</td>
                    <td>7.48</td>
                    <td>8.74</td>
                    <td>11.1</td>
                    <td>11.07</td>
                    <td>13.44</td>
                </tr>
                <tr>
                    <td>65</td>
                    <td>2.5</td>
                    <td>73</td>
                    <td>2.11</td>
                    <td>3.69</td>
                    <td>3.05</td>
                    <td>5.26</td>
                    <td>5.16</td>
                    <td>8.63</td>
                    <td>7.01</td>
                    <td>11.4</td>
                    <td>9.53</td>
                    <td>14.9</td>
                    <td>14.2</td>
                    <td>20.39</td>
                </tr>
                <tr>
                    <td>80</td>
                    <td>3</td>
                    <td>88.9</td>
                    <td>2.11</td>
                    <td>4.51</td>
                    <td>3.05</td>
                    <td>6.45</td>
                    <td>5.49</td>
                    <td>11.3</td>
                    <td>7.62</td>
                    <td>15.2</td>
                    <td>11.1</td>
                    <td>21.3</td>
                    <td>15.24</td>
                    <td>27.65</td>
                </tr>
                <tr>
                    <td>100</td>
                    <td>4</td>
                    <td>114.3</td>
                    <td>2.11</td>
                    <td>5.84</td>
                    <td>3.05</td>
                    <td>8.36</td>
                    <td>6.02</td>
                    <td>16.07</td>
                    <td>8.56</td>
                    <td>22.3</td>
                    <td>13.49</td>
                    <td>33.54</td>
                    <td>17.12</td>
                    <td>41.03</td>
                </tr>
                <tr>
                    <td>125</td>
                    <td>5</td>
                    <td>141.3</td>
                    <td>2.77</td>
                    <td>9.47</td>
                    <td>3.4</td>
                    <td>11.57</td>
                    <td>6.55</td>
                    <td>21.8</td>
                    <td>9.53</td>
                    <td>31.97</td>
                    <td>15.88</td>
                    <td>49.11</td>
                    <td>19.05</td>
                    <td>57.43</td>
                </tr>
                <tr>
                    <td>150</td>
                    <td>6</td>
                    <td>168.3</td>
                    <td>2.77</td>
                    <td>11.32</td>
                    <td>3.4</td>
                    <td>13.84</td>
                    <td>7.11</td>
                    <td>28.3</td>
                    <td>10.97</td>
                    <td>42.7</td>
                    <td>18.2</td>
                    <td>67.56</td>
                    <td>21.95</td>
                    <td>79.22</td>
                </tr>
                <tr>
                    <td>200</td>
                    <td>8</td>
                    <td>219.1</td>
                    <td>2.77</td>
                    <td>14.79</td>
                    <td>3.76</td>
                    <td>19.96</td>
                    <td>8.18</td>
                    <td>42.6</td>
                    <td>12.7</td>
                    <td>64.6</td>
                    <td>23</td>
                    <td>111.2</td>
                    <td>22.23</td>
                    <td>107.8</td>
                </tr>
                <tr>
                    <td>250</td>
                    <td>10</td>
                    <td>273.1</td>
                    <td>3.4</td>
                    <td>22.63</td>
                    <td>4.19</td>
                    <td>27.78</td>
                    <td>9.27</td>
                    <td>60.5</td>
                    <td>12.7</td>
                    <td>96</td>
                    <td>28.6</td>
                    <td>172.4</td>
                    <td>25.4</td>
                    <td>155.15</td>
                </tr>
                <tr>
                    <td>300</td>
                    <td>12</td>
                    <td>323.9</td>
                    <td>3.96</td>
                    <td>31.25</td>
                    <td>4.57</td>
                    <td>36</td>
                    <td>9.52</td>
                    <td>73.88</td>
                    <td>12.7</td>
                    <td>132</td>
                    <td>33.32</td>
                    <td>238.76</td>
                    <td>25.4</td>
                    <td>186.97</td>
                </tr>
                <tr>
                    <td>350</td>
                    <td>14</td>
                    <td>355.6</td>
                    <td>3.96</td>
                    <td>34.36</td>
                    <td>4.78</td>
                    <td>41.3</td>
                    <td>11.13</td>
                    <td>94.59</td>
                    <td>19.05</td>
                    <td>158.08</td>
                    <td>35.71</td>
                    <td>281.7</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>400</td>
                    <td>16</td>
                    <td>406.4</td>
                    <td>4.19</td>
                    <td>41.56</td>
                    <td>4.78</td>
                    <td>47.29</td>
                    <td>12.7</td>
                    <td>123.3</td>
                    <td>21.41</td>
                    <td>203.33</td>
                    <td>40.46</td>
                    <td>365.11</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>450</td>
                    <td>18</td>
                    <td>457.2</td>
                    <td>4.19</td>
                    <td>46.8</td>
                    <td>4.78</td>
                    <td>53.42</td>
                    <td>14.27</td>
                    <td>155.8</td>
                    <td>23.8</td>
                    <td>254.36</td>
                    <td>45.71</td>
                    <td>466.4</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>500</td>
                    <td>20</td>
                    <td>508</td>
                    <td>4.78</td>
                    <td>59.25</td>
                    <td>5.54</td>
                    <td>68.71</td>
                    <td>15.09</td>
                    <td>183.42</td>
                    <td>26.19</td>
                    <td>311.2</td>
                    <td>49.99</td>
                    <td>564.68</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>600</td>
                    <td>24</td>
                    <td>609.6</td>
                    <td>5.54</td>
                    <td>82.47</td>
                    <td>6.35</td>
                    <td>94.45</td>
                    <td>17.48</td>
                    <td>255.41</td>
                    <td>30.96</td>
                    <td>442.08</td>
                    <td>59.54</td>
                    <td>808.22</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
            </tbody>
        </table>
        </div>
        </div>
        </div>
    </div>)
}
export default TechnicalInformation;