import { Row, Col } from 'antd';
import { WhatsAppOutlined, MailOutlined, MobileOutlined } from '@ant-design/icons';
import './Products.css';

const SocialMedia = () => {
    return (<div>
        <Row gutter={[32, 0]} justify='center'>
            <Col xs={24} sm={12} lg={6} xl={6} xxl={5}>
                <button className='social-media-btn-on-product-info'> <a href="https://wa.me/+919099883489?text=RFQ%20Enquiry%20-Flo Metal & Tubes%20/%20please%20send%20me%20quick%20Quotes" target='_blank'><WhatsAppOutlined style={{ marginRight: '10px' }} />WhatsApp Us</a></button>
            </Col>
            <Col xs={24} sm={12} lg={6} xl={6} xxl={4}>
                <button className='social-media-btn-on-product-info'> <a href="mailto:flometaltubes@gmail.com?subject=RFQenquiry%20Flo Metal & Tubes"><MailOutlined style={{ marginRight: '10px' }} />Mail Us</a></button>
            </Col>
            <Col xs={24} sm={12} lg={6} xl={6} xxl={4}>
                <button className='social-media-btn-on-product-info'> <a href="tel:+919619023475"><MobileOutlined style={{ marginRight: '10px' }} />Call Us</a></button>
            </Col>
            <Col sm={12}>
            </Col>
        </Row>
    </div>)
}
export default SocialMedia;