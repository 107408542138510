import { Carousel, Col, Row } from 'antd';
import { useRef } from 'react';
import "./Home.css";
import ProductCard from './ProductCard';
import firstProduct from '../../img/AllProducts/1.jpg';
import secondProduct from '../../img/AllProducts/2.jpg';
import thirdProduct from '../../img/AllProducts/3.jpg';
import fourthProduct from '../../img/AllProducts/4.jpg';
import fifthProduct from '../../img/AllProducts/5.jpg';
import sixthProduct from '../../img/AllProducts/6.jpg';
import seventhProduct from '../../img/AllProducts/7.jpg';
import eighthProduct from '../../img/AllProducts/8.jpg';
import ninthProduct from '../../img/AllProducts/9.jpg';
import tenthProduct from '../../img/AllProducts/10.jpg';
import eleventhProduct from '../../img/AllProducts/11.jpg';
import twelvethProduct from '../../img/Product/fastenersmain.jpg';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const ProductList_LG = [
    [
        {
            productImg: firstProduct,
            title: 'Carbon Steel Pipes & Tubes',
            subTitle: 'Carbon Steel Pipes & Tubes are made with precision and high quality materials.',
            redirect: '/products/carbon-steel-pipes-and-tubes-manufacturer'
        },
        {
            productImg: secondProduct,
            title: 'Stainless Steel Pipes & Tubes',
            subTitle: 'Stainless Steel Pipes & Tubes are made with precision and high quality materials.',
            redirect: '/products/stainless-steel-pipes-and-tubes-manufacturer-india'
        },
        {
            productImg: thirdProduct,
            title: 'Buttweld Fittings',
            subTitle: 'Flo Metal & Tubes offers a wide range of Buttweld Fittings in Stainless Steel, and other grades and standards',
            redirect: '/products/buttweld-fittings-manufacturer-india'
        }],
    [
        {
            productImg: fourthProduct,
            title: 'Socketweld Fittings',
            subTitle: 'Flo Metal & Tubes provides a wide range of Socketweld Fittings available in stainless steel and other grades.',
            redirect: '/products/socketweld-fittings-manufacturer-india'
        },
        {
            productImg: fifthProduct,
            title: 'Flanges',
            subTitle: 'Flanges are used to join pipes so that they may reach a greater distance and provide liquids or gases more easily.',
            redirect: '/products/flanges-manufacturer-india'
        },
        {
            productImg: sixthProduct,
            title: 'Sheet, Plate, Coils',
            subTitle: 'Sheet, Plate and Coils are manufactured by Flo Metal & Tubes with high quality materials as per ASTM Standard.',
            redirect: '/products/sheet-plate-and-coils-manufacturer-india'
        }],
    [{
        productImg: seventhProduct,
        title: 'Flats, Angle, Channel & Beam',
        subTitle: 'Flats, Angle, Channel & Beam are manufactured by Flo Metal & Tubes with high quality materials.',
        redirect: '/products/flats-angle-channel-beam-manufacturer'
    },
    {
        productImg: eighthProduct,
        title: 'Round Bar',
        subTitle: 'Round Bar are manufactured & supplier by FLo Metal & Tubes with high quality materials as per standard.',
        redirect: '/products/round-bar-manufacturer-india'
    },
    {
        productImg: ninthProduct,
        title: 'Olets',
        subTitle: 'Olets are manufactured by FLo Metal & Tubes with high quality materials.',
        redirect: '/products/olets-manufacturer-india'
    }],
    [
        {
            productImg: tenthProduct,
            title: 'Brass Rods',
            subTitle: 'Brass Rods are manufactured by Flo Metal & Tubes with high quality materials.',
            redirect: '/products/brass-rods-manufacturer-india'
        },
        {
            productImg: eleventhProduct,
            title: 'Copper Tubes',
            subTitle: 'Copper Tubes are manufactured by Flo Metal & Tubes with high quality materials.',
            redirect: '/products/copper-tubes-manufacturer-india'
        },
        {
            productImg: twelvethProduct,
            title: 'Fastener',
            subTitle: 'Bhavesh Steel is a leading Fasteners Manufacturer, Supplier & Stockist in India. we supplies bolt, nuts, and washer etc.',
            redirect: '/products/fastner'
        }]
]
const ProductList_MD = [
    [
        {
            productImg: firstProduct,
            title: 'Carbon Steel Pipes & Tubes',
            subTitle: 'Carbon Steel Pipes & Tubes are made with precision and high quality materials.',
            redirect: '/products/carbon-steel-pipes-and-tubes-manufacturer'
        },
        {
            productImg: secondProduct,
            title: 'Stainless Steel Pipes & Tubes',
            subTitle: 'Stainless Steel Pipes & Tubes are made with precision and high quality materials.',
            redirect: '/products/stainless-steel-pipes-and-tubes-manufacturer-india'
        }
    ],
    [
        {
            productImg: thirdProduct,
            title: 'Buttweld Fittings',
            subTitle: 'Flo Metal & Tubes offers a wide range of Buttweld Fittings in Stainless Steel, and other grades and standards',
            redirect: '/products/buttweld-fittings-manufacturer-india'
        },
        {
            productImg: fourthProduct,
            title: 'Socketweld Fittings',
            subTitle: 'Flo Metal & Tubes provides a wide range of Socketweld Fittings available in stainless steel and other grades.',
            redirect: '/products/socketweld-fittings-manufacturer-india'
        }
    ],
    [
        {
            productImg: fifthProduct,
            title: 'Flanges',
            subTitle: 'Flanges are used to join pipes so that they may reach a greater distance and provide liquids or gases more easily.',
            redirect: '/products/flanges-manufacturer-india'
        },
        {
            productImg: sixthProduct,
            title: 'Sheet, Plate, Coils',
            subTitle: 'Sheet, Plate and Coils are manufactured by Flo Metal & Tubes with high quality materials as per ASTM Standard.',
            redirect: '/products/sheet-plate-and-coils-manufacturer-india'
        }
    ],
    [
        {
            productImg: seventhProduct,
            title: 'Flats, Angle, Channel & Beam',
            subTitle: 'Flats, Angle, Channel & Beam are manufactured by Flo Metal & Tubes with high quality materials.',
            redirect: '/products/flats-angle-channel-beam-manufacturer'
        },
        {
            productImg: eighthProduct,
            title: 'Round Bar',
            subTitle: 'Round Bar are manufactured & supplier by FLo Metal & Tubes with high quality materials as per standard.',
            redirect: '/products/round-bar-manufacturer-india'
        }
    ],
    [
        {
            productImg: ninthProduct,
            title: 'Olets',
            subTitle: 'Olets are manufactured by FLo Metal & Tubes with high quality materials.',
            redirect: '/products/olets-manufacturer-india'
        },
        {
            productImg: tenthProduct,
            title: 'Brass Rods',
            subTitle: 'Brass Rods are manufactured by Flo Metal & Tubes with high quality materials.',
            redirect: '/products/brass-rods-manufacturer-india'
        }
    ],
    [
        {
            productImg: eleventhProduct,
            title: 'Copper Tubes',
            subTitle: 'Copper Tubes are manufactured by Flo Metal & Tubes with high quality materials.',
            redirect: '/products/copper-tubes-manufacturer-india'
        },
        {
            productImg: twelvethProduct,
            title: 'Fastener',
            subTitle: 'Bhavesh Steel is a leading Fasteners Manufacturer, Supplier & Stockist in India. we supplies bolt, nuts, and washer etc.',
            redirect: '/products/fastner'
        }
    ]
]

const ProductList_XS = [
    {
        productImg: firstProduct,
        title: 'Carbon Steel Pipes & Tubes',
        subTitle: 'Carbon Steel Pipes & Tubes are made with precision and high quality materials.',
        redirect: '/products/carbon-steel-pipes-and-tubes-manufacturer'
    },
    {
        productImg: secondProduct,
        title: 'Stainless Steel Pipes & Tubes',
        subTitle: 'Stainless Steel Pipes & Tubes are made with precision and high quality materials.',
        redirect: '/products/stainless-steel-pipes-and-tubes-manufacturer-india'
    },
    {
        productImg: thirdProduct,
        title: 'Buttweld Fittings',
        subTitle: 'Flo Metal & Tubes offers a wide range of Buttweld Fittings in Stainless Steel, and other grades and standards',
        redirect: '/products/buttweld-fittings-manufacturer-india'
    },
    {
        productImg: fourthProduct,
        title: 'Socketweld Fittings',
        subTitle: 'Flo Metal & Tubes provides a wide range of Socketweld Fittings available in stainless steel and other grades.',
        redirect: '/products/socketweld-fittings-manufacturer-india'
    },
    {
        productImg: fifthProduct,
        title: 'Flanges',
        subTitle: 'Flanges are used to join pipes so that they may reach a greater distance and provide liquids or gases more easily.',
        redirect: '/products/flanges-manufacturer-india'
    },
    {
        productImg: sixthProduct,
        title: 'Sheet, Plate, Coils',
        subTitle: 'Sheet, Plate and Coils are manufactured by Flo Metal & Tubes with high quality materials as per ASTM Standard.',
        redirect: '/products/sheet-plate-and-coils-manufacturer-india'
    },
    {
        productImg: seventhProduct,
        title: 'Flats, Angle, Channel & Beam',
        subTitle: 'Flats, Angle, Channel & Beam are manufactured by Flo Metal & Tubes with high quality materials.',
        redirect: '/products/flats-angle-channel-beam-manufacturer'
    },
    {
        productImg: eighthProduct,
        title: 'Round Bar',
        subTitle: 'Round Bar are manufactured & supplier by FLo Metal & Tubes with high quality materials as per standard.',
        redirect: '/products/round-bar-manufacturer-india'
    },
    {
        productImg: ninthProduct,
        title: 'Olets',
        subTitle: 'Olets are manufactured by FLo Metal & Tubes with high quality materials.',
        redirect: '/products/olets-manufacturer-india'
    },
    {
        productImg: tenthProduct,
        title: 'Brass Rods',
        subTitle: 'Brass Rods are manufactured by Flo Metal & Tubes with high quality materials.',
        redirect: '/products/brass-rods-manufacturer-india'
    },
    {
        productImg: eleventhProduct,
        title: 'Copper Tubes',
        subTitle: 'Copper Tubes are manufactured by Flo Metal & Tubes with high quality materials.',
        redirect: '/products/copper-tubes-manufacturer-india'
    },
    {
        productImg: twelvethProduct,
        title: 'Fastener',
        subTitle: 'Bhavesh Steel is a leading Fasteners Manufacturer, Supplier & Stockist in India. we supplies bolt, nuts, and washer etc.',
        redirect: '/products/fastner'
    }
]

const ProductSlider = () => {
    const sliderLG = useRef(null);
    const sliderMD = useRef(null);
    const sliderXS = useRef(null);
    return (<>
        <Row style={{ backgroundColor: '#fbf2f1' }}>
            <Col xs={0} md={0} lg={24}>
                <Carousel autoplay ref={sliderLG} dots={false}>{ProductList_LG.map(item => <div>
                    <Row gutter={[16, 0]} className='col3' justify='center' align='middle'>
                        <Col span={1}><LeftOutlined className='left-right-icon' onClick={() => sliderLG.current.prev()} /></Col>
                        {item.map(entity => <Col span={7}>
                            <ProductCard productImg={entity.productImg} title={entity.title} subTitle={entity.subTitle} redirect={entity.redirect} />
                        </Col>)}
                        <Col span={1}><RightOutlined className='left-right-icon' onClick={() => sliderLG.current.next()} /></Col>
                    </Row>
                </div>)}
                </Carousel>
            </Col>
            <Col xs={0} md={24} lg={0}>
                <Carousel autoplay ref={sliderMD} dots={false}>{ProductList_MD.map(item => <div>
                    <Row gutter={[16, 0]} className='col3' justify='center' align='middle'>
                        <Col span={2}><LeftOutlined className='left-right-icon' onClick={() => sliderMD.current.prev()} /></Col>
                        {item.map(entity => <Col span={10}>
                            <ProductCard productImg={entity.productImg} title={entity.title} subTitle={entity.subTitle} redirect={entity.redirect} />
                        </Col>)}
                        <Col span={2}><RightOutlined className='left-right-icon' onClick={() => sliderMD.current.next()} /></Col>
                    </Row>
                </div>)}
                </Carousel>
            </Col>
            <Col xs={24} md={0}>
                <Carousel autoplay ref={sliderXS} dots={false}>{ProductList_XS.map(item => <div>
                    <Row className='col1' justify='space-between' align='middle'>
                        <Col span={1}><LeftOutlined className='left-right-icon' onClick={() => sliderXS.current.prev()} /></Col>
                        <Col span={18}>
                            <ProductCard productImg={item.productImg} title={item.title} subTitle={item.subTitle} redirect={item.redirect} />
                        </Col>
                        <Col span={1}><RightOutlined className='left-right-icon' onClick={() => sliderXS.current.next()} /></Col>
                    </Row>
                </div>)}
                </Carousel>
            </Col>
        </Row>
    </>)
}
export default ProductSlider;