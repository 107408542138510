import './App.css';
import { Route, Routes } from 'react-router-dom'
import About from './pages/About';
import Home from './pages/Home';
import Footer from './Footer';
import Header from './Header';
import Products from './pages/Products';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsConditions';
import CarbonSteelPipesAndTubes from './pages/Products/CarbonSteelPipesAndTubes';
import StainlessSteelPipesAndTubes from './pages/Products/StainlessSteelPipesAndTubes';
import ButtweldFittings from './pages/Products/ButtweldFittings';
import SocketweldFittings from './pages/Products/SocketweldFittings';
import FlangesManfacturer from './pages/Products/FlangesManfacturer';
import SheetPlateAndCoilsManfacturer from './pages/Products/SheetPlateAndCoilsManfacturer';
import FlatsAngleChannelAndBeamManfacturer from './pages/Products/FlatsAngleChannelAndBeamManfacturer';
import RoundBarManfacturer from './pages/Products/RoundBarManfacturer';
import OletsManfacturer from './pages/Products/OletsManfacturer';
import BrassRodsManfacturer from './pages/Products/BrassRodsManfacturer';
import CopperTubesManufacturer from './pages/Products/CopperTubesManufacturer';
import StainlessSteel from './pages/Products/StainlessSteel';
import Fastner from './pages/Products/Fastner,';
import TechnicalInformation from './pages/TechnicalInformation';
import QualityPolicy from './pages/QualityPolicy';
import Enquiry from './pages/Enquiry';
import Contact from './pages/Contact';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-K82GXH1XPF"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


function App() {
  return (
    <div className='app'>
      <Header />
      <Routes>
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-condition' element={<TermsConditions />} />
        <Route path='/' element={<Home />} />
        <Route path='/aboutus' element={<About />} />
        <Route path='/about-us/factory-tour' element={<Home />} />
        <Route path='/products/all-products' element={<Products />} />
        <Route path='/products/carbon-steel-pipes-and-tubes' element={<CarbonSteelPipesAndTubes />} />
        <Route path='/products/stainless-steel' element={<StainlessSteel />} />
        <Route path='/products/stainless-steel-pipes-and-tubes' element={<StainlessSteelPipesAndTubes />} />
        <Route path='/products/buttweld-fittings' element={<ButtweldFittings />} />
        <Route path='/products/socketweld-fittings' element={<SocketweldFittings />} />
        <Route path='/products/flanges' element={<FlangesManfacturer />} />
        <Route path='/products/sheet-plate-and-coils' element={<SheetPlateAndCoilsManfacturer />} />
        <Route path='/products/flats-angle-channel-beam' element={<FlatsAngleChannelAndBeamManfacturer />} />
        <Route path='/products/round-bar' element={<RoundBarManfacturer />} />
        <Route path='/products/olets' element={<OletsManfacturer />} />
        <Route path='/products/brass-rods' element={<BrassRodsManfacturer />} />
        <Route path='/products/copper-tubes' element={<CopperTubesManufacturer />} />
        <Route path='/products/fastner' element={<Fastner />} />
        <Route path='/technical-info' element={<TechnicalInformation />} />
        <Route path='/quality-policy' element={<QualityPolicy />} />
        <Route path='/enquiry' element={<Enquiry />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
