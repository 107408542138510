import { Carousel, Col, Row } from 'antd';
import "./Home.css";
import amnsindia from '../../img/brand/amnsindia.jpg';
import arcelormittal from '../../img/brand/arcelormittal.jpg';
import autocumpu from '../../img/brand/autocumpu.jpg';
import ernefittings from '../../img/brand/ernefittings.jpg';
import inoxtech from '../../img/brand/inoxtech.jpg';
import jfe from '../../img/brand/jfe.jpg';
import jindalsawltd from '../../img/brand/jindalsawltd.jpg';
import jsl from '../../img/brand/jsl.jpg';
import kobelco from '../../img/brand/kobelco.jpg';
import lalit from '../../img/brand/lalit.jpg';
import melesi from '../../img/brand/melesi.jpg';
import metalfar from '../../img/brand/metalfar.jpg';
import nipponmetals from '../../img/brand/nipponmetals.jpg';
import ratnamani from '../../img/brand/ratnamani.jpg';
import rivit from '../../img/brand/rivit.jpg';
import sail from '../../img/brand/sail.jpg';
import samyoung from '../../img/brand/samyoung.jpg';
import sandviklogo from '../../img/brand/sandviklogo.jpg';
import surya from '../../img/brand/surya.jpg';
import tenris from '../../img/brand/tenris.jpg';
import tkcorporation from '../../img/brand/tkcorporation.jpg';
import tsstubaces from '../../img/brand/tsstubaces.jpg';
import ulma from '../../img/brand/ulma.jpg';
import vm from '../../img/brand/vm.jpg';
import welspun from '../../img/brand/welspun.jpg';

const ListOfBrand_MD = [
    [amnsindia, arcelormittal, autocumpu, ernefittings, inoxtech, jfe],
    [jindalsawltd, jsl, kobelco, lalit, melesi, metalfar],
    [nipponmetals, ratnamani, sandviklogo, rivit, sail, samyoung],
    [surya, tenris, tkcorporation, tsstubaces, ulma, vm],
    [tenris, tkcorporation, tsstubaces, ulma, vm, welspun]
]
const ListOfBrand_XS = [
    [amnsindia, arcelormittal, autocumpu],
    [ernefittings, inoxtech, jfe],
    [jindalsawltd, jsl, kobelco],
    [lalit, melesi, metalfar],
    [nipponmetals, ratnamani, sandviklogo],
    [rivit, sail, samyoung],
    [surya, tenris, tkcorporation],
    [tsstubaces, ulma, vm],
    [ulma, vm, welspun]
]

const Brand = () => {
    return (<div className='brand-details'>
        <div className='brand_slider_title'>Brands We Deal</div>
        <br />
        <br />
        <br />
        <div>
            <Row>
                <Col xs={0} md={24}>
                    <Carousel autoplay>{ListOfBrand_MD.map(item => <div>
                        <Row gutter={[32, 0]} className='m3'>
                            {item.map((img, key) => <Col span={4}>
                                <div key={`${img}-${key}`}>
                                    <img src={img} width="100%" />
                                </div>
                            </Col>)}
                        </Row>
                    </div>)}
                    </Carousel></Col>
                <Col xs={24} md={0}> <Carousel autoplay>{ListOfBrand_XS.map((item, key) => <div key={`${item}-${key}`}>
                    <Row gutter={[32, 0]} className='m3'>
                        {item.map(img => <Col span={8}>
                            <div>
                                <img src={img} width="100%" />
                            </div>
                        </Col>)}
                    </Row>
                </div>)}
                </Carousel></Col>
            </Row>
        </div>
    </div>)
}
export default Brand;