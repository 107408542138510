import './Products.css'
import PageHeader from '../../PageHeader';
import { Row, Col } from 'antd';
import SocialMedia from './SocialMedia';
import stainlesssteelpipetubemanufacturer from '../../img/Product/stainlesssteelpipetubemanufacturer.jpg';
import sspipemanufacturer from '../../img/Product/sspipemanufacturer.jpg';
import pipetubemanufacturer from '../../img/Product/pipetubemanufacturer.jpg';
import seamlesspipestockist from '../../img/Product/seamlesspipestockist.jpg';
import '../Pages.css';

const StainlessSteelPipesAndTubes = () => {
    return (<div style={{ width: '100vw' }}>
        <PageHeader title='Stainless Steel Pipes & Tubes Manfacturer in India' />
        <div className='product-info'>
            <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                    <img src={stainlesssteelpipetubemanufacturer} width="100%" height="100%" className='product-img' />
                </Col>
                <Col xs={24} md={12}>
                    <div className='product-info-title'>Stainless Steel Pipes and Tubes Manufacturer, Supplier, and Stockists in India</div>
                    <div className='product-info-description'>Flo Metal & Tubes is a well-known <b>Stainless Steel Pipes & Tubes Manufacturer, Supplier, and Exporter in India</b>. We have a significant manufacturer and supplier of Stainless Steel <u>Seamless Pipes and Tubes</u>, as well as Welded Pipes and Tubes. Stainless Steel Pipes and Tubes are utilised in many sectors, including oil and gas, petroleum, petrochemical, chemical, machine construction, cars, and so on. To suit the demands of our clients, these Stainless Steel Pipes & Tubes are available in a range of sizes, specifications, grade materials, and forms.</div>
                </Col>
                <Col xs={0} md={24}><br /></Col>
            </Row>
            <div className='product-info-title'>Stainless Steel Pipes and Tubes Manufacturer, Carbon Steel Pipes and Tubes Supplier, Duplex Steel Pipes and Tubes Stockists, Nickel Alloy Pipes and Tubes Manufacturer in India.</div>
            <div className='product-info-description'>Flo Metal & Tubes is one of India's leading manufacturer of high-quality <u>Pipes & Tubes</u> that are used in a variety of industries. <b>Stainless Steel Pipes</b> is created by pressing a hard circular steel material over a form and heating it until the steel is wrought into a hollow Tubes. Our <em>Carbon Steel Pipes</em> provide consistent strength and structure throughout the Pipes body. As a result, these Pipes can withstand greater temperatures, higher pressures, corrosive environments, and increased mechanical stress.</div>
            <div className='product-info-description'>We have a competent team to ensure the quality of <b>Pipes & Tubes</b> and other goods throughout the manufacturing and supplying process, which includes selecting high-quality raw materials, turning them into completed products, identifying, packing, storage, and transportation. We are also the largest Supplier of <a href="/products/buttweld-fittings">Buttweld Fittings</a>.</div>
            <div className='product-info-title'>Pipes & Tubes Specifications</div>
            <div className='product-info-description'>
                <div className="table-responsive">
                    <table border="1" className='product-info-description'>
                        <tbody>
                            <tr>
                                <th colspan="2"><strong>Stainless Steel Pipes & Tubes</strong></th>
                            </tr>
                            <tr>
                                <td><strong>Materials</strong></td>
                                <td>Stainless Steel Seamless Pipes, Stainless Steel Welded Pipes, Stainless Steel Box Pipes, Stainless Steel High Precision and Heat Exchanger Tubes, Stainless Steel Hydraulic and Instrumentation Tubes</td>
                            </tr>
                            <tr>
                                <td><strong>Schedule</strong></td>
                                <td>SCH20, SCH30, SCH40, STD, SCH80, XS, SCH60, SCH80, SCH120, SCH140, SCH160, XXS</td>
                            </tr>
                            <tr>
                                <td><strong>Size</strong></td>
                                <td>½ NB to 48NB</td>
                            </tr>
                            <tr>
                                <td><strong>Type</strong></td>
                                <td>Seamless / Erw / Welded in Round & Square .</td>
                            </tr>
                            <tr>
                                <td colspan="2"><strong>Grade</strong></td>
                            </tr>
                            <tr>
                                <td><strong>Stainless steel</strong></td>
                                <td>ASTM / ASME SA 182 F 304 , 304L , 304H, 309H, 310H , 316 , 316H , 316L , 316 LN , 317 , 317L , 321 , 321H , 347 , 347 H<br />
                                    ASTM / ASME A105 / ASTM / ASME A 350 LF 2 .</td>
                            </tr>
                            <tr>
                                <td><strong>Duplex steel</strong></td>
                                <td>ASTM / ASME SA 182 F 44 , F 45 , F51 , F 53 , F 55 , F 60 , F 61.</td>
                            </tr>
                            <tr>
                                <td><strong>Alloy steel</strong></td>
                                <td>ASTM / ASME A 182 GR F 5, F 9 , F 11 , F 12 , F 22 , F 91.</td>
                            </tr>
                            <tr>
                                <td><strong>Nickel & Alloy Steel</strong></td>
                                <td>ASTM / ASME SB 564 UNS 2200 ( NICKEL 200 ) ,UNS 4400 (MONEL 400 ) , , UNS 8825 INCONEL (825) , UNS 6600 (INCONEL 600 ) , UNS 6601 ( INCONEL 601 ) , UNS 6625 (INCONEL 625) , UNS 10276 ( HASTELLOY C 276 )<br />ASTM / ASME SB 160 UNS 2201 (NICKEL 201 ),<br />ASTM / ASME SB 472 UNS 8020 ( ALLOY 20 / 20 CB 3 ).</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='product-info-title'>Types of Pipes and Tubes</div>
            <Row align='middle' gutter={[32, 16]}>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={sspipemanufacturer} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>SS Pipes</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={pipetubemanufacturer} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>SS Pipes</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={seamlesspipestockist} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>SS Pipes</div>
                </Col>
                <Col xs={3} sm={0}></Col>
            </Row>
            <br />
            <SocialMedia />
            <br />
            <div className='product-info-title'>Stainless Steel Pipes & Tubes Supplier - Additional Information</div>
            <div className='product-info-description'>
                <ol >
                    <li>Stainless Steel Pipes & Tubes Payment Modes: LC (Letter of Credit), TT (Telegraphic Transfer or Wire Transfer), Cheque, and others.</li>
                    <li>Stainless Steel Pipes & Tubes Packaging: Stainless Steel Pipes & Tubes are packaged with caution and safety so they reach our client’s as good as he would expect. Packaging Charges Extra.</li>
                    <li>Stainless Steel Pipes & Tubes Port of Dispatch: Mumbai ports in the Maharashtra state of India.</li>
                    <li>Stainless Steel Pipes & Tubes Tax: 18% GST.</li>
                </ol>
            </div>
            <div style={{ padding: '2vw 0', textAlign: 'center' }}><span className='markup-text'>Flo Metal & Tubes is a leading Carbon Steel Pipes and Tubes Manufacturer in India.</span></div>
            <br />
            <div className='product-info-title'>Applications & Uses of Stainless Steel Pipes & Tubes</div>
            <div className='product-info-description'>
                <ul>
                    <li>Stainless Steel Pipes & Tubes used in Sugar Industry.</li>
                    <li>Stainless Steel Pipes & Tubes used in Power Industry.</li>
                    <li>Stainless Steel Pipes & Tubes used in ShipBuilding Industry.</li>
                    <li>Stainless Steel Pipes & Tubes used in Mechanical and Plant engineering.</li>
                    <li>Stainless Steel Pipes & Tubes used in Food, Beverage, and Dairy.</li>
                    <li>Stainless Steel Pipes & Tubes used in Oil and Gas Plant.</li>
                    <li>Stainless Steel Pipes & Tubes used in Process Instrumentation.</li>
                </ul>
            </div>
            {/* <div className='product-info-title'>Cities we supply Pipes & Tubes in India :-</div>
            <div className='product-info-description'>Flo Metal & Tubes is one of India's leading suppliers, dealers, manufacturers, and stockists of <b>Pipes & Tubes</b> due to its dependability and performance. Every month, we supply/export containers of <u>Pipes & Tubes</u> to a range of clients from Indian ports. We supply our products in a number of cities around India. <br />
                <br />
                <div className="table-responsive">
                    <table border="1" className='product-info-description'>
                        <tbody>
                            <tr>
                                <th colspan="6">Stainless Steel Pipes & Tubes Supply:</th>
                            </tr>
                            <tr>
                                <td>Bengaluru</td>
                                <td>Chennai</td>
                                <td>Hyderabad</td>
                                <td>Kolkata</td>
                                <td>Mumbai</td>
                                <td>Pune</td>
                            </tr>
                            <tr>
                                <td>Bhopal</td>
                                <td>Sri City</td>
                                <td>Jamshedpur</td>
                                <td>Hosdurg</td>
                                <td>Lucknow</td>
                                <td>Noida</td>
                            </tr>
                            <tr>
                                <td>Ahmedabad</td>
                                <td>Delhi</td>
                                <td>Raipur</td>
                                <td>Jaipur</td>
                                <td>Surat</td>
                                <td>Salem</td>
                            </tr>
                            <tr>
                                <td>Jamnagar</td>
                                <td>Bhubaneswar</td>
                                <td>Sivakasi</td>
                                <td>Rajahmundry</td>
                                <td>Thiruvananthapuram</td>
                                <td>Tiruppur</td>
                            </tr>
                            <tr>
                                <td>Bhiwandi</td>
                                <td>Bokaro Steel City</td>
                                <td>Bharuch</td>
                                <td>Vijaywada</td>
                                <td>Firozabad</td>
                                <td>Rajkot</td>
                            </tr>
                            <tr>
                                <td>Cochin</td>
                                <td>Ludhiana</td>
                                <td>Durgapur</td>
                                <td>Panna</td>
                                <td>Panipat</td>
                                <td>Raipur</td>
                            </tr>
                            <tr>
                                <td>Bareilly</td>
                                <td>Channapatna</td>
                                <td>Kharagpur</td>
                                <td>Peenya</td>
                                <td>Pimpri-Chinchwad</td>
                                <td>Nashik</td>
                            </tr>
                            <tr>
                                <td>Bangalore</td>
                                <td>Indore</td>
                                <td>Haldia</td>
                                <td>Moradabad</td>
                                <td>Varanasi</td>
                                <td>Rourkela</td>
                            </tr>
                            <tr>
                                <td>Bhopal</td>
                                <td>Sri City</td>
                                <td>Jamshedpur</td>
                                <td>Hosdurg</td>
                                <td>Lucknow</td>
                                <td>Noida</td>
                            </tr>
                            <tr>
                                <td>Bareilly</td>
                                <td>Coimbatore</td>
                                <td>Kannur</td>
                                <td>Nagpur</td>
                                <td>Vadodara</td>
                                <td>Rudrapur</td>
                            </tr>
                            <tr>
                                <td>Agra</td>
                                <td>Bhagalpur</td>
                                <td>Bhilai</td>
                                <td>Thane</td>
                                <td>Kanpur</td>
                                <td>Haldia</td>
                            </tr>
                            <tr>
                                <td>Angul</td>
                                <td>Dibrugarh</td>
                                <td>Gwalior</td>
                                <td>Patna</td>
                                <td>Trivandrum</td>
                                <td>Pithampur</td>
                            </tr>                                        </tbody>
                    </table>
                </div>
            </div>
            <div className='product-info-title'>Countries we export Pipes & Tubes worldwide :-</div>
            <div className='product-info-description'>More than 80 tons of <b>Pipes & Tubes</b> are exported from India each year, with Flo Metal & Tubes accounting for 25% of the total. We export to a range of countries on a regular basis for our new clients. Because of our constant supply of <u>Pipes & Tubes</u>, we can export and satisfy our clients' demands and requests.
                <br />
                <br />
                <div className="table-responsive">
                    <table border="1" className='product-info-description'>
                        <tbody>
                            <tr>
                                <th colspan="6">Stainless Steel Pipes & Tubes Exporter:</th>
                            </tr>
                            <tr>
                                <td>United States</td>
                                <td>South Africa</td>
                                <td>Malaysia</td>
                                <td>Bangladesh</td>
                                <td>Turkey</td>
                            </tr>
                            <tr>
                                <td>Bahrain</td>
                                <td>Qatar</td>
                                <td>UAE</td>
                                <td>Africa</td>
                                <td>China</td>
                            </tr>
                            <tr>
                                <td>Nigeria</td>
                                <td>Mexico</td>
                                <td>Canada</td>
                                <td>Venezuela</td>
                                <td>United Kingdom</td>
                            </tr>
                            <tr>
                                <td>Egypt</td>
                                <td>Jordan</td>
                                <td>Portugal</td>
                                <td>Russia</td>
                                <td>Vietnam</td>
                            </tr>
                            <tr>
                                <td>Kazakhstan</td>
                                <td>Thailand</td>
                                <td>Spain</td>
                                <td>Romania</td>
                                <td>Philippines</td>
                            </tr>
                            <tr>
                                <td>Hongkong</td>
                                <td>Taiwan</td>
                                <td>Japan</td>
                                <td>Ukraine</td>
                                <td>Chile</td>
                            </tr>
                            <tr>
                                <td>United States</td>
                                <td>South Africa</td>
                                <td>Malaysia</td>
                                <td>Bangladesh</td>
                                <td>Turkey</td>
                            </tr>
                            <tr>
                                <td>Australia</td>
                                <td>Brazil</td>
                                <td>Germany</td>
                                <td>Singapore</td>
                                <td>Sri Lanka</td>
                            </tr>
                            <tr>
                                <td>Iran</td>
                                <td>Saudi Arabia</td>
                                <td>Oman</td>
                                <td>Kuwait</td>
                                <td>Netherlands</td>
                            </tr>
                            <tr>
                                <td>Belgium</td>
                                <td>France</td>
                                <td>Italy</td>
                                <td>South Korea</td>
                                <td>Poland</td>
                            </tr>

                            <tr>
                                <td>Costa Rica</td>
                                <td>Israel</td>
                                <td>Iraq</td>
                                <td>Madagascar</td>
                                <td>Morocco</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> */}
        </div>
    </div>)
}
export default StainlessSteelPipesAndTubes;