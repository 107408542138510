
import { Carousel, Row, Col } from 'antd';
import "./Home.css";
import { useRef } from 'react';
import first from '../../img/HomePageSlider/1st.jpg';
import second from '../../img/HomePageSlider/2nd.jpg';
import third from '../../img/HomePageSlider/3rd.jpg';
import fourth from '../../img/HomePageSlider/4th.jpg';
import fifth from '../../img/HomePageSlider/5th.jpg';
import sixth from '../../img/HomePageSlider/6th.jpg';
import seventh from '../../img/HomePageSlider/7th.jpg';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const sliderList = [{
    bg: first,
    title: 'Welcome to Flo Metal & Tubes',
    subtitle: 'We are a leading Manufacturer & Supplier of Carbon Steel Pipes & Tubes In India'
},
{
    bg: second,
    title: 'one of the best Supplier of Stainless Steel Pipes & Tubes.',
    subtitle: 'we are known for best quality Stainless Steel Pipes & Tubes Supplier in India.'
},
{
    bg: third,
    title: 'We are leading Manufacturer of Buttweld Fittings in India.',
    subtitle: 'Flo Metal & Tubes is a leading Manufacturer of Buttweld Fittings in India.'
},
{
    bg: fourth,
    title: 'We are leading Manufacturer of Round Bar in India.',
    subtitle: 'Flo Metal & Tubes is a leading Manufacturer of Round Bar in India.'
},
{
    bg: fifth,
    title: 'We are leading Manufacturer of Pipe & Tube in India.',
    subtitle: 'Flo Metal & Tubes is a leading Manufacturer of Pipe & Tube in India.'
},
{
    bg: sixth,
    title: 'Large Diameter Flanges Manufacturer in India',
    subtitle: 'Flo Metal & Tubes is a leading Flanges Manufacturer in india.'
},
{
    bg: seventh,
    title: 'Large Diameter Flanges Manufacturer in India',
    subtitle: 'Flo Metal & Tubes is a leading Flanges Manufacturer in india.'
},
]

const Slider = () => {
    const slider = useRef(null);
    return (
        <div style={{ backgroundColor: '#f9f9f9' }} >
            <Carousel autoplay ref={slider} dots={false}>
                {sliderList.map(item =>
                    <div>
                        <div style={{ backgroundImage: `url(${item.bg})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}>
                            <Row justify='center' align='middle'>
                                <Col span={1}>
                                    <LeftOutlined className='left-right-icon wcolor' onClick={() => slider.current.prev()} />
                                </Col>
                                <Col span={20}>
                                    <div className='home_slider-title'>{item.title}</div>
                                    <div className='home_slider-subtitle'>{item.subtitle}</div>
                                    <div className='home_action'>
                                        <button type="primary" size='large' className='home_but'> <a href='/products/all-products' style={{ color: 'white' }}>Our Product</a></button>
                                        <button type="primary" size='large' className='home_but'><a href='/contact' style={{ color: 'white' }}>Contact Us</a></button>
                                    </div>
                                </Col>
                                <Col span={1}>
                                    <RightOutlined className='left-right-icon wcolor' onClick={() => slider.current.next()} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                )}
            </Carousel>
        </div>)
}
export default Slider;