import './Products.css';
import PageHeader from '../../PageHeader';
import { Row, Col } from 'antd';
import SocialMedia from './SocialMedia';
import roundbarmanufacturer from '../../img/Product/roundbarmanufacturer.jpg';
import roundbar from '../../img/Product/roundbar.jpg';
import squarebar from '../../img/Product/squarebar.jpg';
import hexbar from '../../img/Product/hexbar.jpg';
import '../Pages.css';

const RoundBarManfacturer = () => {
    return (<div style={{ width: '100vw' }}>
        <PageHeader title='Round Bar Manfacturer in India' />
        <div className='product-info'>
            <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                    <img src={roundbarmanufacturer} width="100%" height="100%" className='product-img' />
                </Col>
                <Col xs={24} md={12}>
                    <div className='product-info-title'>Round Bar Manufacturer, Supplier, and Stockists in India</div>
                    <div className='product-info-description'>Flo Metal & Tubes is a well-known <b>Round Bar Manufacturer, Supplier, and Exporter in India</b>. We have a significant manufacturer and supplier of Stainless Steel <u> round bar, hex bar, square bar, black bar, bright bar </u>etc. Round Bar are utilised in many sectors, including oil and gas, petroleum, petrochemical, chemical, machine construction, cars, and so on. To suit the demands of our clients, these Round Bar are available in a range of sizes, specifications, grade materials, and forms.</div>
                </Col>
                <Col xs={0} md={24}><br /></Col>
            </Row>
            <br />
            <div className='product-info-title'>Round Bar Manufacturer, Carbon Steel Round Bar Supplier, Duplex Steel Round Bar Stockists, Nickel Alloy Round Bar Manufacturer in India.</div>
            <div className='product-info-description'>Flo Metal & Tubes is one of India's leading manufacturer of high-quality <u>Round Bar</u> that are used in a variety of industries. <b>Bar</b> is created by pressing a hard circular steel 'material' over a form and heating it until the steel is wrought into a Round Bar. Our <em>Adaptors</em> provide consistent strength and structure throughout the Round Bar body. As a result, these Round Bar can withstand greater temperatures, higher pressures, corrosive environments, and increased mechanical stress.</div>
            <div className='product-info-description'> We have a competent team to ensure the quality of <b>Round Bar</b> and other goods throughout the manufacturing and supplying process, which includes selecting high-quality raw materials, turning them into completed products, identifying, packing, storage, and transportation. We are also the largest Supplier of <a href="/products/socketweld-fittings">Socketweld Fittings</a>.</div>
            <br />
            <div className='product-info-title'>Round Bar Specifications</div>
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th colspan="2"><strong>Round Bar</strong></th>
                        </tr>
                        <tr>
                            <td><strong>Stainless Stee</strong></td>
                            <td> As per ASTM A276 & A479 Grade 202 / 304 / 304L / 316 / 316L / 316Ti / 317 / 317L /
                                321 / 310 / 347 / 410 /416/ 420 /430/440C/ 904 / 303 / 17-4PH, 15-5 PH Etc. </td>
                        </tr>
                        <tr>
                            <td><strong>Nickel Alloys</strong></td>
                            <td> Monel, Inconel, Hastelloy, Nickel, Titanium, Alloy 20 </td>
                        </tr>

                        <tr>
                            <td><strong>Duplex & Super Duplex </strong></td>
                            <td> UNS 32205, 31803, 32750, 32760,
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Types </strong></td>
                            <td> Round, Square, Rectangular.</td>
                        </tr>
                        <tr>
                            <td><strong>Size</strong></td>
                            <td> 3mm - 75 mm dia ready stock with Mill Test Certificate
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Process </strong></td>
                            <td> Cold Drawn, Annealed, Export Bright & Black
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Tolerance </strong></td>
                            <td> H9, H11
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <br />
            <div className='product-info-title'>Types of Round Bar</div>
            <Row align='middle' gutter={[32, 16]}>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={roundbar} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Round Bar</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={squarebar} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Square Bar</div>
                </Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={3} sm={0}></Col>
                <Col xs={18} sm={12} md={8} xl={6}>
                    <img className='product-img' src={hexbar} width="100%" height="100%" />
                    <div className='pipes-type-name product-info-title'>Hex Bar</div>
                </Col>
                <Col xs={3} sm={0}></Col>
            </Row>
            <br />
            <SocialMedia />
            <br />
            <div className='product-info-title'>Round Bar Supplier - Additional Information</div>
            <div className='product-info-description'>
                <ol>
                    <li>Round Bar Payment Modes: LC (Letter of Credit), TT (Telegraphic Transfer or Wire Transfer), Cheque, and others.</li>
                    <li>Round Bar Packaging: Round Bar are packaged with caution and safety so they reach our client’s as good as he would expect. Packaging Charges Extra.</li>
                    <li>Round Bar Port of Dispatch: Mumbai ports in the Maharashtra state of India.</li>
                    <li>Round Bar Tax: 18% GST.</li>
                </ol>
            </div>
            <div style={{ padding: '2vw 0', textAlign: 'center' }}><span className='markup-text'>Flo Metal & Tubes is a leading Carbon Steel Pipes and Tubes Manufacturer in India.</span></div>
            <br />
            <div className='product-info-title'>Flats, Angle, Channel & Beam Supplier - Additional Information</div>
            <div className='product-info-description'>
                <ol>
                    <li>Flats, Angle, Channel & Beam Payment Modes: LC (Letter of Credit), TT (Telegraphic Transfer or Wire Transfer), Cheque, and others.</li>
                    <li>Flats, Angle, Channel & Beam Packaging: Flats, Angle, Channel & Beam are packaged with caution and safety so they reach our client’s as good as he would expect. Packaging Charges Extra.</li>
                    <li>Flats, Angle, Channel & Beam Port of Dispatch: Mumbai ports in the Maharashtra state of India.</li>
                    <li>Flats, Angle, Channel & Beam Tax: 18% GST.</li>
                </ol>
            </div>
            <div className='product-info-title'>Applications & Uses of Round Bar</div>
            <div className='product-info-description'>
                <ul>
                    <li>Round Bar used in Sugar Industry.</li>
                    <li>Square Bar used in Power Industry.</li>
                    <li>Round Bar used in ShipBuilding Industry.</li>
                    <li>Hex Bar used in Mechanical and Plant engineering.</li>
                    <li>Round Bar used in Food, Beverage, and Dairy.</li>
                    <li>Round Bar used in Oil and Gas Plant.</li>
                    <li>Round Bar used in Process Instrumentation.</li>
                </ul>
            </div>
            {/* <div className='product-info-title'>Cities we supply Round Bar in India :-</div>
            <div className='product-info-description'>Flo Metal & Tubes is one of India's leading suppliers, dealers, manufacturers, and stockists of <b>Round Bar</b> due to its dependability and performance. Every month, we supply/export containers of <u>Round Bar</u> to a range of clients from Indian ports. We supply our products in a number of cities around India.
                <br />
                <div className="table-responsive">
                    <table border="1" className='product-info-description'>
                        <tbody>
                            <tr>
                                <th colspan="6">Round Bar Supply:</th>
                            </tr>
                            <tr>
                                <td>Bengaluru</td>
                                <td>Chennai</td>
                                <td>Hyderabad</td>
                                <td>Kolkata</td>
                                <td>Mumbai</td>
                                <td>Pune</td>
                            </tr>
                            <tr>
                                <td>Bhopal</td>
                                <td>Sri City</td>
                                <td>Jamshedpur</td>
                                <td>Hosdurg</td>
                                <td>Lucknow</td>
                                <td>Noida</td>
                            </tr>
                            <tr>
                                <td>Ahmedabad</td>
                                <td>Delhi</td>
                                <td>Raipur</td>
                                <td>Jaipur</td>
                                <td>Surat</td>
                                <td>Salem</td>
                            </tr>
                            <tr>
                                <td>Jamnagar</td>
                                <td>Bhubaneswar</td>
                                <td>Sivakasi</td>
                                <td>Rajahmundry</td>
                                <td>Thiruvananthapuram</td>
                                <td>Tiruppur</td>
                            </tr>
                            <tr>
                                <td>Bhiwandi</td>
                                <td>Bokaro Steel City</td>
                                <td>Bharuch</td>
                                <td>Vijaywada</td>
                                <td>Firozabad</td>
                                <td>Rajkot</td>
                            </tr>
                            <tr>
                                <td>Cochin</td>
                                <td>Ludhiana</td>
                                <td>Durgapur</td>
                                <td>Panna</td>
                                <td>Panipat</td>
                                <td>Raipur</td>
                            </tr>
                            <tr>
                                <td>Bareilly</td>
                                <td>Channapatna</td>
                                <td>Kharagpur</td>
                                <td>Peenya</td>
                                <td>Pimpri-Chinchwad</td>
                                <td>Nashik</td>
                            </tr>
                            <tr>
                                <td>Bangalore</td>
                                <td>Indore</td>
                                <td>Haldia</td>
                                <td>Moradabad</td>
                                <td>Varanasi</td>
                                <td>Rourkela</td>
                            </tr>
                            <tr>
                                <td>Bhopal</td>
                                <td>Sri City</td>
                                <td>Jamshedpur</td>
                                <td>Hosdurg</td>
                                <td>Lucknow</td>
                                <td>Noida</td>
                            </tr>
                            <tr>
                                <td>Bareilly</td>
                                <td>Coimbatore</td>
                                <td>Kannur</td>
                                <td>Nagpur</td>
                                <td>Vadodara</td>
                                <td>Rudrapur</td>
                            </tr>
                            <tr>
                                <td>Agra</td>
                                <td>Bhagalpur</td>
                                <td>Bhilai</td>
                                <td>Thane</td>
                                <td>Kanpur</td>
                                <td>Haldia</td>
                            </tr>
                            <tr>
                                <td>Angul</td>
                                <td>Dibrugarh</td>
                                <td>Gwalior</td>
                                <td>Patna</td>
                                <td>Trivandrum</td>
                                <td>Pithampur</td>
                            </tr>   										</tbody>
                    </table>
                </div>
            </div>
            <div className='product-info-title'>Countries we export Round Bar worldwide :-</div>
            <div className='product-info-description'>More than 80 tons of <b>Round Bar</b> are exported from India each year, with Flo Metal & Tubes accounting for 25% of the total. We export to a range of countries on a regular basis for our new clients. Because of our constant supply of <u>Round Bar</u>, we can export and satisfy our clients' demands and requests.
                <br />
                <div className="table-responsive">
                    <table border="1" className='product-info-description'>
                        <tbody>
                            <tr>
                                <th colspan="6">Round Bar Exporter:</th>
                            </tr>
                            <tr>
                                <td>United States</td>
                                <td>South Africa</td>
                                <td>Malaysia</td>
                                <td>Bangladesh</td>
                                <td>Turkey</td>
                            </tr>
                            <tr>
                                <td>Bahrain</td>
                                <td>Qatar</td>
                                <td>UAE</td>
                                <td>Africa</td>
                                <td>China</td>
                            </tr>
                            <tr>
                                <td>Nigeria</td>
                                <td>Mexico</td>
                                <td>Canada</td>
                                <td>Venezuela</td>
                                <td>United Kingdom</td>
                            </tr>
                            <tr>
                                <td>Egypt</td>
                                <td>Jordan</td>
                                <td>Portugal</td>
                                <td>Russia</td>
                                <td>Vietnam</td>
                            </tr>
                            <tr>
                                <td>Kazakhstan</td>
                                <td>Thailand</td>
                                <td>Spain</td>
                                <td>Romania</td>
                                <td>Philippines</td>
                            </tr>
                            <tr>
                                <td>Hongkong</td>
                                <td>Taiwan</td>
                                <td>Japan</td>
                                <td>Ukraine</td>
                                <td>Chile</td>
                            </tr>
                            <tr>
                                <td>United States</td>
                                <td>South Africa</td>
                                <td>Malaysia</td>
                                <td>Bangladesh</td>
                                <td>Turkey</td>
                            </tr>
                            <tr>
                                <td>Australia</td>
                                <td>Brazil</td>
                                <td>Germany</td>
                                <td>Singapore</td>
                                <td>Sri Lanka</td>
                            </tr>
                            <tr>
                                <td>Iran</td>
                                <td>Saudi Arabia</td>
                                <td>Oman</td>
                                <td>Kuwait</td>
                                <td>Netherlands</td>
                            </tr>
                            <tr>
                                <td>Belgium</td>
                                <td>France</td>
                                <td>Italy</td>
                                <td>South Korea</td>
                                <td>Poland</td>
                            </tr>

                            <tr>
                                <td>Costa Rica</td>
                                <td>Israel</td>
                                <td>Iraq</td>
                                <td>Madagascar</td>
                                <td>Morocco</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> */}
        </div>
    </div>)
}
export default RoundBarManfacturer;