import './Products.css';
import PageHeader from '../../PageHeader';
import { Row, Col } from 'antd';
import stainlesssteel1 from '../../img/Product/stainlesssteel1.jpg';
import '../Pages.css';

const StainlessSteel = () => {
    return (<div style={{ width: '100vw' }}>
        <PageHeader title='Socketweld Fittings Manfacturer in India' />
        <div className='product-info'>
            <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                    <img src={stainlesssteel1} width="100%" className='product-img' />
                </Col>
                <Col xs={24} md={12}>
                    <div className='product-info-title'>Stainless Steel</div>
                    <div className='product-info-description'>We offer a qualitative range of stainless steel products at Megmite.</div>
                    <div className='product-info-description'>
                        <u>STAINLESS STEEL SERIES</u><br /><br />
                        <strong>Precipitation Hardening Series</strong>
                    </div>
                </Col>
                <Col xs={0} md={24}><br /></Col>
            </Row>
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th colspan="5">
                                Stainlesss Steel Grades
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <p>15-5PH</p>
                            </td>
                            <td>
                                <p>303</p>
                            </td>
                            <td>
                                <p>316</p>
                            </td>
                            <td>
                                <p>403</p>
                            </td>
                            <td>
                                <p>430</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>17-4PH</p>
                            </td>
                            <td>
                                <p>304 1/8 H</p>
                            </td>
                            <td>
                                <p>316L</p>
                            </td>
                            <td>
                                <p>405</p>
                            </td>
                            <td>
                                <p>431</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>17-7PH</p>
                            </td>
                            <td>
                                <p>304L</p>
                            </td>
                            <td>
                                <p>317</p>
                            </td>
                            <td>
                                <p>409</p>
                            </td>
                            <td>
                                <p>440A.C.</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>Carp 20</p>
                            </td>
                            <td>
                                <p>305</p>
                            </td>
                            <td>
                                <p>317L</p>
                            </td>
                            <td>
                                <p>410</p>
                            </td>
                            <td>
                                <p>446</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>21-6-9</p>
                            </td>
                            <td>
                                <p>309</p>
                            </td>
                            <td>
                                <p>321</p>
                            </td>
                            <td>
                                <p>416</p>
                            </td>
                            <td>
                                <p>A-286</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>301 ¼ H</p>
                            </td>
                            <td>
                                <p>309S</p>
                            </td>
                            <td>
                                <p>330</p>
                            </td>
                            <td>
                                <p>418</p>
                            </td>
                            <td>
                                <p>AM-355</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>301 ½ H</p>
                            </td>
                            <td>
                                <p>310</p>
                            </td>
                            <td>
                                <p>333</p>
                            </td>
                            <td>
                                <p>420</p>
                            </td>
                            <td>
                                <p>PH-13-8MO</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p>302</p>
                            </td>
                            <td>
                                <p>314</p>
                            </td>
                            <td>
                                <p>347</p>
                            </td>
                            <td>
                                <p>422</p>
                            </td>
                            <td>
                                <p>&nbsp;</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
        </div>
    </div>)
}
export default StainlessSteel;