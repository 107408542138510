import './Products.css';
import PageHeader from '../../PageHeader';
import { Row, Col } from 'antd';
import SocialMedia from './SocialMedia';
import carbonsteelpipetubesmanufacturer from '../../img/Product/carbonsteelpipetubesmanufacturer.jpg';
import '../Pages.css';

const CarbonSteelPipesAndTubes = () => {
    return (<div style={{ width: '100vw' }}>
        <PageHeader title='Carbon Steel Pipes and Tubes Manfacturer in India' />
        <div className='product-info'>
            <Row gutter={[32, 16]}>
                <Col xs={24} md={12}>
                    <img src={carbonsteelpipetubesmanufacturer} width="100%" height="100%" className='product-img' />
                </Col>
                <Col xs={24} md={12}>
                    <div className='product-info-title'>Carbon Steel Pipes and Tubes Manufacturer, Supplier, and Stockists in India</div>
                    <div className='product-info-description'>Flo Metal & Tubes is a well-known <b>Carbon Steel Pipes and Tubes Manufacturer, Supplier, and Exporter in India</b>. We have a significant manufacturer and supplier of Carbon Steel Pipes and Tubes. Carbon Steel Pipes and Tubes are utilised in many sectors, including oil and gas, petroleum, petrochemical, chemical, machine construction, cars, and so on. To suit the demands of our clients, these Carbon Steel Pipes and Tubes are available in a range of sizes, specifications, grade materials, and forms.</div>
                </Col>
                <Col xs={0} md={24}><br /></Col>
            </Row>
            <div className='product-info-title'>Carbon Steel Pipes and Tubes Supplier, Carbon Steel Pipes and Tubes Stockists, Carbon Steel Pipes and Tubes Dealer, Carbon Steel Pipes and Tubes Manufacturer in India.</div>
            <div className='product-info-description'>Flo Metal & Tubes is one of India's leading manufacturer of high-quality <u>Carbon Steel Pipes and Tubes</u> that are used in a variety of industries. <b>Carbon Steel Pipes and Tubes</b> is created by pressing a hard circular Carbon 'material' over a form and heating it until the Carbon is wrought into a Carbon Steel Pipes and Tubes. Our <em>Carbon</em> provide consistent strength and structure throughout the Carbon Steel Pipes and Tubes body. As a result, these Carbon Steel Pipes and Tubes can with stand greater temperatures, higher pressures, corrosive environments, and increased mechanical stress.</div>
            <div className='product-info-description'>
                We have a competent team to ensure the quality of <b>Carbon Steel Pipes and Tubes</b> and other goods throughout the manufacturing and supplying process, which includes selecting high-quality raw materials, turning them into completed products, identifying, packing, storage, and transportation. We are also the largest Supplier of <a href="/products/socketweld-fittings">Socketweld Fittings</a>.
            </div>
            <div className='product-info-title'>Carbon Steel Pipes and Tubes Specifications</div>
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <td><strong>Carbon Steel Seamless Pipe Dimensions</strong></td>
                            <td>ASTM, ASME and API</td>
                        </tr>
                        <tr>
                            <td><strong>Api 5l Carbon Steel Pipe Size</strong></td>
                            <td>1/2 NB to 36 NB</td>
                        </tr>
                        <tr>
                            <td><strong>Api 5l Carbon Steel Seamless Pipe Thickness</strong></td>
                            <td>3-12mm</td>
                        </tr>
                        <tr>
                            <td><strong>Schedules</strong></td>
                            <td>SCH 40, SCH 80, SCH 160, SCH XS, SCH XXS, All Schedules</td>
                        </tr>
                        <tr>
                            <td><strong>High Carbon Steel Tube Origin</strong></td>
                            <td>European, Japanese, Korean, US, Russian, Indian (Strictly No Chinese Material)</td>
                        </tr>
                        <tr>
                            <td><strong>Type</strong></td>
                            <td>Seamless / ERW / Welded / Fabricated</td>
                        </tr>
                        <tr>
                            <td><strong>Form</strong></td>
                            <td>Round, Hydraulic Etc</td>
                        </tr>
                        <tr>
                            <td><strong>Carbon Steel Tube Material Length</strong></td>
                            <td>Min 3 Meters, Max18 Meters, or according to customer’s requirement</td>
                        </tr>
                        <tr>
                            <td><strong>Jis Carbon Steel Pipe End</strong></td>
                            <td>Plain End, Beveled End, Treaded</td>
                        </tr>
                        <tr>
                            <td><strong>Specialized in</strong></td>
                            <td>Large Diameter Carbon Steel Pipe, seamless carbon steel pipe, carbon steel pipe suppliers, carbon steel seamless pipe suppliers, carbon steel erw pipe suppliers, carbon steel welded pipe suppliers, Api 5l Carbon Steel Seamless Pipe</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <SocialMedia />
            <br />
            <div className='product-info-title'>Carbon Steel Pipes and Tubes Supplier - Additional Information</div>
            <ol className="product-info-description">
                <li>Carbon Steel Pipes and Tubes Payment Modes: LC (Letter of Credit), TT (Telegraphic Transfer or Wire Transfer), Cheque, and others.</li>
                <li>Carbon Steel Pipes and Tubes Packaging: Carbon Steel Pipes and Tubes are packaged with caution and safety so they reach our client’s as good as he would expect. Packaging Charges Extra.</li>
                <li>Carbon Steel Pipes and Tubes Port of Dispatch: Mumbai ports in the Maharashtra state of India.</li>
                <li>Carbon Steel Pipes and Tubes Tax: 18% GST.</li>
            </ol>
            <div style={{ padding: '2vw 0', textAlign: 'center' }}><span className='markup-text'>Flo Metal & Tubes is a leading Carbon Steel Pipes and Tubes Manufacturer in India.</span></div>
            <br />
            <div className='product-info-title'>Applications & Uses of Carbon Steel Pipes and Tubes</div>
            <ul className="product-info-description">
                <li>Carbon Steel Pipes and Tubes used in Sugar Industry.</li>
                <li>Carbon Steel Pipes and Tubes used in Power Industry.</li>
                <li>Carbon Steel Pipes and Tubes used in ShipBuilding Industry.</li>
                <li>Carbon Steel Pipes and Tubes used in Mechanical and Plant engineering.</li>
                <li>Carbon Steel Pipes and Tubes used in Food, Beverage, and Dairy.</li>
                <li>Carbon Steel Pipes and Tubes used in Oil and Gas Plant.</li>
                <li>Carbon Steel Pipes and Tubes used in Process Instrumentation.</li>
            </ul>
            {/* <div className='product-info-title'>Cities we supply Carbon Steel Pipes and Tubes in India :-</div>
            <div className="product-info-description">Flo Metal & Tubes is one of India's leading suppliers, dealers, manufacturers, and stockists of <b>Carbon Steel Pipes and Tubes</b> due to its dependability and performance. Every month, we supply/export containers of <u>Carbon Steel Pipes and Tubes</u> to a range of clients from Indian ports. We supply our products in a number of cities around India.</div>
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th colspan="6">Carbon Steel Pipes and Tubes Supply:</th>
                        </tr>
                        <tr>
                            <td>Bengaluru</td>
                            <td>Chennai</td>
                            <td>Hyderabad</td>
                            <td>Kolkata</td>
                            <td>Mumbai</td>
                            <td>Pune</td>
                        </tr>
                        <tr>
                            <td>Bhopal</td>
                            <td>Sri City</td>
                            <td>Jamshedpur</td>
                            <td>Hosdurg</td>
                            <td>Lucknow</td>
                            <td>Noida</td>
                        </tr>
                        <tr>
                            <td>Ahmedabad</td>
                            <td>Delhi</td>
                            <td>Raipur</td>
                            <td>Jaipur</td>
                            <td>Surat</td>
                            <td>Salem</td>
                        </tr>
                        <tr>
                            <td>Jamnagar</td>
                            <td>Bhubaneswar</td>
                            <td>Sivakasi</td>
                            <td>Rajahmundry</td>
                            <td>Thiruvananthapuram</td>
                            <td>Tiruppur</td>
                        </tr>
                        <tr>
                            <td>Bhiwandi</td>
                            <td>Bokaro Steel City</td>
                            <td>Bharuch</td>
                            <td>Vijaywada</td>
                            <td>Firozabad</td>
                            <td>Rajkot</td>
                        </tr>
                        <tr>
                            <td>Cochin</td>
                            <td>Ludhiana</td>
                            <td>Durgapur</td>
                            <td>Panna</td>
                            <td>Panipat</td>
                            <td>Raipur</td>
                        </tr>
                        <tr>
                            <td>Bareilly</td>
                            <td>Channapatna</td>
                            <td>Kharagpur</td>
                            <td>Peenya</td>
                            <td>Pimpri-Chinchwad</td>
                            <td>Nashik</td>
                        </tr>
                        <tr>
                            <td>Bangalore</td>
                            <td>Indore</td>
                            <td>Haldia</td>
                            <td>Moradabad</td>
                            <td>Varanasi</td>
                            <td>Rourkela</td>
                        </tr>
                        <tr>
                            <td>Bhopal</td>
                            <td>Sri City</td>
                            <td>Jamshedpur</td>
                            <td>Hosdurg</td>
                            <td>Lucknow</td>
                            <td>Noida</td>
                        </tr>
                        <tr>
                            <td>Bareilly</td>
                            <td>Coimbatore</td>
                            <td>Kannur</td>
                            <td>Nagpur</td>
                            <td>Vadodara</td>
                            <td>Rudrapur</td>
                        </tr>
                        <tr>
                            <td>Agra</td>
                            <td>Bhagalpur</td>
                            <td>Bhilai</td>
                            <td>Thane</td>
                            <td>Kanpur</td>
                            <td>Haldia</td>
                        </tr>
                        <tr>
                            <td>Angul</td>
                            <td>Dibrugarh</td>
                            <td>Gwalior</td>
                            <td>Patna</td>
                            <td>Trivandrum</td>
                            <td>Pithampur</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <div className='product-info-title'>Countries we export Carbon Steel Pipes and Tubes worldwide :-</div>
            <div className="product-info-description">More than 80 tons of <b>Carbon Steel Pipes and Tubes</b> are exported from India each year, with Flo Metal & Tubes accounting for 25% of the total. We export to a range of countries on a regular basis for our new clients. Because of our constant supply of <u>Carbon Steel Pipes and Tubes</u>, we can export and satisfy our clients' demands and requests.</div>
            <div className="table-responsive">
                <table border="1" className='product-info-description'>
                    <tbody>
                        <tr>
                            <th colspan="6">Carbon Steel Pipes and Tubes Exporter:</th>
                        </tr>
                        <tr>
                            <td>United States</td>
                            <td>South Africa</td>
                            <td>Malaysia</td>
                            <td>Bangladesh</td>
                            <td>Turkey</td>
                        </tr>
                        <tr>
                            <td>Bahrain</td>
                            <td>Qatar</td>
                            <td>UAE</td>
                            <td>Africa</td>
                            <td>China</td>
                        </tr>
                        <tr>
                            <td>Nigeria</td>
                            <td>Mexico</td>
                            <td>Canada</td>
                            <td>Venezuela</td>
                            <td>United Kingdom</td>
                        </tr>
                        <tr>
                            <td>Egypt</td>
                            <td>Jordan</td>
                            <td>Portugal</td>
                            <td>Russia</td>
                            <td>Vietnam</td>
                        </tr>
                        <tr>
                            <td>Kazakhstan</td>
                            <td>Thailand</td>
                            <td>Spain</td>
                            <td>Romania</td>
                            <td>Philippines</td>
                        </tr>
                        <tr>
                            <td>Hongkong</td>
                            <td>Taiwan</td>
                            <td>Japan</td>
                            <td>Ukraine</td>
                            <td>Chile</td>
                        </tr>
                        <tr>
                            <td>United States</td>
                            <td>South Africa</td>
                            <td>Malaysia</td>
                            <td>Bangladesh</td>
                            <td>Turkey</td>
                        </tr>
                        <tr>
                            <td>Australia</td>
                            <td>Brazil</td>
                            <td>Germany</td>
                            <td>Singapore</td>
                            <td>Sri Lanka</td>
                        </tr>
                        <tr>
                            <td>Iran</td>
                            <td>Saudi Arabia</td>
                            <td>Oman</td>
                            <td>Kuwait</td>
                            <td>Netherlands</td>
                        </tr>
                        <tr>
                            <td>Belgium</td>
                            <td>France</td>
                            <td>Italy</td>
                            <td>South Korea</td>
                            <td>Poland</td>
                        </tr>

                        <tr>
                            <td>Costa Rica</td>
                            <td>Israel</td>
                            <td>Iraq</td>
                            <td>Madagascar</td>
                            <td>Morocco</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br /> */}
        </div>
    </div>)
}
export default CarbonSteelPipesAndTubes;