import { Carousel, Col, Row } from 'antd';
import "./Home.css";
import adnoc from '../../img/Countires/adnoc.png';
import maaden from '../../img/Countires/maaden.png';
import pdo from '../../img/Countires/PDO.png';
import qg from '../../img/Countires/QG.png';
import qpetroleum from '../../img/Countires/qpetroleum.png';
import sabic from '../../img/Countires/sabic.png';
import saudiaramco from '../../img/Countires/saudiaramco.png';

const ListOfFollows_MD = [adnoc, maaden, pdo, qg, qpetroleum, sabic, saudiaramco]

const ListOfFollows_XS = [
    [adnoc, maaden, pdo],
    [qg, qpetroleum, sabic],
    [qpetroleum, sabic, saudiaramco],
]

const CountiresSlider = () => {
    return (<div className='brand-details'>
        <div className='brand_slider_title'>TRUSTED BY CLIENTS IN OVER 32 COUNTIRES</div>
        <br />
        <br />
        <div>
            <Row>
                <Col xs={0} md={24}>
                    <Row gutter={[32, 0]} className='m3' justify='space-between' align='middle'>
                        {ListOfFollows_MD.map(img => <Col span={2}>
                            <div>
                                <img src={img} width="100%" />
                            </div>
                        </Col>)}
                    </Row>
                </Col>
                <Col xs={24} md={0}> <Carousel autoplay>{ListOfFollows_XS.map(item => <div>
                    <Row gutter={[32, 0]} className='m3'>
                        {item.map(img => <Col span={8}>
                            <div>
                                <img src={img} width="100%" />
                            </div>
                        </Col>)}
                    </Row>
                </div>)}
                </Carousel></Col>
            </Row>
        </div>
    </div>)
}
export default CountiresSlider;