import { Card } from 'antd';
import "./ProductCard.css";
import { RightOutlined } from '@ant-design/icons';

const ProductCard = ({ productImg, title, subTitle, redirect }) => {
    return (
        <Card
            hoverable
            style={{ textAlign: 'center', width: '100%' }}
            cover={<img alt="example" src={productImg} />}
        >
            <div className='product-card-title'>{title}</div>
            {subTitle && <div className='product-card-description'>{subTitle}</div>}
            {redirect && <div className='product_card_read_more'><b><a href={redirect} style={{ color: 'black' }}>READ MORE <RightOutlined /></a></b></div>}
        </Card>)
}
export default ProductCard;