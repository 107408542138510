import { Carousel, Col, Row } from 'antd';
import "./Home.css";
import ProductCard from './ProductCard';
import areospaceindustry from '../../img/FloMetalAndTubes/areospaceindustry.jpg';
import chemicalindustry from '../../img/FloMetalAndTubes/chemicalindustry.jpg';
import oilgasindsutry from '../../img/FloMetalAndTubes/oilgasindsutry.jpg';
import powerplants from '../../img/FloMetalAndTubes/powerplants.jpg';
import sugarmills from '../../img/FloMetalAndTubes/sugarmills.jpg';

const ProductList_LG = [
    [
        {
            productImg: areospaceindustry,
            title: 'Aerospace Industry',
        },
        {
            productImg: chemicalindustry,
            title: 'Chemical Industry',
        },
        {
            productImg: oilgasindsutry,
            title: 'Oil Gas Indsutry',
        }],
    [
        {
            productImg: oilgasindsutry,
            title: 'Oil Gas Indsutry',
        },
        {
            productImg: powerplants,
            title: 'Power Plants',
        },
        {
            productImg: sugarmills,
            title: 'Sugar Mills',
        }]
]
const ProductList_MD = [
    [
        {
            productImg: areospaceindustry,
            title: 'Aerospace Industry',
        },
        {
            productImg: chemicalindustry,
            title: 'Chemical Industry',
        }
    ],
    [
        {
            productImg: oilgasindsutry,
            title: 'Oil Gas Indsutry',
        },
        {
            productImg: powerplants,
            title: 'Power Plants',
        }
    ],
    [
        {
            productImg: powerplants,
            title: 'Power Plants',
        },
        {
            productImg: sugarmills,
            title: 'Sugar Mills',
        }
    ]
]

const ProductList_XS = [
    {
        productImg: areospaceindustry,
        title: 'Aerospace Industry',
    },
    {
        productImg: chemicalindustry,
        title: 'Chemical Industry',
    },
    {
        productImg: oilgasindsutry,
        title: 'Oil Gas Indsutry',
    },
    {
        productImg: powerplants,
        title: 'Power Plants',
    },
    {
        productImg: sugarmills,
        title: 'Sugar Mills',
    }
]

const FloMetalAndTubes = () => {
    return (<>
        <Row>
            <Col xs={0} md={0} lg={24}>
                <Carousel autoplay>{ProductList_LG.map(item => <div>
                    <Row gutter={[16, 0]} className='col3'>
                        {item.map(entity => <Col span={8}>
                            <ProductCard productImg={entity.productImg} title={entity.title} />
                        </Col>)}
                    </Row>
                </div>)}
                </Carousel>
            </Col>
            <Col xs={0} md={24} lg={0}>
                <Carousel autoplay>{ProductList_MD.map(item => <div>
                    <Row gutter={[16, 0]} className='col3'>
                        {item.map(entity => <Col span={12}>
                            <ProductCard productImg={entity.productImg} title={entity.title} />
                        </Col>)}
                    </Row>
                </div>)}
                </Carousel>
            </Col>
            <Col xs={24} md={0}>
                <Carousel autoplay>{ProductList_XS.map(item => <div>
                    <Row className='col1'>
                        <ProductCard productImg={item.productImg} title={item.title} />
                    </Row>
                </div>)}
                </Carousel>
            </Col>
        </Row>
    </>)
}
export default FloMetalAndTubes;